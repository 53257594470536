import './Sidebar.css'
import React from 'react'
import DefaultButton from '../DefaultComponents/DefaultButton/DefaultButton'
import { NavLink } from 'react-router-dom'
import { navOptions } from '../../util/constants'
import { useAuth } from '../../hooks/useAuth'
import { ReactComponent as DashboardIcon } from '../../assets/DashboardIcon.svg'

const Sidebar = () => {
  const { user } = useAuth()

  return (
    <div className="sidebar-container">
      <div className="sidebar-body">
        {navOptions.map((sItem, index) => (
          <NavLink key={sItem.url + index} to={sItem.url}>
            <DefaultButton
              onClick={() => {}}
              key={index}
              type={'sidebar-button'}
              leftIcon
              customLeftIcon={sItem.icon}
            >
              {sItem.title}
            </DefaultButton>
          </NavLink>
        ))}
        {user?.role === 'admin' && (
          <NavLink key={'admin-dashboard'} to="/admin-dashboard">
            <DefaultButton
              onClick={() => {}}
              key={'admin-dashboard'}
              type={'sidebar-button'}
              leftIcon
              customLeftIcon={<DashboardIcon />}
            >
              Admin
            </DefaultButton>
          </NavLink>
        )}
      </div>
    </div>
  )
}

export default Sidebar
