import './Layout.css'
import React, { useState, useEffect } from 'react'
import Sidebar from '../Sidebar/Sidebar'
import Header from '../Header/Header'
import { useAuth } from '../../hooks/useAuth'
import { classNameTypeExtractor } from '../../util/util'

const Layout = ({ children }) => {
  const [deviceType, setDeviceType] = useState('')
  const { selectedDeviceType } = useAuth()

  useEffect(() => {
    if (selectedDeviceType) {
      setDeviceType(selectedDeviceType)
    }
  }, [selectedDeviceType])

  return deviceType ? (
    <div className={`layout-wrapper ${classNameTypeExtractor(deviceType)}`}>
      <Header />
      <div className="main-content-area">
        <Sidebar />
        <div className="content">{children}</div>
      </div>
    </div>
  ) : (
    ''
  )
}

export default Layout
