import { getDataForTrialPlan } from './util'

export const getISOTime = (date) => {
  const hours = date.toISOString().split('T')[1].split(':')[0]
  const minutes = date.toISOString().split('T')[1].split(':')[1]
  return hours + ':' + minutes
}

export const calculateTimezone = (time) => {
  const hours = parseInt(time.split(':')[0])
  const minutes = parseInt(time.split(':')[1])
  const dt = new Date()
  dt.setHours(hours, minutes)
  dt.setMinutes(dt.getMinutes() - dt.getTimezoneOffset())
  return dt
}

export const getTimestampFromTime = (time) => {
  const date = new Date()
  const offset = date.getTimezoneOffset()
  const hours = parseInt(time.split(':')[0])
  const minutes = parseInt(time.split(':')[1])
  date.setHours(hours, minutes)
  date.setMinutes(date.getMinutes() - offset)
  const timeNumber = date.getHours() * 3600 + date.getMinutes() * 60 + date.getSeconds()
  return timeNumber
}

export const getFormatedDateGallery = (dateString) => {
  if (dateString) {
    const date = new Date(dateString)
    const formattedDate = date.toLocaleString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short',
    })

    return formattedDate
  }
  return ''
}
export const getFormatedDate = (dateString) => {
  if (dateString) {
    const options = { day: 'numeric', month: 'short', year: 'numeric' }
    const date = new Date(dateString)
    return date.toLocaleDateString('en-GB', options)
  }
  return ''
}
export const getFormatedDateNoYear = (dateString) => {
  if (dateString) {
    const options = { day: 'numeric', month: 'short' }
    const date = new Date(dateString)
    return date.toLocaleDateString('en-GB', options)
  }
  return ''
}

export const getEntriesFormatedDate = (dateString) => {
  const date = new Date(dateString)
  const year = date.getFullYear()
  const month = new Intl.DateTimeFormat('en-GB', { month: 'long' }).format(date)
  const formattedDate = `${year} - ${month}`
  return formattedDate
}

export const getFormatedDateNowDiary = (dateString) => {
  const options = {
    weekday: 'long',
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  }

  const date = new Date(dateString)

  const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date)
  return formattedDate.replace(', ', ' - ')
}

export const getDataForLast30Days = (dataArray) => {
  const currentMonthStart = new Date()
  const thirtyDaysAgo = new Date().setDate(-30)
  let dataForLast30Days
  const currentDateFormat = getEntriesFormatedDate(currentMonthStart)
  const thirtyDaysAgoFormat = getEntriesFormatedDate(thirtyDaysAgo)

  if (currentDateFormat === thirtyDaysAgoFormat) {
    dataForLast30Days = [
      {
        date: getEntriesFormatedDate(currentMonthStart),
        entries: dataArray,
      },
    ]
  } else {
    const dataByMonth = getDataForTrialPlan(dataArray)
    dataForLast30Days = [
      {
        date: getEntriesFormatedDate(thirtyDaysAgo),
        entries: dataByMonth[1],
      },
      {
        date: getEntriesFormatedDate(currentMonthStart),
        entries: dataByMonth[0],
      },
    ]
  }

  return dataForLast30Days
}

export const dateToTimezoneTimeString = (date) => {
  const h = date.getHours().toString().padStart(2, '0')
  const m = date.getMinutes().toString().padStart(2, '0')
  const s = date.getSeconds().toString().padStart(2, '0')
  return `${h}:${m}:${s}`
}
export const dateToUTCTimeString = (date) => {
  const h = date.getUTCHours().toString().padStart(2, '0')
  const m = date.getUTCMinutes().toString().padStart(2, '0')
  const s = date.getUTCSeconds().toString().padStart(2, '0')
  return `${h}:${m}:${s}`
}

// and increment the time by one minute
export const getDateFromUTCTimeString = (timeString) => {
  const date = new Date()
  date.setUTCHours(timeString.split(':')[0])
  date.setUTCMinutes(timeString.split(':')[1])
  date.setUTCMinutes(date.getUTCMinutes() + 1)
  date.setUTCSeconds(0)
  return date
}
