import './NotFoundScreen.css'
import { useTranslation } from 'react-i18next'

const NotFoundScreen = () => {
  const { t } = useTranslation('translations')

  return (
    <div class="section">
      <h1 class="error">404</h1>
      <div class="page">{t('not_found_screen.text')}</div>
      <a class="back-home" href="/">
        {t('not_found_screen.back_to_home')}
      </a>
    </div>
  )
}

export { NotFoundScreen }
