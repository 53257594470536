import React, { useState, useEffect } from 'react'
import './SystemChecks.css'
import { useLocation, useNavigate } from 'react-router-dom'
import WalkthroughContainer from '../WalkthroughContainer/WalkthroughContainer'
import { DefaultButton, DefaultDialog } from '../../DefaultComponents'
import DataService from '../../../data/DataService'
import { ReactComponent as SpinnerIcon } from '../../../assets/SpinnerIcon.svg'
import { ReactComponent as PlugIcon } from '../../../assets/PlugIcon.svg'
import { ReactComponent as HeaterIcon } from '../../../assets/HeaterIcon.svg'
import { ReactComponent as SensorIcon } from '../../../assets/SensorIcon.svg'
import { ReactComponent as FanIcon } from '../../../assets/FanIcon.svg'
import { ReactComponent as LightIcon } from '../../../assets/LightIcon.svg'
import { ReactComponent as MoistureIcon } from '../../../assets/MoistureIcon.svg'
import { ReactComponent as ErrorIcon } from '../../../assets/ErrorIcon.svg'
import { useAuth } from '../../../hooks/useAuth'
import { useTranslation } from 'react-i18next'

const SystemChecks = ({ device, type }) => {
  const navigate = useNavigate()
  const { t } = useTranslation('translations')
  const [displayDialog, setDisplayDailog] = useState(false)
  const [proceed, setProceed] = useState(false)
  const [activeModules, setActiveModules] = useState(null)
  const [currentModuleData, setCurrentModuleData] = useState(null)
  const [errorList, setErrorList] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [deviceType, setDeviceType] = useState('')
  const { state } = useLocation()
  const { connectedDevices, fetchConnectedDevices, user } = useAuth()
  const deviceId = state?.deviceId

  useEffect(() => {
    if (!deviceId) {
      navigate('/')
    }
    if (user.email) {
      fetchConnectedDevices(user.email)
    }
  }, [user])

  useEffect(() => {
    if (connectedDevices?.length) {
      checkDeviceConnection(connectedDevices)
    }
  }, [connectedDevices, device, type])

  useEffect(() => {
    if (currentModuleData && Object.keys(currentModuleData).length > 0 && !isLoading) {
      getIndicatorStatus()
    }
  }, [currentModuleData, isLoading])

  const checkDeviceConnection = async (connectedDevices) => {
    let deviceId = ''
    let deviceType = ''
    if (device && type) {
      deviceId = device
      deviceType = type
    } else {
      deviceId = connectedDevices[0]?.device_id
      deviceType = connectedDevices[0]?.device_type_name
    }
    setDeviceType(deviceType)
    if (deviceId && deviceType) {
      switch (deviceType) {
        case 'groovyControl':
          setActiveModules({
            plug: false,
            energy_usage_sensor: false,
          })
          break
        case 'groovySystem':
          setActiveModules({
            plug: false,
            soil_moisture_sensor: false,
            soil_acidity_sensor: false,
            soil_ph_sensor: false,
          })
          break
        case 'groovyBox':
          setActiveModules({
            plug: false,
            air_moisture_sensor: false,
            air_temperature_sensor: false,
            heater: false,
            fan: false,
            light: false,
          })
          break
        default:
          break
      }

      const deviceModules = await DataService.connectedModulesForDevice(deviceId)

      setCurrentModuleData(deviceModules)
      setIsLoading(false)
    }
  }

  const renderIcon = (indicator) => {
    // eslint-disable-next-line default-case
    switch (indicator) {
      case 'plug':
        return <PlugIcon />
      case 'heater':
        return <HeaterIcon />
      case 'energy_usage_sensor':
        return <SensorIcon />
      case 'soil_moisture_sensor':
        return <SensorIcon />
      case 'soil_acidity_sensor':
        return <SensorIcon />
      case 'soil_ph_sensor':
        return <SensorIcon />
      case 'air_moisture_sensor':
        return <SensorIcon />
      case 'air_temperature_sensor':
        return <MoistureIcon />
      case 'fan':
        return <FanIcon />
      case 'light':
        return <LightIcon />
    }
  }

  const getIconBackgroundColor = () => {
    // eslint-disable-next-line default-case
    switch (deviceType) {
      case 'groovyControl':
        return 'control'
      case 'groovySystem':
        return 'system'
      case 'groovyBox':
        return 'box'
    }
  }

  const checkStatus = async (name, value, lastIndex) => {
    if (value) {
      let iconBackground = getIconBackgroundColor()
      if (lastIndex) {
        if (errorList.length) {
          window.scrollTo(0, 0)
          setDisplayDailog(true)
        }
        setProceed(true)
      }
      setActiveModules((prevState) => ({
        ...prevState,
        [name]: iconBackground,
      }))
    } else {
      if (!errorList.includes(t(`system_checks.error_${name}`))) {
        const tempList = errorList
        tempList.push(t(`system_checks.error_${name}`))
        setErrorList(tempList)
      }
      if (lastIndex) {
        if (errorList.length || !value) {
          window.scrollTo(0, 0)
          setDisplayDailog(true)
        }
        setProceed(true)
      }
      setActiveModules((prevState) => ({
        ...prevState,
        [name]: 'error',
      }))
    }
  }

  const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

  const processModule = async (moduleKey, lastIndex, delay) => {
    setProcessed(moduleKey)
    await wait(2000)
    checkStatus(moduleKey, currentModuleData[moduleKey], lastIndex)
  }

  const getIndicatorStatus = async () => {
    const moduleKeys = Object.keys(activeModules)
    if (moduleKeys.length && activeModules) {
      let lastIndex
      let delay = 2000
      for (let i = 0; i < moduleKeys.length; i++) {
        const key = moduleKeys[i]
        lastIndex = i === moduleKeys.length - 1 ? true : false
        await processModule(key, lastIndex, delay)
        delay += 2000
      }
    }
  }

  const setProcessed = async (name) => {
    setActiveModules((prevState) => ({
      ...prevState,
      [name]: 'process',
    }))
  }

  if (isLoading || Object.keys(activeModules).length === 0) {
    return <>Loading...</>
  }

  return (
    <WalkthroughContainer>
      {
        <DefaultDialog
          disableDefaultAnimation
          classModifier={`error-container ${displayDialog ? 'open' : 'closed'}`}
          header={
            <div>
              <div className="system-checks-dialog-title">{t('system_checks.error_occured')}</div>
              <div className="system-checks-dialog-subtitle">
                {t('system_checks.check_out_details')}
              </div>
            </div>
          }
          open={displayDialog}
          onClose={() => setDisplayDailog(false)}
        >
          <div className="system-checks-dialog-error-container">
            {errorList.map((e, i) => (
              <div key={i} className="system-checks-dialog-error">
                <ErrorIcon className="system-checks-dialog-error-icon" />
                <div className="system-checks-dialog-error-message">{e}</div>
              </div>
            ))}
            <DefaultButton variant={'default error-dialog-button'} onClick={() => {}}>
              {t('system_checks.see_details')}
            </DefaultButton>
          </div>
        </DefaultDialog>
      }
      <div className="system-checks-container">
        <div className="system-checks-title">{t('system_checks.title')}</div>
        <div className="system-checks-device-type-container">
          <div className="system-checks-device-type">
            <div
              className={`system-checks-device-type-status ${
                deviceType === 'groovyBox' ? 'box' : ''
              }`}
            />
            <div
              className={`system-checks-device-type-text ${
                deviceType === 'groovyBox' ? '' : 'disabled'
              }`}
            >
              GroovyBox
            </div>
          </div>
          <div className="system-checks-device-type">
            <div
              className={`system-checks-device-type-status ${
                deviceType === 'groovySystem' ? 'system' : ''
              }`}
            />
            <div
              className={`system-checks-device-type-text ${
                deviceType === 'groovySystem' ? '' : 'disabled'
              }`}
            >
              GroovySystem
            </div>
          </div>
          <div className="system-checks-device-type">
            <div
              className={`system-checks-device-type-status ${
                deviceType === 'groovyControl' ? 'control' : ''
              }`}
            />
            <div
              className={`system-checks-device-type-text ${
                deviceType === 'groovyControl' ? '' : 'disabled'
              }`}
            >
              GroovyControl
            </div>
          </div>
          <div className="system-checks-indicator-container">
            {activeModules &&
              Object.keys(activeModules).length > 0 &&
              Object.keys(activeModules).map((e, i) => (
                <div key={i} className="system-checks-indicator">
                  <div className={`system-checks-indicator-icon ${activeModules[e]}`}>
                    {activeModules[e] === 'process' ? (
                      <SpinnerIcon className="system-checks-indicator-icon-spinner" />
                    ) : (
                      renderIcon(e)
                    )}
                  </div>
                  <div className={`system-checks-indicator-text ${activeModules[e]}`}>
                    {t(`system_checks.${e}`)}
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="system-checks-button-container">
          <DefaultButton
            rightIcon
            onClick={() => {
              navigate('/dashboard')
            }}
            disabled={!proceed}
          >
            {t('system_checks.proceed')}
          </DefaultButton>
        </div>
      </div>
    </WalkthroughContainer>
  )
}

export default SystemChecks
