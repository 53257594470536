import React from 'react'
import './SwitchToggle.css'

const SwitchToggle = ({ activeState }) => {
  return (
    <div className={`${activeState ? 'switch switch-active' : 'switch'}`}>
      <span className={`${activeState ? 'switch-dot switch-dot-active' : 'switch-dot'}`}></span>
    </div>
  )
}

export default SwitchToggle
