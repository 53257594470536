import './NotificationBase.css'
import React, { useState, useEffect } from 'react'
import { ReactComponent as CloseIcon } from '../../assets/CloseIcon.svg'
import PropTypes from 'prop-types'

const NotificationBase = ({ header, text, onDismiss, timeout, type }) => {
  const [dismiss, setDismiss] = useState(false)
  const [dismissTimeout, setDismissTimeout] = useState(false)
  const [paused, setPaused] = useState(false)

  useEffect(() => {
    if (!dismiss) {
      return
    }

    setTimeout(() => {
      onDismiss()
    }, 500)
  }, [dismiss, onDismiss])

  useEffect(() => {
    let timeoutId
    if (paused) {
      if (dismissTimeout) {
        clearTimeout(dismissTimeout)
      }
    } else {
      timeoutId = setTimeout(() => {
        setDismiss(true)
      }, timeout)

      setDismissTimeout(timeoutId)
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [paused, timeout])

  const resume = () => {
    setPaused(false)
  }
  const pause = () => {
    setPaused(true)
  }

  return (
    <div
      onMouseEnter={() => {
        pause()
      }}
      onMouseLeave={() => {
        resume()
      }}
      className={`notification-base ${type} ${dismiss ? 'notification-base-out' : ''}`}
    >
      <div className="notification-base-content">
        <div className="notification-base-header">
          <strong className="notification-base-header-text">{header}</strong>
          <div
            className="notification-base-close-icon"
            onClick={() => {
              setDismiss(true)
            }}
          >
            <CloseIcon />
          </div>
        </div>
        {text && <div className="notification-base-body">{text}</div>}
      </div>

      <div
        className={`progress-bar ${paused ? 'paused' : ''}`}
        style={{
          animationDuration: `${timeout}ms`,
        }}
      ></div>
    </div>
  )
}

NotificationBase.propTypes = {
  header: PropTypes.string,
  text: PropTypes.string,
  onDismiss: PropTypes.func,
  timeout: PropTypes.number,
  type: PropTypes.oneOf(['error', 'success']),
}

NotificationBase.defaultProps = {
  header: '',
  text: '',
  timeout: 5000,
  type: 'success',
}

export default NotificationBase
