import React from 'react'
import './HamburgerMenu.css'

const MenuLink = ({ title, icon }) => {
  return (
    <div className="menu-inline">
      <div className="menu-icon">{icon}</div>
      <div className="menu-title">{title}</div>
    </div>
  )
}

export default MenuLink
