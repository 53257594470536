import PropTypes from 'prop-types'
import { Fragment, isValidElement } from 'react'
import './SSXTableRow.css'

const SSXTableRow = ({ onClick, leftColumns, rightColumns, useSpacer, odd }) => {
  const renderElement = (column) => {
    if (!isValidElement(column.item)) {
      if (typeof column.item === 'function') {
        return <Fragment key={column.key}>{column.item()}</Fragment>
      }
      return <span key={column.key}>{column.item}</span>
    }
    return <Fragment key={column.key}>{column.item}</Fragment>
  }
  return (
    <div
      onClick={onClick}
      className={`ssx-table-row ${odd ? 'odd' : 'even'} ${!!onClick ? 'hovered' : ''}`.trim()}
    >
      {leftColumns.map((col) => renderElement(col))}
      {useSpacer && <span style={{ flex: 1 }}></span>}
      {rightColumns.map((col) => renderElement(col))}
    </div>
  )
}

const PropTypeColumn = PropTypes.shape({
  key: PropTypes.string.isRequired,
  item: PropTypes.element.isRequired,
})
SSXTableRow.propTypes = {
  onClick: PropTypes.func,
  leftColumns: PropTypes.arrayOf(PropTypeColumn),
  rightColumns: PropTypes.arrayOf(PropTypeColumn),
  useSpacer: PropTypes.bool,
  odd: PropTypes.bool,
}

SSXTableRow.defaultProps = {
  leftColumns: [],
  rightColumns: [],
  useSpacer: true,
  odd: false,
}

export default SSXTableRow
