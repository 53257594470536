import React, { useState } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import './Chart.css'
import { styled } from '@mui/material'
import { ReactComponent as DownFacingArrow } from '../../assets/DownFacingArrow.svg'
import { hexToRgb } from '../../util/util'
import { useTranslation } from 'react-i18next'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
)

const CustomAccordion = styled((props, { expanded, onChange }) => (
  <Accordion expanded={expanded} onChange={onChange} disableGutters elevation={0} {...props} />
))(() => ({
  border: `1px solid 	var(--foundation-white-white-600)`,
  backgroundColor: 'var(--foundation-white-white-50)',
  padding: '0.4rem',
}))

const CustomAccordionSummary = styled((props) => (
  <AccordionSummary expandIcon={<ArrowForwardIosSharpIcon />} {...props} />
))(({ theme }) => ({
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
}))

const CustomButton = () => {
  return (
    <div className="custom-button">
      <DownFacingArrow />
    </div>
  )
}

const Chart = ({ name, chartData, labels, dataType, color, expand }) => {
  const { t } = useTranslation('translations')
  const options = {
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
    },
  }

  const targetData = { ...(chartData ?? {}) }
  const noResultsAvailable =
    !chartData ||
    Object.keys(targetData).length < 1 ||
    (Array.isArray(targetData?.values) && targetData.values.length < 1)

  const formattedLabels = labels?.map((label) => {
    const [datePart, time] = label.split('T')
    const [year, month, day] = datePart.split('-')
    return `${month}-${day} ${time}`
  })

  const data = {
    labels: formattedLabels,
    datasets: [
      {
        fill: false,
        label: 'Air Temperature',
        data: targetData[1]?.data.values,
        borderColor: 'Red',
        cubicInterpolationMode: 'monotone',
      },
      {
        label: 'Air Humidity',

        data: targetData[0]?.data.values,
        borderColor: 'Blue',

        cubicInterpolationMode: 'monotone',
      },
    ],
  }

  const [expanded, setExpanded] = useState(expand)

  const handleChange = () => {
    setExpanded((prevExpanded) => !prevExpanded)
  }

  return (
    <>
      <div className="title">{name}</div>
      <AccordionDetails>
        <div className="chart-container">
          <Line options={options} data={data}></Line>
          <div>
            &nbsp;
            {/* DONT REMOVE THIS CONTAINER - Chart wont scale without it, probably bug in library */}
          </div>
          {noResultsAvailable && (
            <div className="chart-container__no_result_overlay">{t('automations.no_results')}</div>
          )}
        </div>
      </AccordionDetails>
    </>
  )
}

export default Chart
