import React, { useRef } from 'react'
import { Dialog, DialogTitle, DialogContent } from '@mui/material'
import ReCAPTCHA from 'react-google-recaptcha'
import { useTranslation } from 'react-i18next'
import { DefaultButton } from '../DefaultComponents'
import './RecaptchaDialog.css'

const RecaptchaDialog = ({ onChange, onErrored, displayDialog, onSubmit, onCancel }) => {
  const { t } = useTranslation('translations')
  const recaptcha = useRef()

  const handleSubmit = () => {
    const captchaValue = recaptcha.current.getValue() || ''
    onSubmit(captchaValue)
  }
  return (
    <Dialog
      className="recaptcha-dialog"
      PaperProps={{
        style: {
          margin: '0',
        },
      }}
      open={displayDialog}
    >
      <DialogTitle className="recaptcha-dialog-title">
        {t('recaptcha_dialog.not_robot_confirmation')}
      </DialogTitle>
      <DialogContent>
        <div className="recaptcha-dialog-content">
          <ReCAPTCHA
            data-theme="dark"
            size="normal"
            sitekey={process.env.REACT_APP_RECAPCHA_PUBLIC}
            render="explicit"
            ref={recaptcha}
            onChange={() => onChange()}
            onErrored={() => onErrored()}
          />
          <DefaultButton classModifier="recaptcha-confirm-button" onClick={() => handleSubmit()}>
            {t('recaptcha_dialog.confirm')}
          </DefaultButton>
          <DefaultButton classModifier="recaptcha-cancel-button" onClick={() => onCancel()}>
            {t('recaptcha_dialog.cancel')}
          </DefaultButton>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default RecaptchaDialog
