import React, { useState, useEffect } from 'react'
import './IndicatorCard.css'
import { useTranslation } from 'react-i18next'
import DefaultProgressIndicator from '../DefaultComponents/DefaultProgressIndicator/DefaultProgressIndicator'
import { ReactComponent as PauseIcon } from '../../assets/PauseIcon.svg'
import { ReactComponent as PlayIcon } from '../../assets/PlayIcon.svg'
import {
  INTAKE_FAN_ID,
  EXHAUST_FAN_ID,
  LITTLE_FAN_ID,
  LIGHT_ID,
  HEATER_ID,
  SWITCH_ID,
  AIR_TEMPERATURE_CONTROLLER_ID,
} from '../../util/constants'
import { returnFanSpeed } from '../../util/util'
import DataService from '../../data/DataService'
import useWarning from '../Modal/WarningModal/WarningModal'
import { useDevice } from '../../hooks/useDevice'

const IndicatorCard = ({
  icon,
  controller,
  progress,
  title,
  subtitle,
  controllerState,
  controllerType,
  deviceAddress,
  classModifier,
  deviceType,
  module_id,
  progressClassModifier,
  module_status,
  automationControllerValue,
  automationStatus,
  isAutomationActiveOnDevice,
  automationId,
  deviceData,
  indicators,
}) => {
  const [loading, setLoading] = useState(false)
  const [minTemperature, setMinTemperature] = useState('20.0')
  const [maxTemperature, setMaxTemperature] = useState('25.0')
  const [lightIntervals, setLightIntervals] = useState([])
  const [warningText, setWarningText] = useState('')
  const [getConfirmation, Warning] = useWarning(warningText)
  const { refreshData, refreshAutomationStatus } = useDevice()
  const { t } = useTranslation('translations')

  useEffect(() => {
    if (automationControllerValue && automationControllerValue !== 'Off') {
      setMinTemperature(automationControllerValue.split(';')[0])
      setMaxTemperature(automationControllerValue.split(';')[1])
    }
  }, [automationControllerValue])

  const displayTemperatureRange = (range) => {
    return `${automationControllerValue.split(';')[0]}° - ${
      automationControllerValue.split(';')[1]
    }°`
  }

  const returnControllerState = async (state) => {
    startLoading()
    if (module_id === HEATER_ID) {
      const response = await DataService.sendActionRequest({
        payload: state ? 'HF' : 'HN',
        device_type: deviceType,
        device_mac_address: deviceAddress,
        action_name: 'Heater',
      })
    }

    if (module_id === SWITCH_ID) {
      const response = await DataService.sendActionRequest({
        payload: state ? 'TF' : 'TN',
        device_type: deviceType,
        device_mac_address: deviceAddress,
        action_name: 'Switch',
      })
    }

    if (module_id === LIGHT_ID) {
      const response = await DataService.sendActionRequest({
        payload: state ? 'LF' : 'LN',
        device_type: deviceType,
        device_mac_address: deviceAddress,
        action_name: 'Lights',
      })
    }
  }

  // TODO - think of cleaner way for handling this
  const triggerController = () => {
    controller(!controllerState)
    setLoading(false)
  }

  const sendFanSpeedAction = async (direction) => {
    startLoading()
    const payload = returnFanSpeed(subtitle, direction)
    let action_name = ''
    let relay_action = ''
    let payload_relay = ''
    if (module_id === INTAKE_FAN_ID) {
      action_name = 'IntakeFanSpeed'
      if (!controllerState) {
        relay_action = 'IntakeFanRelay'
        payload_relay = 'IRN'
      }
    }
    if (module_id === EXHAUST_FAN_ID) {
      action_name = 'ExhaustFanSpeed'
      if (!controllerState) {
        relay_action = 'ExhaustFanRelay'
        payload_relay = 'ERN'
      }
    }
    if (module_id === LITTLE_FAN_ID) {
      action_name = 'LittleFanSpeed'
      if (!controllerState) {
        relay_action = 'LittleFanRelay'
        payload_relay = 'LRN'
      }
    }
    if (payload && action_name) {
      const response = await DataService.sendActionRequest({
        payload: payload,
        device_type: 'GroovyBox',
        device_mac_address: deviceAddress,
        action_name: action_name,
      })
    }
    if (payload_relay && relay_action) {
      const response = await DataService.sendActionRequest({
        payload: payload_relay,
        device_type: 'GroovyBox',
        device_mac_address: deviceAddress,
        action_name: relay_action,
      })
    }
  }

  const sendFanRelayAction = async () => {
    startLoading()
    let payload = ''
    let action_name = ''
    if (module_id === INTAKE_FAN_ID) {
      action_name = 'IntakeFanRelay'
      if (controllerState) {
        payload = 'IRF'
      } else {
        payload = 'IRN'
      }
    }
    if (module_id === EXHAUST_FAN_ID) {
      action_name = 'ExhaustFanRelay'
      if (controllerState) {
        payload = 'ERF'
      } else {
        payload = 'ERN'
      }
    }
    if (module_id === LITTLE_FAN_ID) {
      action_name = 'LittleFanRelay'
      if (controllerState) {
        payload = 'LRF'
      } else {
        payload = 'LRN'
      }
    }
    const response = await DataService.sendActionRequest({
      payload: payload,
      device_type: 'GroovyBox',
      device_mac_address: deviceAddress,
      action_name: action_name,
    })
  }

  const sendTemperatureAction = async (state) => {
    const deviceAutomationStatus = deviceData?.automation_active
    let confirm = true
    if (
      deviceAutomationStatus &&
      deviceData?.automation?.active_stage_temperature_max &&
      deviceData?.automation?.active_stage_temperature_min
    ) {
      setWarningText(
        `This action will deactivate the ${deviceData?.automation?.name}, are you sure you want to proceed?`,
      )
      confirm = await getConfirmation()
      if (confirm) {
        await DataService.updateAutomationStatus(automationId, false, 'groovyBox')
      }
    }

    startLoading()
    let payload = ''
    if (minTemperature && maxTemperature) {
      payload = `${minTemperature};${maxTemperature}`
    }
    if (!state) {
      payload = '-'
    }

    if (payload && confirm) {
      const response = await DataService.sendActionRequest({
        payload: payload,
        device_type: 'GroovyBox',
        device_mac_address: deviceAddress,
        action_name: 'SetTemperature',
      })
    }
  }

  const deactiveTempRangeAndAutomation = async () => {
    await DataService.updateAutomationStatus(automationId, false, 'groovyBox')
    await DataService.sendActionRequest({
      payload: '-',
      device_type: 'GroovyBox',
      device_mac_address: deviceAddress,
      action_name: 'SetTemperature',
    })
  }

  const handleControllerClick = async (action, state, direction) => {
    let automationLightIntervals = lightIntervals
    if (!automationLightIntervals.length) {
      const res = await DataService.getLightIntervalsByStage({
        stage_id: deviceData?.automation?.active_stage_id,
        automation_id: deviceData?.automation?.automation_id,
      })
      if (res) {
        setLightIntervals(res)
        automationLightIntervals = res
      }
    }
    let confirm = true
    const deviceAutomationStatus = deviceData?.automation_active

    const temperatureControllerIndicator = indicators.find(
      (indicator) => indicator.name === 'Air Temperature Controller',
    )

    //Case 1: The “Temperature range“ Controller in ON
    if (
      (title === 'Intake Fan' || title === 'Exhaust Fan') &&
      temperatureControllerIndicator &&
      temperatureControllerIndicator?.value !== '-r' &&
      !deviceAutomationStatus
    ) {
      setWarningText(
        'This action will deactivate the “Temperature range“, are you sure you want to proceed?',
      )
      confirm = await getConfirmation()
      if (confirm) {
        // deactivate Temperature range controller
        await DataService.sendActionRequest({
          payload: '-',
          device_type: 'GroovyBox',
          device_mac_address: deviceAddress,
          action_name: 'SetTemperature',
        })
      }
    }
    //Case 2: Automation is ON with a stage that has only the lights automated
    else if (
      title === 'Light' &&
      deviceAutomationStatus &&
      automationLightIntervals.length &&
      !deviceData?.automation?.active_stage_temperature_max &&
      !deviceData?.automation?.active_stage_temperature_min
    ) {
      setWarningText(
        `This action will deactivate the ${deviceData?.automation?.name}, are you sure you want to proceed?`,
      )
      confirm = await getConfirmation()
      if (confirm) {
        // deactivate automation
        await DataService.updateAutomationStatus(automationId, false, 'groovyBox')
        await refreshAutomationStatus()
      }
    }
    // Case 3: Automation is ON with a stage that has only the “Temperature range“ automated
    else if (
      (title === 'Intake Fan' || title === 'Exhaust Fan') &&
      deviceAutomationStatus &&
      deviceData?.automation?.active_stage_temperature_max &&
      deviceData?.automation?.active_stage_temperature_min &&
      !automationLightIntervals.length
    ) {
      setWarningText(
        `This action will deactivate the “Temperature range“ and the ${deviceData?.automation?.name}, are you sure you want to proceed?`,
      )
      confirm = await getConfirmation()
      if (confirm) {
        // deactivate automation and Temperature range
        await deactiveTempRangeAndAutomation()
        await refreshAutomationStatus()
      }
    }
    //Case 4: Automation is ON with a stage that has both the “Temperature range“ and the “Lights“ automated.
    else if (
      (title === 'Intake Fan' || title === 'Exhaust Fan' || title === 'Light') &&
      deviceAutomationStatus &&
      deviceData?.automation?.active_stage_temperature_max &&
      deviceData?.automation?.active_stage_temperature_min &&
      automationLightIntervals.length
    ) {
      setWarningText(
        `This action will deactivate the “Temperature range“ and the ${deviceData?.automation?.name}, are you sure you want to proceed?`,
      )
      confirm = await getConfirmation()
      if (confirm) {
        // deactivate automation and Temperature range
        await deactiveTempRangeAndAutomation()
        await refreshAutomationStatus()
      }
    }

    if (confirm) {
      switch (action) {
        case 'state':
          returnControllerState(state)
          break
        case 'fan_relay':
          sendFanRelayAction()
          break
        case 'fan_speed':
          sendFanSpeedAction(direction)
          break
        default:
          break
      }

      await refreshData()
    }
  }

  // TODO - think of cleaner way for handling this
  const startLoading = () => {
    setLoading(true)
    setTimeout(() => triggerController(), 3000)
  }

  return (
    <div
      className={`indicator-card ${
        module_id === AIR_TEMPERATURE_CONTROLLER_ID ? 'air-temperature-controller' : ''
      } ${classModifier || ''}`}
    >
      <Warning showText={true} />
      <div className="indicator-card-wrapper">
        <div className="indicator-card-icon">{icon}</div>
        <div
          className={`indicator-card-header-wrapper ${
            module_id === AIR_TEMPERATURE_CONTROLLER_ID ? 'air-temperature-controller-subtitle' : ''
          }`}
        >
          <div className="indicator-card-header-title">{title}</div>
          {controller ? (
            <div className="indicator-card-header-subtitle">
              {loading ? (
                <div className="indicator-card-loader-wrapper">
                  <div className="indicator-card-loader-animation" />
                </div>
              ) : automationControllerValue ? (
                displayTemperatureRange(automationControllerValue)
              ) : controllerState ? (
                t('dashboard.indicators.on')
              ) : (
                t('dashboard.indicators.off')
              )}
            </div>
          ) : (
            <div
              className={`indicator-card-header-subtitle ${module_status ? 'module_status' : ''}`}
            >
              {subtitle}
            </div>
          )}
        </div>
        {controllerType === 'fan' ? (
          controllerState ? (
            <div
              className="indicator-card-pause-icon"
              onClick={() => (!loading ? handleControllerClick('fan_relay') : {})}
            >
              <PauseIcon />
            </div>
          ) : (
            <div
              className="indicator-card-play-icon"
              onClick={() => (!loading ? handleControllerClick('fan_relay') : {})}
            >
              <PlayIcon />
            </div>
          )
        ) : null}
        {controllerType === 'temperature' ? (
          controllerState ? (
            <div
              className="indicator-card-pause-icon"
              onClick={() => (!loading ? sendTemperatureAction() : {})}
            >
              <PauseIcon />
            </div>
          ) : (
            <div
              className="indicator-card-play-icon"
              onClick={() => (!loading ? sendTemperatureAction(true) : {})}
            >
              <PlayIcon />
            </div>
          )
        ) : null}
      </div>
      {controller ? (
        <div className="indicator-card-controller-wrapper">
          {controllerType === 'relay' ? (
            <button
              disabled={loading}
              className={`indicator-card-controller-button ${!controllerState ? 'active' : ''}`}
              onClick={() => (!loading ? handleControllerClick('state', false) : {})}
            >
              {!loading && t('dashboard.indicators.turn_on')}
            </button>
          ) : null}
          {controllerType === 'relay' ? (
            <button
              disabled={loading}
              className={`indicator-card-controller-button ${controllerState ? 'active' : ''}`}
              onClick={() => (!loading ? handleControllerClick('state', true) : {})}
            >
              {!loading && t('dashboard.indicators.turn_off')}
            </button>
          ) : null}
          {controllerType === 'fan' ? (
            <button
              disabled={loading}
              className={`indicator-card-fan-controller-button ${loading ? 'disabled' : ''}`}
              onClick={() => (!loading ? handleControllerClick('fan_speed', null, '-') : {})}
            >
              {t('dashboard.indicators.slow_down')}
            </button>
          ) : null}
          {controllerType === 'fan' ? (
            <div className="indicator-card-fan-speed">
              {loading ? (
                <div className="indicator-card-loader-wrapper">
                  <div className="indicator-card-loader-animation small" />
                </div>
              ) : (
                subtitle
              )}
            </div>
          ) : null}
          {controllerType === 'fan' ? (
            <button
              disabled={loading}
              className={`indicator-card-fan-controller-button ${loading ? 'disabled' : ''}`}
              onClick={() => (!loading ? handleControllerClick('fan_speed', null, '+') : {})}
            >
              {t('dashboard.indicators.speed_up')}
            </button>
          ) : null}
          {controllerType === 'temperature' ? (
            <div className="indicator-card-temperature-controller-wrapper">
              <div className="indicator-card-temperature-controller-container">
                <input
                  min={0}
                  max={30}
                  step={0.1}
                  disabled={loading}
                  value={minTemperature}
                  className="indicator-card-temperature-controller-input"
                  type="number"
                  onChange={(e) => setMinTemperature(e.target.value)}
                />
                <input
                  min={0}
                  max={30}
                  step={0.1}
                  disabled={loading}
                  value={maxTemperature}
                  className="indicator-card-temperature-controller-input"
                  type="number"
                  onChange={(e) => setMaxTemperature(e.target.value)}
                />
                <button
                  disabled={loading}
                  className={`indicator-card-fan-controller-button temp ${
                    loading ? 'disabled' : ''
                  }`}
                  onClick={() => (!loading ? sendTemperatureAction(true) : {})}
                >
                  {t('dashboard.indicators.set')}
                </button>
              </div>
            </div>
          ) : null}
        </div>
      ) : (
        <div className="indicator-card-progress-wrapper">
          <DefaultProgressIndicator classModifier={progressClassModifier} value={progress} />
        </div>
      )}
    </div>
  )
}

export default IndicatorCard
