import React, { useState, createContext, useContext, useCallback } from 'react'
import NotificationBase from '../components/NotificationBase/NotificationBase'

const NotificationContext = createContext(null)

let _notificationId = 1

const getNextId = () => _notificationId++

export const NotificationProvider = ({ children }) => {
  const [messages, setMessages] = useState([])

  const addNotification = useCallback(({ header, text, timeout, type }) => {
    const message = {
      key: getNextId(),
      text,
      header,
      timeout,
      type,
    }

    setMessages((messages) => [message, ...messages])
  }, [])

  const addSuccessNotification = useCallback((param) => {
    const mainObject =
      typeof param === 'string'
        ? {
            header: param,
            type: 'success',
          }
        : { ...param, type: 'success' }

    return addNotification(mainObject)
  }, [])

  const addErrorNotification = useCallback((param) => {
    const mainObject =
      typeof param === 'string'
        ? {
            header: param,
            type: 'error',
          }
        : { ...param, type: 'error' }

    return addNotification(mainObject)
  }, [])

  return (
    <NotificationContext.Provider
      value={{ messages, addNotification, addErrorNotification, addSuccessNotification }}
    >
      <div className="notification-base-container">
        {messages.map((m) => (
          <NotificationBase
            key={m.key}
            header={m.header}
            text={m.text}
            timeout={m.timeout}
            type={m.type}
            onDismiss={() => {
              setMessages((msg) => msg.filter((m1) => m1 !== m))
            }}
          >
            {m.text}
          </NotificationBase>
        ))}
      </div>
      {children}
    </NotificationContext.Provider>
  )
}

/**
 * @typedef {Object} NotificationOptions
 * @property {string} header - Main title of notification
 * @property {string} text - Subtext of notification
 * @property {number} timeout - Timeout for notification in milliseconds
 * @property {('success'|'error')} type - Notification type
 */

/**
 * @typedef {Object} NotificationHook
 * @property {(options: NotificationOptions) => void} addNotification - base hook for creating notifications
 * @property {(optionOrText: NotificationOptions | string) => void} addSuccessNotification
 * @property {(optionOrText: NotificationOptions | string) => void} addErrorNotification
 */
export const useNotifications = () => {
  /**
   * @type {NotificationHook}
   */
  const main = useContext(NotificationContext)
  return main
}
