import React, { useState, useEffect, useRef } from 'react'
import { ReactComponent as SaveIcon } from '../../assets/SaveIcon.svg'
import { ReactComponent as RemoveIcon } from '../../assets/RemoveIcon.svg'
import { ReactComponent as CloseIcon } from '../../assets/CloseIcon.svg'
import DataService from '../../data/DataService'
import { DefaultButton, DefaultInput } from '../../components/DefaultComponents'
import { autoResizeModalTextbox } from '../../util/util'
import { useTranslation } from 'react-i18next'
import '../../components/AllEntries/AllEntries.css'
import './DiaryModal.css'

const DiaryModal = ({ onClose, diaryData, dataUpdated }) => {
  const [title, setTitle] = useState(diaryData.title)
  const [content, setContent] = useState(diaryData.content)
  const { t } = useTranslation('translations')
  const modalRef = useRef()

  useEffect(() => {
    const listener = (e) => {
      if (e.code === 'Escape') {
        onClose()
      }
    }
    document.addEventListener('keydown', listener)

    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [])

  const removeDiary = async (diaryId) => {
    const response = await DataService.removeDiary(diaryId)
    if (response === 200) {
      dataUpdated(true)
    }
    onClose()
  }

  const updateDiary = async (diaryId, body) => {
    const response = await DataService.updateDiary(diaryId, body)
    if (response === 200) {
      dataUpdated(true)
    }
    onClose()
  }

  return (
    <>
      <div
        className="edit-modal"
        ref={modalRef}
        onClick={(e) => {
          if (e.target === modalRef.current) {
            onClose()
          }
        }}
      >
        <div className="edit-modal-container-diary">
          <div className="edit-modal-box-diary">
            <div className="edit-modal-control">
              <CloseIcon className="edit-modal-close-icon" onClick={() => onClose()} />
            </div>
            <div className="edit-modal-section">
              <DefaultInput
                label={<div className="edit-modal-label">{t('diary.title')}</div>}
                defaultValue={title}
                removeMessage
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="edit-modal-section">
              <span>{t('diary.diary')}</span>
              <textarea
                defaultValue={content}
                onChange={(e) => setContent(e.target.value)}
                className="allEntries-textarea"
                onInput={(e) => autoResizeModalTextbox(e)}
              />
            </div>
            <div className="button-row">
              <DefaultButton
                onClick={() => updateDiary(diaryData.diary_id, { title, content })}
                variant={'green'}
                leftIcon
                customLeftIcon={<SaveIcon />}
              >
                {t('modals.save')}
              </DefaultButton>
              <DefaultButton
                onClick={() => removeDiary(diaryData.diary_id)}
                variant={'red'}
                leftIcon
                customLeftIcon={<RemoveIcon />}
              >
                {t('modals.remove')}
              </DefaultButton>
              <DefaultButton onClick={() => onClose()}>{t('modals.close')}</DefaultButton>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DiaryModal
