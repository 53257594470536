import React, { useState } from 'react'
import './CreateAutomationModal.css'
import { ReactComponent as AutomationsIcon } from '../../../assets/AutomationsIcon.svg'
import SSXModal from '../../../components/Modal/SSXModal/SSXModal'
import { DefaultButton, DefaultInput } from '../../../components/DefaultComponents'
import { ReactComponent as SaveIcon } from '../../../assets/SaveIcon.svg'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import DataService from '../../../data/DataService'
import { useAuth } from '../../../hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import { useNotifications } from '../../../hooks/useNotification'

const CreateAutomationModal = ({ onClose }) => {
  const { t } = useTranslation('translations')

  const [automationValidation, setAutomationValidation] = useState('')
  const [automationName, setAutomationName] = useState('')
  const { user } = useAuth()
  const navigate = useNavigate()
  const { addSuccessNotification, addErrorNotification } = useNotifications()

  const validateAutomation = async () => {
    setAutomationValidation(true)
    if (automationName) {
      try {
        const createAutomation = await DataService.addAutomation({
          name: automationName,
          user_created: true,
          user_id: user.user_id,
        })
        if (createAutomation.automation_id) {
          setAutomationValidation(false)
          addSuccessNotification({ header: t('automations.automation_created') })
          navigate(`/automations/custom-automation/${createAutomation.automation_id}`)
        } else {
          addErrorNotification({ header: t('automations.create_automation_failed') })
        }
      } catch (error) {
        addErrorNotification({ header: t('automations.create_automation_failed') })
      }
    }
  }

  return (
    <SSXModal
      header={() => (
        <div className="create-automation-modal-header">
          <AutomationsIcon></AutomationsIcon>
          <strong>{t('automations.create_automation')}</strong>
        </div>
      )}
      show={true}
      onClose={onClose}
      footer={() => (
        <DefaultButton
          onClick={() => validateAutomation()}
          customStyle={'save-btn'}
          leftIcon
          customLeftIcon={<SaveIcon />}
        >
          {t('automations.save_automation')}
        </DefaultButton>
      )}
    >
      <DefaultInput
        value={automationName}
        label={t('automations.automation_name')}
        placeholder={t('automations.enter_automation_name')}
        onChange={(e) => setAutomationName(e.target.value)}
        error={automationValidation && !automationName}
        message={automationValidation && !automationName ? 'This field is required.' : ''}
      />
    </SSXModal>
  )
}

CreateAutomationModal.propTypes = {
  onClose: PropTypes.func,
}

CreateAutomationModal.defaultProps = {
  onClose: () => {},
}

export default CreateAutomationModal
