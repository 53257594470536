import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as MoistureIndicator } from '../../../assets/MoistureIndicator.svg'
import { ReactComponent as SoilMoistureImage } from '../../../assets/SoilMoistureImage.svg'
import { ReactComponent as Temperature } from '../../../assets/Temperature.svg'
import { ReactComponent as LightIconOn } from '../../../assets/LightIconOn.svg'
import { ReactComponent as LightIconOff } from '../../../assets/LightIconOff.svg'
import { ReactComponent as HeaterIconOn } from '../../../assets/HeaterIconOn.svg'
import { ReactComponent as HeaterIconOff } from '../../../assets/HeaterIconOff.svg'
import { ReactComponent as FanIndicator } from '../../../assets/FanIndicator.svg'
import { ReactComponent as IntakeFlow } from '../../../assets/IntakeFlow.svg'
import { ReactComponent as ExhaustFlow } from '../../../assets/ExhaustFlow.svg'
import { ReactComponent as IndicatorsIcon } from '../../../assets/IndicatorsIcon.svg'
import { ReactComponent as PhIndicator } from '../../../assets/PhIndicator.svg'
import { ReactComponent as SoilPhImage } from '../../../assets/SoilPhImage.svg'
import { ReactComponent as AirMoisture } from '../../../assets/AirMoisture.svg'
import { ReactComponent as SensorCalibrationIcon } from '../../../assets/SensorCalibrationIcon.svg'
import DataService from '../../../data/DataService'
import './DashboardIndicators.css'
import { renderSensorIndicators, renderControllerIndicators } from '../../../util/util'
import DefaultButton from '../../../components/DefaultComponents/DefaultButton/DefaultButton'
import ModalHelper from '../../../components/Modal/ModalHelper'
import { useDevice } from '../../../hooks/useDevice'
import { CircularProgress } from '@mui/material'

const BoxIndicators = ({ device_id, device_address, handleRefresh }) => {
  const { t } = useTranslation('translations')
  const [deviceModuleStatus, setDeviceModuleStatus] = useState([])
  const [newDeviceStatus, setNewDeviceStatus] = useState(1)
  const [showCalibration, setShowCalibration] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [connectionStatus, setConnectionStatus] = useState(true)
  const hasIndicators =
    Array.isArray(deviceModuleStatus) && deviceModuleStatus?.some((module) => !module.is_controller)
  const hasControllers =
    Array.isArray(deviceModuleStatus) && deviceModuleStatus?.some((module) => module.is_controller)

  useEffect(() => {
    function changeStatus() {
      setConnectionStatus(navigator.onLine)
    }
    window.addEventListener('online', changeStatus)
    window.addEventListener('offline', changeStatus)
    return () => {
      window.removeEventListener('online', changeStatus)
      window.removeEventListener('offline', changeStatus)
    }
  }, [])

  const {
    deviceStatus,
    deviceData,
    automationStatus,
    isLoadingStatus: isDeviceLoading,
  } = useDevice()

  useEffect(() => {
    if (device_id) {
      getModuleStatus(device_id)
      const interval = setInterval(async () => getModuleStatus(device_id), 5000)
      return () => clearInterval(interval)
    }
  }, [newDeviceStatus, device_id])

  const getModuleStatus = async (device_id) => {
    const deviceModules = await DataService.getModuleDataForDevice(device_id)
    if (deviceModules?.data?.length) {
      // setIsAutomationActive(getAutomationStatus(deviceModules.data))
      setDeviceModuleStatus(deviceModules.data)
    }
  }

  // establishes whether the temperature regulation is active or not
  // const getAutomationStatus = (data) => {
  //   let temperatureControllerModule = null
  //   for (const module of data) {
  //     if (module.module_id === AIR_TEMPERATURE_CONTROLLER_ID) {
  //       temperatureControllerModule = module
  //     }
  //   }
  //   if (temperatureControllerModule) {
  //     if (temperatureControllerModule.value && temperatureControllerModule.value !== '-r') {
  //       return true
  //     }
  //   }
  //   return false
  // }

  const renderIndicatorIcon = (indicator) => {
    switch (indicator) {
      case 'soil_moisture_sensor':
        return (
          <div className="indicator-card-icon-wrapper">
            <MoistureIndicator />
            <SoilMoistureImage />
          </div>
        )
      case 'air_moisture_sensor':
        return <AirMoisture />
      case 'soil_acidity_sensor':
        return (
          <div className="indicator-card-icon-wrapper">
            <PhIndicator />
            <SoilPhImage />
          </div>
        )
      case 'air_temperature_sensor':
        return <Temperature />
      default:
        return ''
    }
  }

  const renderControllerIcon = (indicator, value, speed) => {
    if (indicator === 'light') {
      if (value) {
        return <LightIconOn />
      } else {
        return <LightIconOff />
      }
    }
    if (indicator === 'heater') {
      if (value) {
        return <HeaterIconOn />
      } else {
        return <HeaterIconOff />
      }
    }
    if (indicator === 'intake_fan') {
      if (value) {
        return (
          <div className="indicator-card-icon-container">
            <IntakeFlow className="indicator-card-icon-overlay" />
            <div className="indicator-card-intake-fan-position">
              <FanIndicator className={`indicator-card-fan-intake-icon speed-${speed}`} />
            </div>
          </div>
        )
      } else {
        return <FanIndicator />
      }
    }
    if (indicator === 'exhaust_fan') {
      if (value) {
        return (
          <div className="indicator-card-icon-container">
            <ExhaustFlow className="indicator-card-icon-overlay" />
            <div className="indicator-card-exhaust-fan-position">
              <FanIndicator className={`indicator-card-fan-exhaust-icon speed-${speed}`} />
            </div>
          </div>
        )
      } else {
        return <FanIndicator />
      }
    }
    if (indicator === 'little_fan') {
      if (value) {
        return (
          <div className="indicator-card-icon-container">
            <ExhaustFlow className="indicator-card-icon-overlay" />
            <div className="indicator-card-exhaust-fan-position">
              <FanIndicator className={`indicator-card-fan-exhaust-icon speed-${speed}`} />
            </div>
          </div>
        )
      } else {
        return <FanIndicator />
      }
    }
    if (indicator === 'air_temperature_controller') {
      return <Temperature />
    }
  }

  const getInstantModuleData = async (device_address) => {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 4000)
    await DataService.sendActionRequest({
      payload: 'D',
      device_type: 'GroovyBox',
      device_mac_address: device_address,
      action_name: 'InstantData',
    })
  }

  const handleRefreshButton = () => {
    getInstantModuleData(device_address)
    handleRefresh()
  }

  return (
    <div>
      {hasIndicators && (
        <>
          <div className="indicator-header">
            <div className="indicator-title">
              <IndicatorsIcon />
              {t('dashboard.indicators.title')}
            </div>
            <hr className="indicator-divider-line" />
          </div>
          <div className="indicator-container-splitter">
            {isDeviceLoading ? (
              <CircularProgress sx={{ mx: 'auto' }} color="success" />
            ) : (
              renderSensorIndicators(
                deviceModuleStatus,
                deviceStatus,
                (indicator) => renderIndicatorIcon(indicator),
                t,
                connectionStatus,
              )
            )}
          </div>
        </>
      )}
      {hasControllers && (
        <>
          <div className="indicator-header">
            <div className="indicator-title">
              <IndicatorsIcon />
              {t('dashboard.indicators.controllers')}
            </div>
            <hr className="indicator-divider-line" />
          </div>
          <div className="indicator-container-splitter">
            {isDeviceLoading ? (
              <CircularProgress sx={{ mx: 'auto' }} color="success" />
            ) : (
              renderControllerIndicators(
                deviceModuleStatus,
                deviceStatus,
                (indicator, value, speed) => renderControllerIcon(indicator, value, speed),
                t,
                () => setNewDeviceStatus(newDeviceStatus + 1),
                'GroovyBox',
                automationStatus,
                deviceData,
                connectionStatus,
              )
            )}
          </div>
          {showCalibration && (
            <ModalHelper
              deviceType={'GroovyBox'}
              onClose={() => setShowCalibration(false)}
              type={'calibration'}
            />
          )}
          <div className="inline-btns">
            {/* <DefaultButton
              leftIcon
              customLeftIcon={<SensorCalibrationIcon />}
              customStyle={'dashboard-sensor-calibration'}
              onClick={() => setShowCalibration(true)}
            >
              {t('dashboard.indicators.sensor_calibration')}
            </DefaultButton> */}
            <DefaultButton
              loading={isLoading}
              loadingText={'Refreshing ...'}
              onClick={handleRefreshButton}
            >
              {t('dashboard.indicators.refresh_data')}
            </DefaultButton>
          </div>
        </>
      )}
    </div>
  )
}

export default BoxIndicators
