import React from 'react'
import './DefaultComponent.css'

const SectionHeader = ({ title, icon, classModifier }) => {
  return (
    <div className={`your-diary-header ${classModifier}`}>
      <div className="your-diary-icon-box">
        {icon}
        <span className="your-diary-text">{title}</span>
      </div>
      <div className="divider" />
    </div>
  )
}

export default SectionHeader
