import Chart from '../Chart/Chart'
import './Charts.css'
import { ReactComponent as BarChartIcon } from '../../assets/BarChartIcon.svg'
import { getSelectedDeviceByType, formatDataForChart, formatISODate } from '../../util/util'
import { MobileDateRangePicker } from '@mui/x-date-pickers-pro/MobileDateRangePicker'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import { useEffect, useState } from 'react'
import DataService from '../../data/DataService'
import { CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CalendarIcon } from '../../assets/CalendarIcon.svg'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import { useAuth } from '../../hooks/useAuth'
import DeviceSelector from '../DeviceSelector/DeviceSelector'
import dayjs from 'dayjs'

const Charts = ({ refreshData }) => {
  const [preparedData, setPreparedData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [deviceId, setDeviceId] = useState(0)
  const [offset, setOffset] = useState('hour')
  const { selectedDeviceType, selectedBoxDevice, selectedSystemDevice, selectedControlDevice } =
    useAuth()

  const { t } = useTranslation('translations')
  const [start, setStart] = useState()
  const [end, setEnd] = useState()

  const [availableOffsets, setAvailableOffsets] = useState([])

  useEffect(() => {
    if (deviceId) {
      if (parseInt(deviceId)) {
        getModules(parseInt(deviceId), start, end, offset)
      }
    }
  }, [refreshData, deviceId])

  useEffect(() => {
    if (!start || !end) {
      setAvailableOffsets([])
      return
    }

    const startDate = new Date(start)
    const endDate = new Date(end)

    const delta = endDate.getTime() - startDate.getTime()

    let offsets = [
      { name: t('charts.hour'), value: 'hour' },
      { name: t('charts.day'), value: 'day' },
    ]

    if (delta < 1000 * 60 * 60 * 24 + 1) {
      offsets.unshift({ name: t('charts.minute'), value: 'minute' })
    }

    setAvailableOffsets(offsets)
  }, [start, end])

  useEffect(() => {
    const today = new Date()
    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)

    setStart(yesterday.toISOString())
    setEnd(today.toISOString())
  }, [])

  useEffect(() => {
    if (!start || !end || !offset) {
      return
    }

    const device_id = getSelectedDeviceByType(
      selectedBoxDevice,
      selectedSystemDevice,
      selectedControlDevice,
      selectedDeviceType,
    )
    if (parseInt(device_id)) {
      setDeviceId(parseInt(device_id))
      getModules(parseInt(device_id), start, end, offset)
    }
  }, [
    selectedDeviceType,
    selectedBoxDevice,
    selectedSystemDevice,
    selectedControlDevice,
    offset,
    start,
    end,
  ])

  const getModuleMedian = async (device_id, module_id, start, end, offset) => {
    try {
    } catch (error) {}
    const res = await DataService.getModuleMedianData({
      device_id: device_id,
      module_id: module_id,
      start: start,
      end: end,
      offset: offset,
    })
    if (res.length) {
      return formatDataForChart(res)
    }
    return {}
  }

  useEffect(() => {
    if (!availableOffsets.find((off) => off.value === offset)) {
      setOffset(availableOffsets[0]?.value)
    }
  }, [offset, availableOffsets])

  const getModules = async (device_id, start, end, offset) => {
    if (!offset) {
      return
    }
    setLoading(true)
    try {
      const res = await DataService.getDeviceModules(device_id)
      let chartData = []
      if (res.length) {
        for (const module of res) {
          chartData.push({
            module_id: module.module_id,
            module_input: module.module_input,
            name: module.name,
            data: await getModuleMedian(device_id, module.module_id, start, end, offset),
          })
        }
      }
      setPreparedData(chartData)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const formatDate = (range) => {
    if (range[0]) {
      setStart(formatISODate(range[0]) + 'T00:00')
    } else {
      setStart('')
    }
    if (range[1]) {
      setEnd(formatISODate(range[1]) + 'T23:59')
    } else {
      setEnd('')
    }
    if (range[0] && range[1]) {
      getModules(
        deviceId,
        formatISODate(range[0]) + 'T00:00',
        formatISODate(range[1]) + 'T23:59',
        offset,
      )
    }
  }

  const returnChartColor = (indicator) => {
    switch (indicator) {
      case 'soil_moisture_sensor':
        return '#508cff'
      case 'air_moisture_sensor':
        return '#508cff'
      case 'soil_acidity_sensor':
        return '#d4d298'
      case 'air_temperature_sensor':
        return '#f43633'
      case 'energy_usage_sensor':
        return '#f5ee13'
      default:
        return ''
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="charts-container">
        <div className="growing-plan-first-row">
          <div className="growing-plan-first-row-title">
            <BarChartIcon />
            {'Conditions Overview'}
          </div>
          <hr className="growing-plan-divider-line" />
        </div>
        <div className="conditions-overview-container">
          <div className="automations-stage-date-picker-container">
            <MobileDateRangePicker
              value={[dayjs(start ?? new Date()), dayjs(end ?? new Date())]}
              className="date-range-picker mobile"
              onChange={(e) => formatDate(e)}
              slotProps={{
                textField: {
                  focused: true,
                  InputProps: { endAdornment: <CalendarIcon className="calendar-icon" /> },
                },
              }}
              localeText={{
                start: t('automations.stage_setup.start'),
                end: t('automations.stage_setup.end'),
              }}
            />
            <DateRangePicker
              value={[dayjs(start ?? new Date()), dayjs(end ?? new Date())]}
              className="date-range-picker"
              onChange={(e) => formatDate(e)}
              slotProps={{
                textField: {
                  focused: true,
                  InputProps: { endAdornment: <CalendarIcon className="calendar-icon" /> },
                },
              }}
              localeText={{
                start: t('automations.stage_setup.start'),
                end: t('automations.stage_setup.end'),
              }}
            />
          </div>
          <div className="charts-offset-selector-container">
            <div className="charts-offset-selector-label">{t('charts.select_offset')}</div>
            <DeviceSelector
              onSelect={(value) => setOffset(value)}
              value={offset}
              options={availableOffsets}
            />
          </div>
        </div>
        {!loading && !!preparedData.length && (
          <Chart chartData={preparedData} labels={preparedData[1]?.data.dates} />
        )}

        {!loading && Array.isArray(preparedData) && preparedData.length < 1 && (
          <div>{t('charts.chart_data_error')}</div>
        )}

        {loading && <CircularProgress sx={{ mx: 'auto' }} color="success" />}
      </div>
    </LocalizationProvider>
  )
}

export default Charts
