import React from 'react'
import PropTypes from 'prop-types'
import './DefaultCheckbox.css'

const DefaultCheckbox = ({ type, fill, ...restProps }) => {
  return (
    <label className={`custom-checkbox ${type} ${fill}`}>
      <input readOnly {...restProps} type="checkbox" />
      <span></span>
    </label>
  )
}

DefaultCheckbox.propTypes = {
  type: PropTypes.string
}

DefaultCheckbox.defaultProps = {
  type: 'default',
  fill: 'checkmark'
}

export default DefaultCheckbox;