import React, { useState, createContext, useContext, useEffect } from 'react'
import { useAuth } from './useAuth'
import DataService from '../data/DataService'

const DeviceContext = createContext(null)

export const DeviceProvider = ({ children }) => {
  const [deviceStatus, setDeviceStatus] = useState(true)
  const [automationStatus, setAutomationStatus] = useState(true)
  const [deviceId, setDeviceId] = useState('')
  const [deviceData, setDeviceData] = useState()
  const [isLoadingStatus, setIsLoadingStatus] = useState(false)
  const [isLoadingData, setIsLoadingData] = useState(false)
  const { selectedDeviceType, selectedBoxDevice, selectedSystemDevice, selectedControlDevice } =
    useAuth()

  useEffect(() => {
    setIsLoadingStatus(true)
    setIsLoadingData(true)
    switch (selectedDeviceType) {
      case 'groovyBox':
        setDeviceId(selectedBoxDevice ?? '')
        break
      case 'groovySystem':
        setDeviceId(selectedSystemDevice ?? '')
        break
      case 'groovyControl':
        setDeviceId(selectedControlDevice ?? '')
        break
      default:
        setDeviceId('')
    }
  }, [selectedDeviceType, selectedBoxDevice, selectedSystemDevice, selectedControlDevice])

  useEffect(() => {
    getDeviceStatus(deviceId)
    getAutomationStatus(deviceId)
    const deviceStatusInterval = setInterval(async () => {
      getDeviceStatus(deviceId)
      getAutomationStatus(deviceId)
    }, 30000)
    return () => clearInterval(deviceStatusInterval)
  }, [deviceId])

  useEffect(() => {
    fetchData()
  }, [deviceId])

  const getAutomationStatus = async (device_id) => {
    if (!deviceId) {
      setDeviceStatus(false)
      setIsLoadingStatus(false)
      return
    }

    const automationStatus = await DataService.getDeviceAutomationStatus(device_id)
    if (automationStatus === 'active') {
      setAutomationStatus(true)
    } else {
      setAutomationStatus(false)
    }
    setIsLoadingStatus(false)
  }

  const getDeviceStatus = async (device_id) => {
    if (!deviceId) {
      setDeviceStatus(false)
      setIsLoadingStatus(false)
      return
    }

    const status = await DataService.checkDeviceStatus(device_id)
    // change status to check for 1 if online and 2 if offline
    if (status === 'OK') {
      setDeviceStatus(true)
    } else {
      setDeviceStatus(false)
    }
    setIsLoadingStatus(false)
  }

  const fetchData = async () => {
    if (!deviceId) {
      setDeviceData(null)
      setIsLoadingData(false)
      return
    }

    try {
      const data = await DataService.getDeviceCurrentSettings(deviceId)
      setDeviceData(data)
      setIsLoadingData(false)
    } catch (error) {
      console.error('Error fetching device current settings:', error)
      setDeviceData(null)
      setIsLoadingData(false)
    }
  }

  const refreshData = async () => {
    setIsLoadingData(true)

    await fetchData()
  }

  const refreshAutomationStatus = async () => {
    setIsLoadingStatus(true)
    await getAutomationStatus(deviceId)
  }

  return (
    <DeviceContext.Provider
      value={{
        deviceStatus,
        deviceData,
        refreshData,
        refreshAutomationStatus,
        isLoadingStatus,
        isLoadingData,
        automationStatus,
      }}
    >
      {children}
    </DeviceContext.Provider>
  )
}

export const useDevice = () => useContext(DeviceContext)
