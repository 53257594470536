import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { DefaultButton } from '../../DefaultComponents'
import { useState } from 'react'
import { ReactComponent as FolderIcon } from '../../../assets/FolderIcon.svg'
import { ReactComponent as SaveTemplateIcon } from '../../../assets/SaveTemplateIcon.svg'
import { ReactComponent as SaveIcon } from '../../../assets/SaveIcon.svg'
import { useNotifications } from '../../../hooks/useNotification'
import DataService from '../../../data/DataService'
import MainStageForm, { ValidateStageForm } from './MainStageForm'
import { useDevice } from '../../../hooks/useDevice'

const StageCreateEditForm = ({
  initialData,
  setStageLoading,
  onSaved,
  automationId,
  onLoadTemplatePressed,
}) => {
  const { t } = useTranslation('translations')
  const [errorMessages, setErrorMessages] = useState()
  const { addErrorNotification, addSuccessNotification } = useNotifications()
  const { refreshData } = useDevice()

  const [formData, setFormData] = useState()

  const updateStage = async () => {
    setStageLoading?.(true)

    try {
      const payload = {
        name: formData.name,
        start: formData.start,
        end: formData.end,
        temperature_min: formData.temperature_min,
        temperature_max: formData.temperature_max,
        moisture_value: formData.moisture_value,
        user_id: formData.user_id,
        light_intervals: formData.light_intervals,
        automation_id: automationId,
      }

      const resp = await DataService.updateStageWithLightIntervals(payload, formData.stage_id)

      if (resp !== 200) {
        if (resp.response.status === 409) {
          addErrorNotification({
            header: 'Failed to save stage',
            text: 'Stage overlaps with some other stages',
          })
        } else {
          throw resp
        }
      } else {
        addSuccessNotification({
          header: 'Stage saved',
        })
        onSaved?.()
      }
      refreshData()
    } catch (error) {
      console.error('err', error)
      addErrorNotification({
        header: 'Failed to save stage',
      })
    } finally {
      setStageLoading?.(false)
    }
  }

  const createStage = async (isTemplate = false) => {
    setStageLoading?.(true)

    try {
      const payload = {
        name: formData.name,
        automation_id: automationId,
        start: formData.start,
        end: formData.end,
        temperature_min: formData.temperature_min,
        temperature_max: formData.temperature_max,
        moisture_value: formData.moisture_value,
        user_id: formData.user_id,
        light_intervals: formData.light_intervals,
      }

      const resp = isTemplate
        ? await DataService.addStageTemplate(payload)
        : await DataService.addStage(payload)

      if (resp !== 201) {
        if (resp.response.status === 409) {
          addErrorNotification({
            header: 'Failed to create stage',
            text: 'Stage overlaps with some other stages',
          })
        } else {
          throw resp
        }
      } else {
        addSuccessNotification({
          header: 'Stage created',
        })
        onSaved?.()
      }
      refreshData()
    } catch (error) {
      console.error('err', error)
      addErrorNotification({
        header: 'Failed to create stage',
      })
    } finally {
      setStageLoading?.(false)
    }
  }

  const validateStage = () => {
    if (!formData) {
      return
    }
    const errors = ValidateStageForm(formData)
    setErrorMessages(errors)

    return Object.keys(errors).length < 1
  }

  return (
    <div className="stage-setup-form">
      <MainStageForm onDataChange={setFormData} initialData={initialData} errors={errorMessages} />
      <div className="inline-btns-stage-modal">
        <DefaultButton
          customStyle={'save-btn button-style-mobile'}
          onClick={() => {
            if (validateStage()) {
              if (formData.stage_id) {
                updateStage()
              } else {
                createStage()
              }
            }
          }}
          leftIcon
          customLeftIcon={<SaveIcon />}
        >
          {t('automations.stage_setup.save_stage')}
        </DefaultButton>
        <DefaultButton
          leftIcon
          customStyle={'button-style-mobile'}
          onClick={() => {
            if (validateStage()) {
              createStage(true)
            }
          }}
          customLeftIcon={<SaveTemplateIcon />}
        >
          {t('automations.stage_setup.save_template')}
        </DefaultButton>
        <DefaultButton
          leftIcon
          onClick={() => {
            onLoadTemplatePressed()
          }}
          customStyle={'button-style-mobile'}
          customLeftIcon={<FolderIcon className="icon" />}
        >
          {t('automations.stage_setup.load_from_templates')}
        </DefaultButton>
      </div>
    </div>
  )
}

StageCreateEditForm.propTypes = {
  automationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  initialData: PropTypes.shape({
    name: PropTypes.string,
    automation_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    start: PropTypes.string,
    end: PropTypes.string,
    temperature_min: PropTypes.string,
    temperature_max: PropTypes.string,
    stage_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    light_intervals: PropTypes.arrayOf(
      PropTypes.shape({
        start_time: PropTypes.string,
        end_time: PropTypes.string,
        light_interval_id: PropTypes.number,
        stage_id: PropTypes.number,
      }),
    ),
  }),
  setStageLoading: PropTypes.func,
  onSaved: PropTypes.func,
  onLoadTemplatePressed: PropTypes.func,
}

StageCreateEditForm.defaultProps = {
  initialData: {},
  setStageLoading: () => {},
  onSaved: () => {},
  onStageTemplateLoad: () => {},
  onStageTemplateEdit: () => {},
  onLoadTemplatePressed: () => {},
}

export default StageCreateEditForm
