import React, { useRef, useState } from 'react'
import { ReactComponent as SaveIcon } from '../../../assets/SaveIcon.svg'
import { ReactComponent as RemoveIcon } from '../../../assets/RemoveIcon.svg'
import { ReactComponent as CloseIcon } from '../../../assets/CloseIcon.svg'
import { DefaultButton, DefaultInput } from '../../../components/DefaultComponents'
import { autoResizeModalTextbox } from '../../../util/util'
import './SettingsModal.css'
import DataService from '../../../data/DataService'
import { useTranslation } from 'react-i18next'

const SettingsModal = ({ onClose, deviceData, dataUpdated }) => {
  const [name, setName] = useState(deviceData.name || '')
  const [order, setOrder] = useState(deviceData.order || 0)
  const [notes, setNotes] = useState(deviceData.notes || '')
  const [showError, setShowError] = useState('')
  const [showWarningModal, setShowWarningModal] = useState(false)
  const { t } = useTranslation('translations')
  const modalRef = useRef()

  const removeDevice = async () => {
    const resp = await DataService.removeDevice(deviceData.device_id)
    if (resp === 200) {
      dataUpdated()
      setShowError('')
      onClose()
    } else {
      setShowError(t('modals.settings.delete_device_error'))
    }
  }

  const updateDevice = async () => {
    const resp = await DataService.updateDeviceSettings(
      { name: name, order: order, notes: notes },
      deviceData.device_id,
    )
    if (resp === 200) {
      dataUpdated()
      setShowError('')
      onClose()
    } else {
      setShowError(t('modals.settings.update_device_error'))
    }
  }

  return (
    <>
      <div
        className="edit-modal"
        ref={modalRef}
        onClick={(e) => {
          if (e.target === modalRef.current) {
            onClose()
          }
        }}
      >
        <div className="edit-modal-container-settings">
          <div className="edit-modal-box-settings">
            {showWarningModal ? (
              <div>
                <h3>{t('modals.settings.warning')}</h3>
                <div>{t('modals.settings.warning_text')}</div>
                <div className="btns-inline">
                  <DefaultButton customStyle={'btn btn-confirm'} onClick={removeDevice}>
                    {t('modals.settings.yes')}
                  </DefaultButton>
                  <DefaultButton
                    customStyle={'btn btn-cancel'}
                    onClick={() => setShowWarningModal(false)}
                  >
                    {t('modals.settings.no')}
                  </DefaultButton>
                </div>
              </div>
            ) : (
              <>
                <div className="edit-modal-control">
                  <CloseIcon className="edit-modal-close-icon" onClick={() => onClose()} />
                </div>
                <div className="edit-modal-section">
                  <DefaultInput
                    label={<div className="edit-modal-label">{t('modals.settings.name')}</div>}
                    defaultValue={name || ''}
                    removeMessage
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="edit-modal-section">
                  <DefaultInput
                    label={<div className="edit-modal-label">{t('modals.settings.order')}</div>}
                    defaultValue={order || 0}
                    removeMessage
                    onChange={(e) => setOrder(e.target.value)}
                  />
                </div>
                <div className="edit-modal-section">
                  <span>{t('modals.settings.notes')}</span>
                  <textarea
                    defaultValue={notes || ''}
                    onChange={(e) => setNotes(e.target.value)}
                    className="device-textarea"
                    onInput={(e) => autoResizeModalTextbox(e)}
                  />
                </div>
                {showError && (
                  <div className="settings-error-message">
                    {t('modals.settings.update_device_error')}
                  </div>
                )}
                <div className="button-row">
                  <DefaultButton
                    onClick={() => updateDevice(deviceData.id, { name, order, notes })}
                    variant={'green'}
                    leftIcon
                    customLeftIcon={<SaveIcon />}
                  >
                    {t('modals.save')}
                  </DefaultButton>
                  <DefaultButton
                    onClick={() => setShowWarningModal(true)}
                    variant={'red'}
                    leftIcon
                    customLeftIcon={<RemoveIcon />}
                  >
                    {t('modals.remove')}
                  </DefaultButton>
                  <DefaultButton onClick={() => onClose()}>{t('modals.close')}</DefaultButton>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default SettingsModal
