import './SettingsScreen.css'
import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useAuth } from '../../hooks/useAuth'
import SectionHeader from '../../components/DefaultComponents/SectionHeader'
import { ReactComponent as SettingsIcon } from '../../assets/SettingsIcon.svg'
import defaultAvatar from '../../assets/DefaultAvatar.png'
import DefaultButton from '../../components/DefaultComponents/DefaultButton/DefaultButton'
import Table from '../../components/Table/Table'
import { ReactComponent as RemoveIcon } from '../../assets/RemoveIcon.svg'
import DefaultCheckbox from '../../components/DefaultComponents/DefaultCheckbox/DefaultCheckbox'
import DataService from '../../data/DataService'
import { DefaultDialog } from '../../components/DefaultComponents'
import { defaultCustomNoficiations } from '../../util/constants'
import { useTranslation } from 'react-i18next'

const SettingsScreen = () => {
  const { userSettings, user, updateUser } = useAuth()
  const [notificationType, setNotificationType] = useState(userSettings?.notificationType ?? '')
  const [customNotifications, setCustomNotifications] = useState(
    userSettings?.customNotifications ?? defaultCustomNoficiations,
  )
  const [profileImage, setProfileImage] = useState(user.image_url || defaultAvatar)
  const [profileNickname, setProfileNickname] = useState(user?.nickname || '')
  const [update, setUpdate] = useState(false)
  const [devices, setDevices] = useState([])
  const [showDefaultModal, setShowDefaultModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const { t } = useTranslation('translations')

  useEffect(() => {
    getDeviceData()
    setUpdate(false)
  }, [update])

  useEffect(() => {
    updateNotificationSettings()
  }, [notificationType, customNotifications])

  useEffect(() => {
    const timeOutId = setTimeout(async () => {
      const resp = await DataService.updateUserColumn(
        { data: profileNickname || 'Groover', columnTitle: t('settings.nickname') },
        user.user_id,
      )
      if (resp !== 200) {
        setShowDefaultModal(true)
        setErrorMessage(t('settings.nickname_saving_failed'))
        return
      }
      await updateUser()
    }, 500)
    return () => clearTimeout(timeOutId)
  }, [profileNickname])

  const dataUpdated = () => {
    setUpdate(true)
  }

  const handleImageUpload = async (file) => {
    const formData = new FormData()
    formData.append('file', file)
    if (file) {
      const resp = await DataService.uploadProfileImage(formData, user.user_id)
      if (resp !== 200) {
        setShowDefaultModal(true)
        setErrorMessage(t('settings.image_saving_failed'))
        return false
      }
      return true
    }
  }

  const handleImageChange = async (e) => {
    const image = e.target.files[0]
    if (!image) {
      return
    }
    const resp = await handleImageUpload(image)
    if (resp) {
      await updateUser()
      setProfileImage(URL.createObjectURL(image))
    }
  }

  const getDeviceData = async () => {
    const resp = await DataService.allDevicesUser(user.email)
    if (resp) {
      setDevices(resp)
    }
  }

  const handleChangeNickname = async (e) => {
    const nickname = e.target.value
    setProfileNickname(nickname)
  }

  const updateNotificationSettings = async () => {
    let notification
    if (notificationType === 'custom') {
      const trueValues = Object.keys(customNotifications).filter((key) => customNotifications[key])
      const custom = trueValues.join(', ')
      notification = custom
    } else {
      notification = notificationType
    }
    const resp = await DataService.updateUserColumn(
      { data: notification, columnTitle: 'notifications' },
      user.user_id,
    )
    if (resp !== 200) {
      setShowDefaultModal(true)
      setErrorMessage(t('settings.notification_saving_failed'))
    }
  }

  const imageUploadRef = useRef(null)

  const notificationsLayout = [
    { name: t('settings.turn_of_notifications'), value: 'off' },
    { name: t('settings.only_mandatory'), value: 'mandatory' },
    { name: t('settings.custom'), value: 'custom' },
  ]

  const customNotificationOptions = [
    { name: t('settings.system_updates'), value: 'systemUpdates' },
    { name: t('settings.progress'), value: 'progress' },
    { name: t('settings.alerts'), value: 'alerts' },
    { name: t('settings.advices'), value: 'advices' },
    { name: t('settings.plant_status'), value: 'plantStatus' },
    { name: t('settings.promotional'), value: 'promotional' },
  ]

  const handleDeletePicture = async () => {
    const formData = new FormData()
    formData.append('file', null)
    const resp = await DataService.uploadProfileImage(formData, user.user_id)
    if (resp !== 200) {
      setShowDefaultModal(true)
      setErrorMessage(t('settings.image_deleting_failed'))
    }
    await updateUser()
    setProfileImage(defaultAvatar)
  }

  return (
    <div className="settingsScreen-mainContainer">
      <DefaultDialog
        classModifier={'error-dialog'}
        header={t('settings.error')}
        open={showDefaultModal}
        onClose={() => setShowDefaultModal(false)}
      >
        {errorMessage}
      </DefaultDialog>
      <SectionHeader
        title={t('settings.settings')}
        icon={<SettingsIcon fill="var(--Foundation-Black-black-500, #131010)" />}
        classModifier={'pt0'}
      />
      {/* <div className="settingsScreen-notificationArea">
        <div className="settingsScreen-notificationArea-title">{'Notifications'}</div>
        {notificationsLayout.map((notification, i) => (
          <div
            key={i}
            className="settingsScreen-notificationArea-item"
            onClick={() => setNotificationType(notification.value)}
          >
            <DefaultCheckbox type={'round'} checked={notification.value === notificationType} />
            {notification.name || ''}
          </div>
        ))}
        {notificationType && notificationType === 'custom' && (
          <div className="settingsScreen-notificationArea-customRow">
            {customNotificationOptions.map((customNotification, i) => (
              <div key={i} className="settingsScreen-notificationArea-customRow-item">
                <DefaultCheckbox
                  checked={customNotifications[customNotification.value]}
                  onClick={() =>
                    setCustomNotifications((prevState) => ({
                      ...prevState,
                      [customNotification.value]: !prevState[customNotification.value],
                    }))
                  }
                />
                {customNotification.name || ''}
              </div>
            ))}
          </div>
        )}
      </div> */}
      <div className="settingsScreen-editProfileArea">
        <div className="settingsScreen-editProfileArea-title">{'Edit Profile'}</div>
        <div className="settingsScreen-editProfileArea-imageRow">
          <div className="settingsScreen-editProfileArea-imageRow-imageBox">
            <img alt="avatar" src={profileImage} />
          </div>
          <div className="settingsScreen-editProfileArea-imageRow-buttonsColumn">
            <DefaultButton
              leftIcon
              variant={'dynamic'}
              onClick={() => imageUploadRef.current.click()}
            >
              {t('settings.browse')}
            </DefaultButton>
            <DefaultButton leftIcon customLeftIcon={<RemoveIcon />} onClick={handleDeletePicture}>
              {t('settings.delete')}
            </DefaultButton>
            <form encType="multipart/form-data">
              <input
                type="file"
                ref={imageUploadRef}
                style={{ display: 'none' }}
                accept=".jpeg, .png, .webp"
                onChange={handleImageChange}
              />
            </form>
          </div>
        </div>
        <div className="settingsScreen-editProfileArea-nicknameRow">
          <div className="settingsScreen-editProfileArea-nicknameRow-title">{'Nickname'}</div>
          <input
            className="settingsScreen-editProfileArea-nicknameRow-input"
            value={profileNickname}
            onChange={handleChangeNickname}
          />
        </div>
      </div>
      <div className="settingsScreen-deviceSettingsArea">
        <div className="settingsScreen-deviceSettingsArea-title">{'Device Settings'}</div>
      </div>
      {devices && devices.length > 0 && (
        <Table type={'settings'} dataUpdated={dataUpdated} tableData={devices} />
      )}
    </div>
  )
}

SettingsScreen.propTypes = {
  deviceSettings: PropTypes.array.isRequired,
}

SettingsScreen.defaultProps = {
  deviceSettings: [{ orderNumber: 1, title: 'Default Prop', content: 'Default Prop' }],
}
export default SettingsScreen
