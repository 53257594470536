// App.js
// import React, { useEffect, useState } from 'react'
import React, { useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Login from './components/Walkthrough/Login/Login'
import Dashboard from './screens/DashboardScreen/Dashboard'
import Automations from './screens/AutomationsScreen/Automations'
import Diary from './screens/DiaryScreen/Diary'
import Upgrade from './screens/UpgradeScreen/Upgrade'
import AdminDashboard from './screens/AdminScreen/AdminDashboard'
import HelpScreen from './screens/HelpScreen/HelpScreen'
import Gallery from './screens/GalleryScreen/Gallery'
import SettingsScreen from './screens/SettingsScreen/SettingsScreen'
import ManageDeviceAutomations from './components/AutomationsComponents/ManageDeviceAutomation'
// import LiveScreen from './components/LiveScreen/LiveScreen'
// import Hello from './components/Walkthrough/Hello/Hello'
import Registration from './components/Walkthrough/Register/Registration'
import ConnectDevice from './components/Walkthrough/ConnectDevice/ConnectDevice'
import SystemChecks from './components/Walkthrough/SystemChecks/SystemChecks'
import './App.css'
import './colors.css'
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute'
import { useAuth } from './hooks/useAuth'
import { NotFoundScreen } from './screens/NotFoundScreen/NotFoundScreen'
import { LicenseInfo } from '@mui/x-date-pickers-pro'
import CustomAutomationV2 from './components/AutomationsComponents/CustomAutomation/CustomAutomationV2'
import RuleEngine from './screens/RuleEngine/RuleEngine'

function App() {
  const theme = 'default'
  const { isLoading, user } = useAuth()

  //Here because mobile screens can have url bar added on top which takes up space; the convetional 100vh would be buggy
  const setDynamicHeight = () => {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }

  setDynamicHeight()

  useEffect(() => {
    window.addEventListener('resize', setDynamicHeight)

    return () => {
      window.removeEventListener('resize', setDynamicHeight)
    }
  }, [])

  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE)

  if (isLoading) {
    return <></>
  }
  return (
    <div className={`theme-provider ${theme}`}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/automations"
            element={
              <ProtectedRoute>
                <Automations />
              </ProtectedRoute>
            }
          />
          <Route
            path="/gallery"
            element={
              <ProtectedRoute>
                <Gallery />
              </ProtectedRoute>
            }
          />
          <Route
            path="/automations/custom-automation/:id"
            element={
              <ProtectedRoute>
                <CustomAutomationV2 />
              </ProtectedRoute>
            }
          />
          <Route
            path="/automations/custom-automation"
            element={
              <ProtectedRoute>
                <CustomAutomationV2 />
              </ProtectedRoute>
            }
          />
          <Route
            path="/automations/manage-device-automations"
            element={
              <ProtectedRoute>
                <ManageDeviceAutomations />
              </ProtectedRoute>
            }
          />
          <Route
            path="/diary"
            element={
              <ProtectedRoute>
                <Diary />
              </ProtectedRoute>
            }
          />
          <Route
            path="/help"
            element={
              <ProtectedRoute>
                <HelpScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <ProtectedRoute>
                <SettingsScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/upgrade"
            element={
              <ProtectedRoute>
                <Upgrade />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin"
            element={
              <ProtectedRoute>
                <AdminDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ruleengine"
            element={
              <ProtectedRoute>
                <RuleEngine />
              </ProtectedRoute>
            }
          />
          {/* <Route path="/live" element={<LiveScreen />} /> */}
          {/* <Route path="/welcome" element={<Hello />} /> */}
          <Route
            path="/register"
            element={
              <ProtectedRoute>
                <Registration />{' '}
              </ProtectedRoute>
            }
          />
          <Route
            path="/login"
            element={
              <ProtectedRoute>
                <Login />
              </ProtectedRoute>
            }
          />
          <Route
            path="/connect"
            element={
              <ProtectedRoute>
                <ConnectDevice />
              </ProtectedRoute>
            }
          />
          <Route
            path="/system-check"
            element={
              <ProtectedRoute>
                <SystemChecks />
              </ProtectedRoute>
            }
          />
          {user?.role?.toLowerCase() === 'admin' && (
            <Route
              path="/admin-dashboard"
              element={
                <ProtectedRoute>
                  <AdminDashboard />
                </ProtectedRoute>
              }
            />
          )}
          <Route path="*" element={<NotFoundScreen />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
