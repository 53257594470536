import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import DataService from '../../../data/DataService'
import { useGoogleLogin } from '@react-oauth/google'
import RecaptchaDialog from '../../RecaptchaDialog/RecaptchaDialog'
import { useTranslation } from 'react-i18next'
import { DisplayErrorMessage } from '../../../util/util'
import { DefaultButton, DefaultInput, DefaultLink } from '../../DefaultComponents'
import WalkthroughContainerV2 from '../WalkthroughContainerV2/WalkthroughContainerV2'
import { ReactComponent as IconShowPassword } from '../../../assets/IconShowPassword.svg'
import { ReactComponent as IconHidePassword } from '../../../assets/IconHidePassword.svg'
import { ReactComponent as FacebookIcon } from '../../../assets/FacebookIcon.svg'
import { ReactComponent as AppleIcon } from '../../../assets/AppleIcon.svg'
import { ReactComponent as GoogleIcon } from '../../../assets/GoogleIcon.svg'
import { ReactComponent as EmailIcon } from '../../../assets/EmailIcon.svg'
import { ReactComponent as KeyIcon } from '../../../assets/KeyIcon.svg'
import './Registration.css'
import { useAuth } from '../../../hooks/useAuth'

const Registration = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState('password')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [showConfirmPassword, setShowConfirmPassword] = useState('password')
  const [error, setError] = useState('')
  const [validation, setValidation] = useState(false)
  const [displayDialog, setDisplayDialog] = useState(false)
  const [userAgreement, setUserAgreement] = useState(false)
  const { t } = useTranslation('translations')
  const navigate = useNavigate()
  const { getUserAccessToken } = useAuth()

  const openDialog = () => {
    setValidation(true)
    if (!userAgreement) {
      return
    }
    if (
      DisplayErrorMessage('email', true, email, password, confirmPassword, error, t) ||
      DisplayErrorMessage('password', true, email, password, confirmPassword, '', t)
    ) {
      return
    }
    setDisplayDialog(true)
  }

  const submit = async (captchaValue) => {
    if (captchaValue) {
      setDisplayDialog(false)
      const register = await DataService.registerUser(email, password, captchaValue)
      if (register === 'OK') {
        setValidation(false)
        await getUserAccessToken()
        navigate('/connect')
      } else {
        if (register?.response?.status === 400) {
          setError(register?.response?.data?.message)
        }
      }
    }
  }

  const googleLogin = useGoogleLogin({
    flow: 'auth-flow',
    onSuccess: async (codeResponse) => {
      const signIn = await DataService.signInSSO(codeResponse.code)
      if (signIn === 'OK') {
        await getUserAccessToken()
        navigate('/connect')
      }
    },
    onError: (error) => console.log('Login Failed:', error),
  })

  const renderMessage = (type, customError) => {
    const message = DisplayErrorMessage(
      type,
      validation,
      email,
      password,
      confirmPassword,
      customError,
      t,
    )
    return message
  }

  return (
    <WalkthroughContainerV2>
      <div className="registration-container">
        {displayDialog && (
          <RecaptchaDialog
            displayDialog={displayDialog}
            onSubmit={(captchaValue) => submit(captchaValue)}
            onCancel={() => setDisplayDialog(false)}
          />
        )}
        <div className="registration-title">{t('registration.title')}</div>
        <div className="registration-content-splitter">
          <div className="registration-form-container">
            <DefaultInput
              onChange={(e) => {
                setEmail(e.target.value)
                setError('')
              }}
              label={
                <div className="registration-label-container">
                  <EmailIcon className="registration-email-icon" />
                  {t('registration.email')}
                </div>
              }
              error={renderMessage('email', error)}
              message={renderMessage('email', error)}
            />
            <DefaultInput
              onChange={(e) => {
                setPassword(e.target.value)
              }}
              label={
                <div className="registration-label-container">
                  <KeyIcon className="registration-key-icon" />
                  {t('registration.password')}
                </div>
              }
              type={showPassword}
              icon={
                showPassword === 'text' ? (
                  <IconShowPassword
                    onClick={() => setShowPassword('password')}
                    className="show-password-icon"
                  />
                ) : (
                  <IconHidePassword
                    onClick={() => setShowPassword('text')}
                    className="show-password-icon"
                  />
                )
              }
              error={renderMessage('password', '')}
              message={renderMessage('password', '')}
            />
            <DefaultInput
              onChange={(e) => {
                setConfirmPassword(e.target.value)
              }}
              label={
                <div className="registration-label-container">
                  <KeyIcon className="registration-key-icon" />
                  {t('registration.confirm_password')}
                </div>
              }
              type={showConfirmPassword}
              icon={
                showConfirmPassword === 'text' ? (
                  <IconShowPassword
                    onClick={() => setShowConfirmPassword('password')}
                    className="show-password-icon"
                  />
                ) : (
                  <IconHidePassword
                    onClick={() => setShowConfirmPassword('text')}
                    className="show-password-icon"
                  />
                )
              }
              error={renderMessage('confirm_password', '')}
              message={renderMessage('confirm_password', '')}
            />
            <div className="registration-agreement-container">
              <div className="registration-agreement-checkbox">
                <input type="checkbox" onChange={() => setUserAgreement(!userAgreement)} />
              </div>
              <div className="registration-agreement-links">
                {t('registration.accept')}
                <DefaultLink href={'user-agreement'} classModifier={'agreement-link'}>
                  {t('registration.user_agreement')}
                </DefaultLink>
                &
                <DefaultLink href={'privacy-policy'} classModifier={'agreement-link'}>
                  {t('registration.privacy_policy')}
                </DefaultLink>
              </div>
            </div>
            <div className="registration-error-message">
              {!userAgreement && validation ? t('registration.agreement_error') : '\u00a0'}
            </div>
          </div>
          <div className="registration-alternative-registration">
            <div>{t('registration.register_with')}</div>
            <div className="registration-alternative-container">
              <button className="registration-alternative">
                <FacebookIcon className="registration-alternative-icon" />
              </button>
              <button className="registration-alternative">
                <AppleIcon className="registration-alternative-icon" />
              </button>
              <button
                onClick={() => {
                  googleLogin()
                }}
                className="registration-alternative"
              >
                <GoogleIcon className="registration-alternative-icon" />
              </button>
            </div>
            <div className="registration-have-account">
              <div>{t('registration.have_account') + '\u00a0'}</div>
              <div onClick={() => navigate('/login')} className="registration-link">
                {t('registration.sign_in')}
              </div>
            </div>
          </div>
        </div>
        <div className="registration-button-container">
          <DefaultButton onClick={() => openDialog()}>{t('registration.register')}</DefaultButton>
        </div>
      </div>
    </WalkthroughContainerV2>
  )
}

export default Registration
