import React from 'react'
import { useNavigate } from 'react-router-dom'
import './DefaultLink.css'

const DefaultLink = ({ children, classModifier, to, ...restProps }) => {
  const navigate = useNavigate()

  const navigateTo = () => {
    if (to) {
      navigate(to)
    }
  }
  return (
    <a
      {...restProps}
      onClick={() => navigateTo()}
      className={`default-link ${classModifier || ''}`}
    >
      {children}
    </a>
  )
}

export default DefaultLink
