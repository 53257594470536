import React, { useState, useEffect, useRef } from 'react'
import './SSXMultipleDropdownSelector.css'
import { ReactComponent as SelectorIcon } from '../../../assets/SelectorIcon.svg'
import PropTypes from 'prop-types'
import SSXCheckbox from '../SSXCheckbox/SSXCheckbox'
import { useTranslation } from 'react-i18next'

const SSXMultipleDropdownSelector = ({
  onSelect,
  options,
  values,
  onDisplay,
  onValueCompare,
  label,
  placeholder,
}) => {
  const [active, setActive] = useState(false)
  const dropdownButtonRef = useRef(null)
  const { t } = useTranslation('translations')

  useEffect(() => {
    const closeOpenMenus = (e) => {
      if (dropdownButtonRef.current && active && !dropdownButtonRef.current.contains(e.target)) {
        setActive(false)
      }
    }

    document.addEventListener('mousedown', closeOpenMenus)

    return () => {
      document.removeEventListener('mousedown', closeOpenMenus)
    }
  }, [active])

  return (
    <div className="multiple-dropdown-selector-container" ref={dropdownButtonRef}>
      {label && <div className="default-input-label">{label}</div>}
      <div
        tabIndex={0}
        className="multiple-dropdown-selector-wrapper"
        onClick={() => setActive(!active)}
      >
        <div className={`multiple-dropdown-selector-icon-container ${active ? 'active' : ''}`}>
          <SelectorIcon />
        </div>
        <div className="multiple-dropdown-selector-text">
          {values.length > 0
            ? values.map(onDisplay).join(',')
            : placeholder ?? t('automations.no_items_selected')}
        </div>
      </div>
      <div className={`multiple-dropdown-selector-item-container ${active ? 'active' : ''}`}>
        {active &&
          options.map((item, i) => {
            return (
              <SSXCheckbox
                key={item?.key ?? item}
                checked={!!values.find((val) => onValueCompare(val, item))}
                onChange={(e) => {
                  onSelect(item, i)
                }}
              >
                {onDisplay(item)}
              </SSXCheckbox>
            )
          })}
      </div>
    </div>
  )
}

SSXMultipleDropdownSelector.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  ).isRequired,
  values: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  onDisplay: PropTypes.func,
  onValueCompare: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
}

SSXMultipleDropdownSelector.defaultProps = {
  options: [{ value: 1, name: 'My tickets' }],
  values: [],
  onSelect: () => {},
  onDisplay: (item) => item,
  onValueCompare: (a, b) => a === b,
}

export default SSXMultipleDropdownSelector
