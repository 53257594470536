import React from 'react'
import './CustomAutomation.css'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const AutomationBreadCrumbs = ({ route }) => {
  const navigate = useNavigate()
  const { t } = useTranslation('translations')
  return (
    <div>
      <span className="automations-link" onClick={() => navigate('/automations')}>
        {t('automations.automations')}
      </span>{' '}
      / <span className="active-step">{route}</span>
    </div>
  )
}

export default AutomationBreadCrumbs
