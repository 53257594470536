import React, { useEffect, useState, useRef } from 'react'
import './Gallery.css'
import SectionHeader from '../../components/DefaultComponents/SectionHeader'
import { ReactComponent as GalleryIcon } from '../../assets/GalleryIcon.svg'
import DeviceSelector from '../../components/DeviceSelector/DeviceSelector'
import GalleryImage from '../../components/Gallery/GalleryImage'
import { getEntriesFormatedDate } from '../../util/formatDate'
import { useTranslation } from 'react-i18next'
import GalleryCarousel from '../../components/Gallery/GalleryCarousel'
import DataService from '../../data/DataService'
import { useAuth } from '../../hooks/useAuth'
import { CircularProgress } from '@mui/material'
import { dropdownOptions } from '../../util/constants'
import { formatPictureData, yearMonthComparator } from '../../util/util'

const Gallery = () => {
  const limit = 50
  const [index, setIndex] = useState(0)
  const [showCarousel, setShowCarousel] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [galleryImages, setGalleryImages] = useState([])
  const { t } = useTranslation('translations')
  const { selectedBoxDevice } = useAuth()
  const [offset, setOffset] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [periodInMonths, setPeriodInMonths] = useState(1)
  const [updated, setUpdated] = useState({})
  const loader = useRef(null)

  const isLoadingRef = useRef()
  isLoadingRef.current = isLoading
  const offsetRef = useRef()
  offsetRef.current = offset
  const updatedRef = useRef()
  updatedRef.current = updated
  const galleryImagesRef = useRef()
  galleryImagesRef.current = galleryImages
  const periodInMonthsRef = useRef()
  periodInMonthsRef.current = periodInMonths

  useEffect(() => {
    if (selectedBoxDevice) {
      setHasMore(true)
      setOffset(1)
      fetchImages(periodInMonths)
    }
  }, [selectedBoxDevice, periodInMonths])

  // infinite scrolling logic using intersection observer
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    }
    const observer = new IntersectionObserver(handleObserver, options)
    if (loader.current) observer.observe(loader.current)

    return () => {
      if (loader.current) observer.unobserve(loader.current)
    }
  }, [loader.current])

  const fetchImages = async (periodInMonths) => {
    setIsLoading(true)
    setGalleryImages([])
    const pictures = await DataService.getGalleryPictures({
      deviceId: selectedBoxDevice,
      months: periodInMonths,
    })
    const updated = pictures.reduce(formatPictureData(galleryImagesRef, false), {})
    setUpdated(updated)
    setGalleryImages(pictures)
    setIsLoading(false)
  }

  const appendImages = async (periodInMonths) => {
    setIsLoading(true)
    const pictures = await DataService.getGalleryPictures({
      deviceId: selectedBoxDevice,
      months: periodInMonths,
      offset: offsetRef.current,
    })
    const updated = pictures.reduce(formatPictureData(galleryImagesRef, false), updatedRef.current)
    setUpdated(updated)
    setGalleryImages((prevPictures) => [...prevPictures, ...pictures])
    if (pictures.length < limit) {
      setHasMore(false)
    }
    setOffset((prevOffset) => prevOffset + 1)
    setIsLoading(false)
  }

  const handleObserver = (entities) => {
    const target = entities[0]
    if (target.isIntersecting && !isLoadingRef.current) {
      appendImages(periodInMonthsRef.current)
    }
  }

  return (
    <div
      onClick={() => {
        if (showCarousel) setShowCarousel(false)
      }}
      className="gallery-body"
    >
      {showCarousel && (
        <GalleryCarousel
          onClose={setShowCarousel}
          imagesData={galleryImages}
          intialImageIndex={index}
          showThumbnails={true}
          showActionArrows={true}
        />
      )}
      <div className="gallery-device-selector">
        <DeviceSelector onSelect={() => {}} />
      </div>
      <div className="gallery-filter-container">
        <span>{t('gallery.show_last')}</span>
        <select
          value={periodInMonths}
          onChange={(ev) => setPeriodInMonths(ev.target.value)}
          className="gallery-filter-dropdown"
        >
          {dropdownOptions().map((m, i) => (
            <option key={i} value={m.value}>
              {m.display}
            </option>
          ))}
        </select>
        <span>{t('gallery.months')}</span>
      </div>
      <SectionHeader title={'Gallery'} icon={<GalleryIcon className="gallery-icon" />} />
      {Object.keys(updated)
        .sort(yearMonthComparator)
        .map((month, ind) => {
          return (
            <div key={ind}>
              <div className="gallery-month">
                {getEntriesFormatedDate(updated[month][0].date_captured)}
              </div>
              <div className="gallery-images">
                {updated[month].map((image, index) => (
                  <div
                    key={index}
                    className="gallery-images-image"
                    onClick={() => {
                      setShowCarousel(true)
                      setIndex(image.index)
                    }}
                  >
                    <GalleryImage image={image.url} imageAlt={''} imageDate={image.date_captured} />
                  </div>
                ))}
              </div>
            </div>
          )
        })}
      {hasMore && <div ref={loader}></div>}
      {isLoading && <CircularProgress sx={{ mx: 'auto' }} color="success" />}
      <div className="gallery-btns">
        {/* <DefaultButton leftIcon customLeftIcon={<GalleryIcon />}>
          {t('gallery.multiple_selection')}
        </DefaultButton> */}
        {/* <DefaultButton customStyle={'gallery-btn-theme'} leftIcon customLeftIcon={<DownloadIcon />}>
          {t('gallery.download_selected')}
        </DefaultButton> */}
        {/* <DefaultButton customStyle={'gallery-btn-theme'} leftIcon customLeftIcon={<RemoveIcon />}>
          {t('gallery.delete_selected')}
        </DefaultButton> */}
      </div>
    </div>
  )
}

export default Gallery
