import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { DefaultButton } from '../../DefaultComponents'
import { useState } from 'react'
import { ReactComponent as SaveIcon } from '../../../assets/SaveIcon.svg'
import { ReactComponent as RemoveIcon } from '../../../assets/RemoveIcon.svg'
import { useNotifications } from '../../../hooks/useNotification'
import DataService from '../../../data/DataService'
import MainStageForm, { ValidateStageForm } from './MainStageForm'
import useWarning from '../../Modal/WarningModal/WarningModal'

const StageTemplateEditForm = ({ initialData, setStageLoading, onSaved }) => {
  const { t } = useTranslation('translations')
  const [errorMessages, setErrorMessages] = useState()
  const { addErrorNotification, addSuccessNotification } = useNotifications()
  const [getConfirmation, Warning] = useWarning(
    `Are you sure you want to delete template ${initialData.name}?`,
    false,
  )

  const [formData, setFormData] = useState()

  const updateStage = async () => {
    setStageLoading?.(true)

    try {
      const payload = {
        name: formData.name,
        start: formData.start,
        end: formData.end,
        temperature_min: formData.temperature_min,
        temperature_max: formData.temperature_max,
        moisture_value: formData.moisture_value,
        user_id: formData.user_id,
        light_intervals: formData.light_intervals,
        stage_template_id: formData.stage_template_id,
      }

      const resp = await DataService.updateStageTemplate(payload, initialData.stage_template_id)

      if (resp !== 200) {
        throw resp
      } else {
        addSuccessNotification({
          header: 'Stage template saved',
        })
        onSaved?.()
      }
    } catch (error) {
      console.error('err', error)
      addErrorNotification({
        header: 'Failed to save stage template',
      })
    } finally {
      setStageLoading?.(false)
    }
  }

  const deleteTemplate = async () => {
    setStageLoading?.(true)
    await DataService.deleteStageTemplate(initialData.stage_template_id)
    setStageLoading?.(false)
    onSaved?.()
  }

  const validateStage = () => {
    if (!formData) {
      return
    }
    const errors = ValidateStageForm(formData)
    setErrorMessages(errors)

    return Object.keys(errors).length < 1
  }

  const handleDelete = async () => {
    const confirmed = await getConfirmation()
    if (confirmed) {
      deleteTemplate()
    }
  }

  return (
    <div className="stage-setup-form">
      <MainStageForm onDataChange={setFormData} initialData={initialData} errors={errorMessages} />
      <div className="inline-btns">
        <DefaultButton
          customStyle={'save-btn'}
          onClick={() => {
            if (validateStage()) {
              updateStage()
            }
          }}
          leftIcon
          customLeftIcon={<SaveIcon />}
        >
          {t('automations.stage_setup.save_template')}
        </DefaultButton>
        <DefaultButton
          variant="red"
          onClick={handleDelete}
          leftIcon
          customLeftIcon={<RemoveIcon />}
        >
          {t('automations.stage_setup.delete_template')}
        </DefaultButton>
        <Warning />
      </div>
    </div>
  )
}

StageTemplateEditForm.propTypes = {
  stageTemplateId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  initialData: PropTypes.shape({
    name: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string,
    temperature_min: PropTypes.string,
    temperature_max: PropTypes.string,
    stage_template_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    light_intervals: PropTypes.arrayOf(
      PropTypes.shape({
        start_time: PropTypes.string,
        end_time: PropTypes.string,
        light_interval_id: PropTypes.number,
        stage_id: PropTypes.number,
      }),
    ),
  }),
  setStageLoading: PropTypes.func,
  onSaved: PropTypes.func,
  onLoadTemplatePressed: PropTypes.func,
}

StageTemplateEditForm.defaultProps = {
  initialData: {},
  setStageLoading: () => {},
  onSaved: () => {},
  onStageTemplateLoad: () => {},
  onStageTemplateEdit: () => {},
  onLoadTemplatePressed: () => {},
}

export default StageTemplateEditForm
