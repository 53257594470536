import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import DataService from '../../../data/DataService'
import { useGoogleLogin } from '@react-oauth/google'
import { useTranslation } from 'react-i18next'
import { DefaultButton, DefaultInput } from '../../DefaultComponents'
import WalkthroughContainerV2 from '../WalkthroughContainerV2/WalkthroughContainerV2'
import { ReactComponent as IconShowPassword } from '../../../assets/IconShowPassword.svg'
import { ReactComponent as IconHidePassword } from '../../../assets/IconHidePassword.svg'
import { ReactComponent as FacebookIcon } from '../../../assets/FacebookIcon.svg'
import { ReactComponent as AppleIcon } from '../../../assets/AppleIcon.svg'
import { ReactComponent as GoogleIcon } from '../../../assets/GoogleIcon.svg'
import { ReactComponent as EmailIcon } from '../../../assets/EmailIcon.svg'
import { ReactComponent as KeyIcon } from '../../../assets/KeyIcon.svg'
import './Login.css'
import { useAuth } from '../../../hooks/useAuth'

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState('password')
  const [error, setError] = useState(false)
  const { t } = useTranslation('translations')
  const navigate = useNavigate()
  const { getUserAccessToken, setConnectedDevices, changeSelectedBoxDevice } = useAuth()
  const buttonRef = useRef(null)

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [])

  const handleKeyDown = (ev) => {
    if (ev.key === 'Enter') {
      if (buttonRef.current) buttonRef.current.click()
    }
  }

  const submit = async () => {
    if (email && password) {
      const signIn = await DataService.loginUser(email, password)
      if (signIn === 'OK') {
        setConnectedDevices([])
        changeSelectedBoxDevice(0)
        await getUserAccessToken()
        navigate('/connect')
      } else {
        if (signIn?.response?.status === 400) {
          setError(signIn?.response?.data?.message)
        }
      }
    } else {
      setError(t('login.login_error'))
    }
  }

  const googleLogin = useGoogleLogin({
    flow: 'auth-flow',
    onSuccess: async (codeResponse) => {
      const signIn = await DataService.signInSSO(codeResponse.code)
      if (signIn === 'OK') {
        setConnectedDevices([])
        changeSelectedBoxDevice(0)
        await getUserAccessToken()
        navigate('/connect')
      }
    },
    onError: (error) => console.log('Login Failed:', error),
  })

  return (
    <WalkthroughContainerV2>
      <div className="login-container">
        <div className="login-title">{t('login.title')}</div>
        <div className="login-content-splitter">
          <div className="login-form-container">
            <DefaultInput
              onChange={(e) => {
                setEmail(e.target.value)
                setError(false)
              }}
              label={
                <div className="login-label-container">
                  <EmailIcon className="login-email-icon" />
                  {t('login.email')}
                </div>
              }
              error={error}
              message={error}
            />
            <DefaultInput
              onChange={(e) => {
                setPassword(e.target.value)
                setError(false)
              }}
              label={
                <div className="login-label-container">
                  <KeyIcon className="login-key-icon" />
                  {t('login.password')}
                </div>
              }
              type={showPassword}
              icon={
                showPassword === 'text' ? (
                  <IconShowPassword
                    onClick={() => setShowPassword('password')}
                    className="show-password-icon"
                  />
                ) : (
                  <IconHidePassword
                    onClick={() => setShowPassword('text')}
                    className="show-password-icon"
                  />
                )
              }
              error={error}
              message={error}
            />
          </div>
          <div className="login-alternative-login">
            <div>{t('login.login_with')}</div>
            <div className="login-alternative-container">
              <button className="login-alternative">
                <FacebookIcon className="login-alternative-icon" />
              </button>
              <button className="login-alternative">
                <AppleIcon className="login-alternative-icon" />
              </button>
              <button
                onClick={() => {
                  googleLogin()
                }}
                className="login-alternative"
              >
                <GoogleIcon className="login-alternative-icon" />
              </button>
            </div>
            <div className="login-create-account">
              <div>{t('login.have_no_account') + '\u00a0'}</div>
              <div onClick={() => navigate('/register')} className="login-link">
                {t('login.register')}
              </div>
            </div>
          </div>
        </div>
        <div className="login-button-container">
          <DefaultButton ref={buttonRef} onClick={submit}>
            {t('login.login')}
          </DefaultButton>
        </div>
      </div>
    </WalkthroughContainerV2>
  )
}

export default Login
