import IndicatorCard from '../components/IndicatorCard/IndicatorCard'
import { sensor_type_colors, module_statuses } from './constants'
import {
  INTAKE_FAN_ID,
  EXHAUST_FAN_ID,
  LITTLE_FAN_ID,
  AIR_TEMPERATURE_CONTROLLER_ID,
  SOIL_PH_SENSOR_ID,
} from './constants'

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
}

export const DisplayErrorMessage = (
  type,
  validation,
  email,
  password,
  confirmPassword,
  customMessage,
  t,
) => {
  if (customMessage) {
    return customMessage
  }
  if (type === 'email') {
    if (validation) {
      if (!email) {
        return t('validation.required')
      }
      if (!validateEmail(email)) {
        return t('validation.email_format')
      }
    }
  }
  if (type === 'password') {
    if (validation) {
      if (!password) {
        return t('validation.required')
      }
      // length
      if (password.length < 8) {
        return t('validation.password_length')
      }
      // number
      if (!/\d+/g.test(password)) {
        return t('validation.password_requirement')
      }
      // lowercase
      if (!/[a-z]+/g.test(password)) {
        return t('validation.password_requirement')
      }
      // uppercase
      if (!/[A-Z]+/g.test(password)) {
        return t('validation.password_requirement')
      }
      if (password !== confirmPassword) {
        return t('validation.password_mismatch')
      }
    }
  }
  if (type === 'confirm_password') {
    if (validation) {
      if (password !== confirmPassword) {
        return t('validation.password_mismatch')
      }
      if (!confirmPassword) {
        return t('validation.required')
      }
    }
  }
  return ''
}

export const hexToRgb = (hex, opacity) => {
  if (opacity < 0 || opacity > 1) return ''
  hex = hex.replace('#', '')

  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  return `rgb(${r}, ${g}, ${b}, ${opacity})`
}

export const readableDate = (date) => {
  return new Date(date).toLocaleDateString().slice(0, 5)
}

export const prepareDataForChart = (data) => {
  const map = new Map()

  data.forEach((entry) => {
    if (!map.get(entry.name)) {
      map.set(entry.name, {
        dates: [readableDate(entry.date_read)],
        values: [entry.data],
        color: sensor_type_colors[entry.name],
      })
    } else {
      const tmp = map.get(entry.name)
      tmp.dates.push(readableDate(entry.date_read))
      tmp.values.push(entry.data)
      map.set(entry.name, tmp)
    }
  })

  //filter out sensors that have less than two entries
  map.forEach((customData, key) => {
    if (customData.values.length < 2) map.delete(key)
  })

  return map
}

const formatChartDate = (date_string) => {
  return date_string.split(':')[0] + ':' + date_string.split(':')[1]
}

export const formatDataForChart = (data) => {
  if (data && data.length) {
    let dates = []
    let values = []
    for (const record of data) {
      dates.push(formatChartDate(record.dates))
      values.push(record.values)
    }
    return { dates, values }
  }
  return {}
}

export const classNameTypeExtractor = (device_type) => {
  return device_type.toLowerCase().replace('groovy', '')
}

export const capitalizeFirstChar = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const renderSensorIndicators = (
  indicators,
  deviceStatus,
  renderIcons,
  t,
  connectionStatus,
) => {
  return (
    <div
      className={`indicator-container
        ${!deviceStatus && 'device-offline'}
        ${!connectionStatus && 'no-connection'}`}
    >
      {indicators
        ?.map((e, i) => {
          if (!e.is_controller) {
            return (
              <IndicatorCard
                key={i}
                title={
                  t(`dashboard.indicators.${e.name.replace(/ /g, '_').toLowerCase()}`) +
                  `${e.module_input ? ` #${e.module_input}` : ''}`
                }
                classModifier={'icon-wrapper'}
                subtitle={
                  e.data === '100'
                    ? 'Disconnected'
                    : e.status === 'Connected'
                      ? e.data
                      : e.status
                        ? e.status.replace(/_/g, ' ')
                        : '-'
                }
                module_status={e.status === 'Connected' ? false : true}
                progress={
                  !e.module_status
                    ? 0
                    : (e.module_id === SOIL_PH_SENSOR_ID ? e.data / 0.14 : e.data) || 0
                }
                progressClassModifier={e.name.replace(/ /g, '-').toLowerCase()}
                icon={renderIcons(e.name.replace(/ /g, '_').toLowerCase())}
              />
            )
          } else {
            return ''
          }
        })
        .filter((e) => e)}
    </div>
  )
}

const returnControllerValue = (device_status, value, name) => {
  if (name === 'Control Relay') {
    if (value === 'rTN') {
      return true
    }
    return false
  }
  if (name === 'Air Temperature Controller') {
    if (value) {
      if (value === '-r') {
        return false
      } else {
        return true
      }
    } else {
      return false
    }
  }
  return module_statuses[value]
}

const returnAutomationControllerValue = (module_id, value) => {
  if (module_id === AIR_TEMPERATURE_CONTROLLER_ID) {
    if (value) {
      if (value === '-r') {
        return ''
      } else {
        return value.replace('r', '')
      }
    }
  }
  return ''
}

export const renderControllerIndicators = (
  indicators,
  deviceStatus,
  renderIcons,
  t,
  setDeviceModulStatus,
  deviceType,
  isAutomationActive, //this should be named areConstraintsActive or something in that direction; has nothing to do with automations
  deviceData,
  connectionStatus,
) => {
  return (
    <div
      className={`indicator-container
        ${!deviceStatus && 'device-offline'}
        ${!connectionStatus && 'no-connection'}`}
    >
      {indicators
        ?.map((e, i) => {
          if (e.is_controller) {
            return (
              <IndicatorCard
                key={i}
                type={'controller'}
                title={t(`dashboard.indicators.${e.name.replace(/ /g, '_').toLowerCase()}`)}
                subtitle={e?.data || '25'}
                controllerState={returnControllerValue(e.device_status_id, e.value, e.name)}
                deviceType={deviceType}
                automationControllerValue={returnAutomationControllerValue(e.module_id, e.value)}
                automationStatus={isAutomationActive}
                controllerType={
                  e.module_id === INTAKE_FAN_ID ||
                  e.module_id === EXHAUST_FAN_ID ||
                  e.module_id === LITTLE_FAN_ID
                    ? 'fan'
                    : e.module_id === AIR_TEMPERATURE_CONTROLLER_ID
                      ? 'temperature'
                      : 'relay'
                }
                module_id={e.module_id}
                controller={() => {
                  setDeviceModulStatus()
                }}
                deviceAddress={e.mac_address}
                icon={renderIcons(
                  e.name.replace(/ /g, '_').toLowerCase(),
                  returnControllerValue(e.device_status_id, e.value, e.name),
                  e?.data,
                )}
                isAutomationActiveOnDevice={deviceData?.automation_active}
                automationId={deviceData?.automation_id}
                deviceData={deviceData}
                indicators={indicators}
              />
            )
          } else {
            return ''
          }
        })
        .filter((e) => e)}
    </div>
  )
}

export const getDataForTrialPlan = (data) => {
  const groupedData = {}
  const currentMonthStart = new Date()
  const thirtyDaysAgo = new Date()
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 29)

  const currentMonth = data.filter(
    (f) => new Date(f.created_at).getMonth() === currentMonthStart.getMonth(),
  )
  const prevMonth = data.filter(
    (f) => new Date(f.created_at).getMonth() === thirtyDaysAgo.getMonth(),
  )

  if (currentMonth) {
    currentMonth.forEach((d) => {
      if (d) {
        if (!groupedData[0]) {
          groupedData[0] = []
        }
        groupedData[0].push(d)
      }
    })
  } else {
    groupedData[0].push({})
  }

  if (prevMonth) {
    prevMonth.forEach((d) => {
      if (d) {
        if (!groupedData[1]) {
          groupedData[1] = []
        }
        groupedData[1].push(d)
      }
    })
  } else {
    groupedData[1].push({})
  }
  return groupedData
}

export const checkIfTypeHasDevice = (devices, type) => {
  const findDevice = devices.find((o) => o.device_type_name === type) || ''
  if (findDevice) {
    return true
  } else {
    return false
  }
}

export const checkIfUserHasDevice = (devices, device_id) => {
  const findDevice = devices.find((o) => o.device_id === parseInt(device_id)) || ''
  if (findDevice) {
    return true
  } else {
    return false
  }
}

export const autoResizeModalTextbox = (e) => {
  e.target.style.height = 'auto'
  e.target.style.height = e.target.scrollHeight + 'px'
}

export const getDeviceOptionsByType = (devices, type) => {
  let options = []
  devices.forEach((value) => {
    if (value.device_type_name === type) {
      options.push({ value: value.device_id, name: value.name })
    }
  })
  return options
}

export const getSelectedDeviceByType = (
  selectedBoxDevice,
  selectedSystemDevice,
  selectedControlDevice,
  type,
) => {
  switch (type) {
    case 'groovyBox':
      return selectedBoxDevice
    case 'groovySystem':
      return selectedSystemDevice
    case 'groovyControl':
      return selectedControlDevice
    default:
      return ''
  }
}

export const getDevicesByType = (devices, type) => {
  let devicesByType = []
  devices.forEach((value) => {
    if (value.device_type_name === type) {
      devicesByType.push(value)
    }
  })
  return devicesByType
}

export const getDeviceMacAddressById = (devices, id) => {
  for (const module of devices) {
    if (module.device_id === parseInt(id)) {
      return module.mac_address
    }
  }
  return ''
}

export const deviceHasCustomModules = (devices, id) => {
  for (const device of devices) {
    if (device?.device_id === parseInt(id) && device?.is_custom) {
      return true
    }
  }
  return false
}

const addLeadingZero = (value) => {
  if (value.toString().length === 1) {
    return '0'
  }
  return ''
}

export const formatISODate = (dayJsValue) => {
  return (
    dayJsValue.$y +
    '-' +
    addLeadingZero(dayJsValue.$M + 1) +
    (dayJsValue.$M + 1) +
    '-' +
    addLeadingZero(dayJsValue.$D) +
    dayJsValue.$D
  )
}

const months = {
  0: 'Jan',
  1: 'Feb',
  2: 'Mar',
  3: 'Apr',
  4: 'May',
  5: 'Jun',
  6: 'Jul',
  7: 'Aug',
  8: 'Sep',
  9: 'Oct',
  10: 'Nov',
  11: 'Dec',
}

export const formatDateToShortString = (date, options) => {
  if (!date) {
    return undefined
  }

  const firstPart = `${date.getDate()} ${months[date.getMonth()]}`
  if (options?.year) {
    return `${firstPart} ${date.getFullYear()}`
  }

  return firstPart
}

export const formatShortDateRange = (startDate, endDate) => {
  const includeBothYears =
    !startDate || !endDate || startDate?.getFullYear() !== endDate?.getFullYear()

  const startString = formatDateToShortString(startDate, { year: includeBothYears }) ?? ''
  const endString = formatDateToShortString(endDate, { year: true }) ?? ''
  return `${startString} - ${endString}`.trim()
}

export const formmatedListDate = (start, end) => {
  if (start && end) {
    const UTCStart = new Date(start)
    const UTCEnd = new Date(end)
    const startYear = UTCStart.getFullYear()
    const startMonth = UTCStart.getMonth()
    const startDay = UTCStart.getDate()
    const endYear = UTCEnd.getFullYear()
    const endMonth = UTCEnd.getMonth()
    const endDay = UTCEnd.getDate()
    return (
      addLeadingZero(startDay.toString()) +
      startDay +
      ' ' +
      months[parseInt(startMonth)] +
      ' ' +
      startYear +
      ' - ' +
      addLeadingZero(endDay.toString()) +
      endDay +
      ' ' +
      months[parseInt(endMonth)] +
      ' ' +
      endYear
    )
  }
  return '-'
}

export const returnFanSpeed = (current, direction) => {
  if (current === '25') {
    if (direction === '+') {
      return '50'
    }
  }
  if (current === '50') {
    if (direction === '+') {
      return '75'
    }
    if (direction === '-') {
      return '25'
    }
  }
  if (current === '75') {
    if (direction === '+') {
      return '100'
    }
    if (direction === '-') {
      return '50'
    }
  }
  if (current === '100') {
    if (direction === '-') {
      return '75'
    }
  }
  if (current === '0') {
    if (direction === '+') {
      return '25'
    }
  }

  return ''
}

export const calcElapsedTime = (date) => {
  const dateObj = new Date(date)
  const currentTime = new Date()

  const diffMS = currentTime - dateObj
  const diffMin = Math.floor(diffMS / (1000 * 60))
  const diffHrs = Math.floor(diffMin / 60)
  const diffDays = Math.floor(diffHrs / 24)

  let elapsedTime = `${diffDays} days ago`

  if (!diffDays) {
    elapsedTime = `${diffHrs} h ago`
  }

  if (!diffHrs) {
    elapsedTime = `${diffMin} m ago`
  }

  return elapsedTime
}

export const formatPictureData = (galleryImagesRef, initialFetch) => {
  return (newObj, currentPicture, index) => {
    const tmpDate = new Date(currentPicture.date_captured)
    currentPicture['index'] = initialFetch ? index : galleryImagesRef.current.length + index
    const month = tmpDate.getMonth()
    const year = tmpDate.getFullYear()
    const date = year + '-' + month
    if (!(date in newObj)) {
      newObj[date] = [currentPicture]
    } else {
      newObj[date].push(currentPicture)
    }
    return newObj
  }
}

//comparator for array that has elements of type year-month
export const yearMonthComparator = (a, b) => {
  const yearA = a.split('-')[0]
  const monthA = a.split('-')[1]
  const yearB = b.split('-')[0]
  const monthB = b.split('-')[1]

  if (yearA < yearB || (yearA === yearB && monthA < monthB)) return -1
  else if (yearA > yearB || (yearA === yearB && monthA > monthB)) return 1
  else return 0
}
