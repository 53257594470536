import './SSXModal.css'
import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as CloseIcon } from '../../../assets/CloseIcon.svg'
import ElementLoader from '../../UI/ElementLoader/ElementLoader'

const SSXModal = ({ show, onClose, header, children, footer, hideCloseButton, modalLoading }) => {
  const overlayRef = useRef()

  useEffect(() => {
    const listener = (e) => {
      if (e.code === 'Escape') {
        if (!modalLoading) {
          onClose()
        }
      }
    }
    document.addEventListener('keydown', listener)

    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [modalLoading])

  if (!show) return <></>

  return (
    <div
      className="ssx-modal-overlay center"
      ref={overlayRef}
      onClick={(e) => {
        if (e.target === overlayRef.current && !modalLoading) {
          onClose()
        }
      }}
    >
      <ElementLoader
        extraStyle={{
          maxHeight: '100%',
        }}
        loading={modalLoading}
      >
        <div className="ssx-modal-container">
          {(header || !hideCloseButton) && (
            <div className="ssx-modal-header">
              <div>{typeof header === 'string' ? <strong>{header}</strong> : header()}</div>
              {!hideCloseButton && (
                <div className="ssx-modal-header-close-icon" onClick={onClose}>
                  <CloseIcon></CloseIcon>
                </div>
              )}
            </div>
          )}
          <div className="ssx-modal-body">{children}</div>
          <div className="ssx-modal-footer">
            <div>{typeof footer === 'string' ? <strong>{footer}</strong> : footer()}</div>
          </div>
        </div>
      </ElementLoader>
    </div>
  )
}

SSXModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  header: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  footer: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  hideCloseButton: PropTypes.bool,
  modalLoading: PropTypes.bool,
}

SSXModal.defaultProps = {
  show: true,
  onClose: () => {},
  header: '',
  footer: '',
  hideCloseButton: false,
  modalLoading: false,
}

export default SSXModal
