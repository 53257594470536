import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as PowerIcon } from '../../../assets/PowerIcon.svg'
import { ReactComponent as IndicatorsIcon } from '../../../assets/IndicatorsIcon.svg'
import { ReactComponent as EnergyIcon } from '../../../assets/EnergyIcon.svg'
import DataService from '../../../data/DataService'
import './DashboardIndicators.css'
import { renderControllerIndicators, renderSensorIndicators } from '../../../util/util'
import { AIR_TEMPERATURE_CONTROLLER_ID } from '../../../util/constants'
import { useDevice } from '../../../hooks/useDevice'
import { CircularProgress } from '@mui/material'

const ControlIndicators = ({ device_id }) => {
  const { t } = useTranslation('translations')
  const [deviceModuleStatus, setDeviceModuleStatus] = useState([])
  const [newDeviceStatus, setNewDeviceStatus] = useState(1)
  const [isAutomationActive, setIsAutomationActive] = useState(false)
  const [connectionStatus, setConnectionStatus] = useState(true)
  const hasIndicators =
    Array.isArray(deviceModuleStatus) && deviceModuleStatus?.some((module) => !module.is_controller)
  const hasControllers =
    Array.isArray(deviceModuleStatus) && deviceModuleStatus?.some((module) => module.is_controller)

  const { deviceStatus, deviceData, isLoadingStatus: isDeviceLoading } = useDevice()

  useEffect(() => {
    function changeStatus() {
      setConnectionStatus(navigator.onLine)
    }
    window.addEventListener('online', changeStatus)
    window.addEventListener('offline', changeStatus)
    return () => {
      window.removeEventListener('online', changeStatus)
      window.removeEventListener('offline', changeStatus)
    }
  }, [])

  useEffect(() => {
    if (device_id) {
      getModuleStatus(device_id)
      const interval = setInterval(async () => getModuleStatus(device_id), 5000)
      return () => clearInterval(interval)
    }
  }, [newDeviceStatus, device_id])

  const getModuleStatus = async (device_id) => {
    const deviceModules = await DataService.getModuleDataForDevice(device_id)
    setDeviceModuleStatus(deviceModules.data)

    if (deviceModules?.data?.length) {
      setIsAutomationActive(getAutomationStatus(deviceModules.data))
    }
  }

  const getAutomationStatus = (data) => {
    let temperatureControllerModule = null
    for (const module of data) {
      if (module.module_id === AIR_TEMPERATURE_CONTROLLER_ID) {
        temperatureControllerModule = module
      }
    }
    if (temperatureControllerModule) {
      if (temperatureControllerModule.value && temperatureControllerModule.value !== '-r') {
        return true
      }
    }
    return false
  }

  const renderIndicatorIcon = (indicator) => {
    switch (indicator) {
      case 'energy_usage_sensor':
        return (
          <div className="indicator-energy-icon">
            <EnergyIcon />
          </div>
        )
      default:
        return ''
    }
  }

  const renderControllerIcon = (indicator, value) => {
    if (indicator === 'control_relay') {
      if (value) {
        return (
          <div className="indicator-power-icon on">
            <PowerIcon />
          </div>
        )
      } else {
        return (
          <div className="indicator-power-icon off">
            <PowerIcon />
          </div>
        )
      }
    }
  }

  return (
    <div>
      {hasIndicators && (
        <>
          <div className="indicator-header">
            <div className="indicator-title">
              <IndicatorsIcon />
              {t('dashboard.indicators.title')}
            </div>
            <hr className="indicator-divider-line" />
          </div>
          <div className="indicator-container-splitter">
            {isDeviceLoading ? (
              <CircularProgress sx={{ mx: 'auto' }} color="success" />
            ) : (
              renderSensorIndicators(
                deviceModuleStatus,
                deviceStatus,
                (indicator) => renderIndicatorIcon(indicator),
                t,
                connectionStatus,
              )
            )}
          </div>
        </>
      )}
      {hasControllers && (
        <>
          <div className="indicator-header">
            <div className="indicator-title">
              <IndicatorsIcon />
              {t('dashboard.indicators.controllers')}
            </div>
            <hr className="indicator-divider-line" />
          </div>
          <div className="indicator-container-splitter">
            {isDeviceLoading ? (
              <CircularProgress sx={{ mx: 'auto' }} color="success" />
            ) : (
              renderControllerIndicators(
                deviceModuleStatus,
                deviceStatus,
                (indicator, value) => renderControllerIcon(indicator, value),
                t,
                setNewDeviceStatus,
                'GroovyControl',
                isAutomationActive,
                deviceData,
                connectionStatus,
              )
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default ControlIndicators
