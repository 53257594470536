import React from 'react'
import './WalkthroughContainerV2.css'

const WalkthroughContainerV2 = ({ children }) => {
  return (
    <div className="walkthrough-container2">
      <div className="walkthrough-container-content2">{children}</div>
    </div>
  )
}

export default WalkthroughContainerV2
