import React, { useState } from 'react'
import './Table.css'
import { ReactComponent as EditIcon } from '../../assets/EditIcon.svg'
import { getFormatedDate, getFormatedDateNoYear } from '../../util/formatDate'
import { formmatedListDate } from '../../util/util'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ModalHelper from '../Modal/ModalHelper'

const TableRow = ({ data, dataUpdated, type }) => {
  const [showModal, setShowModal] = useState(false)
  const { t } = useTranslation('translations')
  const navigate = useNavigate()

  const closeModal = (show) => {
    setShowModal(show)
  }

  const versionDummyData = {
    title: 'Senzorix Platform v1.0.0',
    content: 'The platform is live.',
  }

  return (
    <div>
      {showModal && type === 'diary' && (
        <div>
          <ModalHelper
            data={data}
            dataUpdated={(e) => dataUpdated(e)}
            type={'diary'}
            onClose={() => setShowModal(false)}
          />
        </div>
      )}
      {showModal && type === 'stages' && type === 'stages' && (
        <div>
          <ModalHelper
            data={data}
            type={'stage'}
            modalState={showModal}
            onClose={() => {
              setShowModal(false)
              dataUpdated(true)
            }}
          />
        </div>
      )}
      {showModal && type === 'updateLog' && (
        <tr>
          <td>
            <ModalHelper
              data={versionDummyData}
              type={'version'}
              onClose={() => setShowModal(false)}
            />
          </td>
        </tr>
      )}
      {showModal && type === 'settings' && (
        <tr>
          <td>
            <ModalHelper
              data={data}
              type={'settings'}
              dataUpdated={dataUpdated}
              onClose={() => setShowModal(false)}
            />
          </td>
        </tr>
      )}
      {type === 'diary' && (
        <div onClick={() => setShowModal(true)} className="entry">
          <div className="entry-date">{getFormatedDate(data.created_at)}</div>
          <div className="entry-text-wide">{data.title}</div>
          <div className="entry-icon">
            <EditIcon />
          </div>
        </div>
      )}
      {type === 'automation' && (
        <div
          onClick={() => navigate(`/automations/custom-automation/${data.automation_id}`)}
          className="entry"
        >
          <div className="entry-text">{data.title}</div>
          <div className="entry-icon">
            <EditIcon />
          </div>
        </div>
      )}
      {type === 'stages' && (
        <div onClick={() => setShowModal(true)} className="entry">
          <div className="entry-stage-wrapper">
            <div className="entry-date">{formmatedListDate(data.start, data.end)}</div>
            <div className="entry-text">{data.title}</div>
          </div>
          <div className="entry-icon">
            <EditIcon />
          </div>
        </div>
      )}
      {type === 'updateLog' && (
        <tr onClick={() => setShowModal(true)} className="entry">
          <td className="entry-date">{getFormatedDate(data.created_at)}</td>
          <td className="entry-text-wide">{data.title}</td>
        </tr>
      )}
      {type === 'settings' && (
        <tr onClick={() => setShowModal(true)} className="entry">
          <td className="entry-date">{data.order || 0}</td>
          <td className="entry-text">{data.name || ''}</td>
          <td className="entry-notes">{data.notes || t('modals.settings.device_has_no_notes')}</td>
          <td className="entry-icon">
            <EditIcon />
          </td>
        </tr>
      )}
    </div>
  )
}

export default TableRow
