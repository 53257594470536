import React from 'react'
import './WalkthroughContainer.css'

const WalkthroughContainer = ({ children }) => {
  return (
    <div className="walkthrough-container">
      <div className="walkthrough-container-content">{children}</div>
    </div>
  )
}

export default WalkthroughContainer
