import React, { useState, useEffect } from 'react'
import './Automations.css'
import SectionHeader from '../../components/DefaultComponents/SectionHeader'
import { ReactComponent as AutomationsIcon } from '../../assets/AutomationsIcon.svg'
import { ReactComponent as CustomAutomationIcon } from '../../assets/CustomAutomationIcon.svg'
import { ReactComponent as SaveIcon } from '../../assets/SaveIcon.svg'
import { ReactComponent as DeleteIcon } from '../../assets/Delete.svg'
import { ReactComponent as CloseIcon } from '../../assets/CloseIcon.svg'
import { DefaultButton, DefaultInput } from '../../components/DefaultComponents'
import Table from '../../components/Table/Table'
import { useTranslation } from 'react-i18next'
import DataService from '../../data/DataService'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../hooks/useAuth'
import SSXTableRow from '../../components/UI/SSXTableRow/SSXTableRow'
import { ReactComponent as EditIcon } from '../../assets/EditIcon.svg'
import { ReactComponent as OnlineStatus } from '../../assets/OnlineStatus.svg'
import { ReactComponent as OfflineStatus } from '../../assets/OfflineStatus.svg'
import { formatShortDateRange } from '../../util/util'
import CreateAutomationModal from './CreateAutomationModal/CreateAutomationModal'
import SSXModal from '../../components/Modal/SSXModal/SSXModal'
import { CircularProgress } from '@mui/material'
import { useNotifications } from '../../hooks/useNotification'

const Automations = () => {
  const [automationName, setAutomationName] = useState('')
  const [createdAutomation, setCreatedAutomation] = useState('')
  const [automationValidation, setAutomationValidation] = useState('')
  const [automationFilter, setAutomationFilter] = useState('')
  const [automationData, setAutomationData] = useState([])
  const { user } = useAuth()
  const { t } = useTranslation('translations')
  const navigate = useNavigate()

  const [activeAutomations, setActiveAutomations] = useState([])
  const [inactiveAutomations, setInactiveAutomations] = useState([])

  const [createAutomationModalShown, setCreateAutomationModalShown] = useState(false)
  const [automationToDelete, setAutomationToDelete] = useState()
  const [deleteInProgress, setDeleteInProgress] = useState(false)
  const { addSuccessNotification, addErrorNotification } = useNotifications()

  useEffect(() => {
    getAutomations({ filter: automationFilter, user_id: user.user_id })
  }, [automationFilter, createdAutomation, user])

  useEffect(() => {
    DataService.getAllAutomations().then((response) => {
      if (!response || !Array.isArray(response)) {
        return
      }

      const active = []
      const inactive = []

      for (const automation of response) {
        if (!Array.isArray(automation.devices) || automation.devices.length < 1) {
          inactive.push(automation.automation)
          continue
        }

        for (const device of automation.devices) {
          if (device.active) {
            active.push({ ...automation.automation, device })
          } else {
            inactive.push({ ...automation.automation, device })
          }
        }
      }

      setActiveAutomations(active)
      setInactiveAutomations(inactive)
    })
  }, [])

  const getAutomations = async (payload) => {
    const automations = await DataService.getAutomations(payload)
    if (automations) {
      setAutomationData(automations)
    }
  }

  const validateAutomation = async () => {
    setAutomationValidation(true)
    if (automationName) {
      const createAutomation = await DataService.addAutomation({
        name: automationName,
        user_created: true,
        user_id: user.user_id,
      })
      if (createAutomation.automation_id) {
        setCreatedAutomation(createAutomation.automation_id)
        setAutomationValidation(false)
        navigate(`/automations/custom-automation/${createAutomation.automation_id}`)
      }
    }
  }

  const formatStageDates = (automation) => {
    const startDate = automation.stage_start ? new Date(automation.stage_start) : undefined
    const endDate = automation.stage_end ? new Date(automation.stage_end) : undefined
    return formatShortDateRange(startDate, endDate)
  }

  const deleteAutomation = async () => {
    if (!automationToDelete) {
      return
    }

    setDeleteInProgress(true)
    try {
      const respStatus = await DataService.removeAutomation(automationToDelete.automation_id)
      if (respStatus === 200) {
        addSuccessNotification({
          header: 'Automation deleted',
        })
        setAutomationToDelete(undefined)

        setActiveAutomations((automations) =>
          automations.filter((a) => a.automation_id !== automationToDelete.automation_id),
        )
        setInactiveAutomations((automations) =>
          automations.filter((a) => a.automation_id !== automationToDelete.automation_id),
        )
      } else {
        addErrorNotification({
          header: 'Failed to delete automation',
        })
        console.error('Response was', respStatus)
      }
    } catch (error) {
      addErrorNotification({
        header: 'Failed to delete automation',
      })
      console.error(error)
    } finally {
      setDeleteInProgress(false)
    }
  }

  const renderAutomations = (automations, status) => {
    return (
      <>
        {automations.map((automation) => {
          return (
            <SSXTableRow
              key={automation.automation_id + '-' + (automation.device?.device_id ?? '')}
              onClick={() => {
                navigate(`/automations/custom-automation/${automation.automation_id}`)
              }}
              useSpacer={false}
              leftColumns={[
                {
                  key: 'status',
                  item: (
                    <span className="secondary status-blob">
                      {status ? <OnlineStatus /> : <OfflineStatus />}
                    </span>
                  ),
                },
                {
                  key: 'date_range',
                  item: (
                    <span className="secondary date-range-text no-break-text">
                      {formatStageDates(automation)}
                    </span>
                  ),
                },
                {
                  key: 'plan_name',
                  item: (
                    <span className="primary no-break-text automation-name">{automation.name}</span>
                  ),
                },
                {
                  key: 'device_name',
                  item: (
                    <span className="secondary mobile-hidden device-name">
                      {automation.device?.name ?? '-'}
                    </span>
                  ),
                },
              ]}
              rightColumns={[
                {
                  key: 'delete',
                  item: (
                    <button
                      className="delete-icon"
                      onClick={(e) => {
                        e.stopPropagation()
                        setAutomationToDelete(automation)
                      }}
                    >
                      <DeleteIcon></DeleteIcon>
                    </button>
                  ),
                },
                {
                  key: 'edit',
                  item: (
                    <button className="edit-icon">
                      <EditIcon></EditIcon>
                    </button>
                  ),
                },
              ]}
            />
          )
        })}
      </>
    )
  }

  return (
    <div>
      <div>
        <SectionHeader
          title={t('automations.automations')}
          icon={<AutomationsIcon className="automation-icon" />}
          classModifier={'pt0'}
        />
        <div
          style={{
            textAlign: 'left',
            marginBottom: '1rem',
          }}
        >
          {/* Lorem Ipsum is simply dummy text of the printing and typesetting industry. */}
        </div>
        <DefaultButton
          onClick={() => navigate(`/automations/custom-automation`)}
          customStyle={'save-btn'}
          leftIcon
          customLeftIcon={<SaveIcon />}
        >
          {t('automations.create_automation')}
        </DefaultButton>
        <div className="search-container">
          <div className="indicator-container">
            {/* <DefaultInput
              value={automationName}
              label={t('automations.automation_name')}
              placeholder={t('automations.enter_automation_name')}
              onChange={(e) => setAutomationName(e.target.value)}
              error={automationValidation && !automationName ? true : false}
              message={
                automationValidation && !automationName
                  ? t('automations.automation_name_error')
                  : ''
              }
            /> */}
          </div>
          <div className="indicator-container">
            <div className="automation-name-buttons">
              {/* <DefaultButton
                onClick={() => validateAutomation()}
                customStyle={'save-btn'}
                leftIcon
                customLeftIcon={<SaveIcon />}
              >
                {t('automations.save_automation')}
              </DefaultButton>
              <DefaultButton
                onClick={() => navigate(`/automations/manage-device-automations`)}
                leftIcon
                customLeftIcon={<CustomAutomationIcon />}
              >
                {t('automations.manage_device_automation')}
              </DefaultButton> */}
            </div>
          </div>
        </div>
        <SectionHeader
          title={t('automations.search')}
          icon={<AutomationsIcon className="automation-icon" />}
        />
        <div className="search-container">
          <div className="indicator-container">
            <DefaultInput
              value={automationFilter}
              placeholder={t('automations.search_automation')}
              removeMessage
              onChange={(value) => setAutomationFilter(value.target.value)}
            />
          </div>
        </div>
        <div className="automation-table">
          {activeAutomations.length > 0 && (
            <>
              <h3 className="table-title">{t('automations.active')}</h3>
              {renderAutomations(activeAutomations, true)}
            </>
          )}
          {inactiveAutomations.length > 0 && (
            <>
              <h3 className="table-title">{t('automations.inactive')}</h3>
              {renderAutomations(inactiveAutomations, false)}
            </>
          )}
        </div>
      </div>

      <SSXModal
        show={!!automationToDelete}
        onClose={() => {
          if (!deleteInProgress) {
            setAutomationToDelete(undefined)
          }
        }}
        hideCloseButton={true}
        header={
          deleteInProgress
            ? undefined
            : () => (
                <div>
                  Are you sure you want to delete automation{' '}
                  <strong>{automationToDelete.name}?</strong>
                </div>
              )
        }
        footer={
          deleteInProgress
            ? undefined
            : () => (
                <div className="modal-button-row">
                  <DefaultButton
                    onClick={() => {
                      deleteAutomation()
                    }}
                    variant={'red'}
                    leftIcon
                    customLeftIcon={
                      <span className="modal-delete-btn">
                        <DeleteIcon />
                      </span>
                    }
                  >
                    {t('modals.remove')}
                  </DefaultButton>
                  <DefaultButton
                    onClick={() => {
                      setAutomationToDelete(undefined)
                    }}
                    variant={'black'}
                    leftIcon
                    customLeftIcon={
                      <span className="modal-close-btn">
                        <CloseIcon />
                      </span>
                    }
                  >
                    {t('modals.close')}
                  </DefaultButton>
                </div>
              )
        }
      >
        {deleteInProgress && <CircularProgress sx={{ mx: 'auto' }} color="success" />}
      </SSXModal>

      {createAutomationModalShown && (
        <CreateAutomationModal
          onClose={() => {
            setCreateAutomationModalShown(false)
          }}
        ></CreateAutomationModal>
      )}
    </div>
  )
}

export default Automations
