import React from 'react'
import './Table.css'
import TableRow from './TableRow'

const Table = ({ tableData, dataUpdated, type }) => {
  return (
    <div className="entries-container">
      {tableData.map((data, i) => {
        return <TableRow type={type} dataUpdated={dataUpdated} key={i} data={data} />
      })}
    </div>
  )
}

export default Table
