import React, { useState, useEffect, useRef } from 'react'
import AutomationBreadCrumbs from '../AutomationBreadCrumbs'
import { DefaultButton, DefaultInput } from '../../DefaultComponents'
import { ReactComponent as AutomationsIcon } from '../../../assets/AutomationsIcon.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/Delete.svg'
import { ReactComponent as CloseIcon } from '../../../assets/CloseIcon.svg'
import { ReactComponent as AddIcon } from '../../../assets/AddIcon.svg'
import { ReactComponent as EditIcon } from '../../../assets/EditIcon.svg'
import { ReactComponent as ActivatePlan } from '../../../assets/CheckMark.svg'
import { ReactComponent as DeactivatePlan } from '../../../assets/X.svg'
import { ReactComponent as SaveIcon } from '../../../assets/SaveIcon.svg'
import SectionHeader from '../../DefaultComponents/SectionHeader'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../../hooks/useAuth'
import DataService from '../../../data/DataService'
import { useNavigate, useParams } from 'react-router-dom'
import './CustomAutomationV2.css'
import SSXTableRow from '../../UI/SSXTableRow/SSXTableRow'
import SSXModal from '../../Modal/SSXModal/SSXModal'
import { CircularProgress } from '@mui/material'
import { useNotifications } from '../../../hooks/useNotification'
import { formmatedListDate } from '../../../util/util'
import StageTemplateModalV2 from './StageTemplateModalV2'
import StageCreateEditForm from './StageCreateEditForm'
import StageTemplateEditForm from './StageTemplateEditForm'
import SSXMultipleDropdownSelector from '../../UI/MultipleDropdownSelector/SSXMultipleDropdownSelector'
import { useDevice } from '../../../hooks/useDevice'
import useWarning from '../../Modal/WarningModal/WarningModal'

const CustomAutomationV2 = () => {
  const [automationName, setAutomationName] = useState('')
  const [automationValidation, setAutomationValidation] = useState('')
  const [stageData, setStageData] = useState([])
  const { connectedDevices, selectedDeviceType, user, refreshConnectedDevices } = useAuth()
  const { refreshData, refreshAutomationStatus } = useDevice()
  const { t } = useTranslation('translations')
  const { id } = useParams()

  const [selectedDevices, setSelectedDevices] = useState([])
  const [selectedStage, setSelectedStage] = useState()
  const [selectedDevicesDirty, setSelectedDevicesDirty] = useState(false)
  const [planActive, setPlanActive] = useState(false)

  const { addErrorNotification, addSuccessNotification } = useNotifications()

  const [loadingAutomation, setLoadingAutomation] = useState(false)
  const [activeModal, setActiveModal] = useState()
  const [automationId, setAutomationId] = useState()
  const [validationMessage, setValidationMessage] = useState('')
  const [getConfirmation, Warning] = useWarning(`Do you want to activate automation?`, false)

  const navigate = useNavigate()

  useEffect(() => {
    if (id) {
      setAutomationId(id)
    }
  }, [id])

  const showStages = automationName?.length > 0

  const createAutomation = async () => {
    const name = automationName
    setLoadingAutomation(true)
    try {
      const response = await DataService.addAutomation({
        name,
      })

      if (response.automation_id) {
        addSuccessNotification('HC: automation created')
        navigate(`/automations/custom-automation/${response.automation_id}`)
      } else {
        addErrorNotification('HC: Failed to create automation')
        setActiveModal(undefined)
      }
    } catch (error) {
      addErrorNotification('HC: Failed to create automation')
      setActiveModal(undefined)
    } finally {
      setLoadingAutomation(false)
    }
  }

  useEffect(() => {
    if (!automationId) {
      return
    }

    getAutomation(automationId)
  }, [automationId])

  const getAutomation = async (automation_id) => {
    const automationData = await DataService.getAutomation(automation_id)
    setAutomationName(automationData.name)
    const devices = automationData?.devices ?? []
    const filteredAndSelectedDevices = connectedDevices
      .filter(
        (d) =>
          d.device_type_name === selectedDeviceType &&
          devices.findIndex((d2) => d2.device_id === d.device_id) >= 0,
      )
      .map((item) => ({
        ...item,
        automation_active: devices.find((d) => d.device_id === item.device_id).automation_active,
      }))

    setSelectedDevices(filteredAndSelectedDevices)
    setSelectedDevicesDirty(false)
    const isPlanActive = filteredAndSelectedDevices.every((device) => device.automation_active)
    setPlanActive(isPlanActive)

    getAutomationStages(automation_id)
  }

  const getAutomationStages = async (automation_id) => {
    const stageList = (await DataService.getStagesByAutomation({ automation_id })) || []
    if (Array.isArray(stageList)) {
      setStageData(stageList)
    } else {
      console.error('Failed to receive stage data', stageList)
    }
  }

  const deleteStage = async (stageId) => {
    try {
      const resp = await DataService.deleteStage(stageId)
      if (resp === 200) {
        addSuccessNotification('Stage deleted')
      } else {
        throw resp
      }
      setActiveModal(undefined)
      setSelectedStage(undefined)
      getAutomationStages(automationId)
    } catch (error) {
      addErrorNotification('Failed to delete stage')
    }
  }

  const changeAutomationStatus = async (status) => {
    const response = await DataService.updateAutomationStatus(
      automationId,
      status,
      selectedDeviceType,
    )
    if (response > 199 && response < 400) {
      const message = `Automation ${!status ? 'de' : ''}activated`
      addSuccessNotification(message)
      await refreshConnectedDevices()
      await refreshData()
      await refreshAutomationStatus()
      getAutomation(automationId)
      setAutomationValidation(false)
    } else {
      addErrorNotification('Something went wrong while updating plan status')
    }
  }

  const validateAutomation = async () => {
    setAutomationValidation(true)
    if (!selectedDevices.length) {
      setValidationMessage('Device is required!')
      return
    }
    if (automationName) {
      const updateAutomation = await DataService.updateAutomation(
        {
          name: automationName,
          user_created: true,
          user_id: user.user_id,
          device_ids: selectedDevices.map((d) => d.device_id),
          device_type: selectedDeviceType,
        },
        parseInt(id),
      )
      if (updateAutomation > 199 && updateAutomation < 400) {
        addSuccessNotification('Automation saved')
        getAutomation(automationId)
        setAutomationValidation(false)
        if (!planActive) {
          const confirm = await getConfirmation()
          if (confirm) {
            changeAutomationStatus(true)
          }
        }
        refreshData()
      } else {
        addErrorNotification('Something went wrong while updating automation')
      }
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div>
        <div className="breadcrumbs">
          <AutomationBreadCrumbs route={'Custom Automation Setup'} />
        </div>
        <div className="search-container">
          <div className="indicator-container">
            <DefaultInput
              value={automationName}
              label={t('automations.automation_name')}
              placeholder={t('automations.enter_automation_name')}
              onChange={(e) => setAutomationName(e.target.value)}
              error={automationValidation && !automationName ? true : false}
              message={
                automationValidation && !automationName
                  ? t('automations.automation_name_error')
                  : ''
              }
            />
          </div>
        </div>

        {automationId && (
          <div className="search-container">
            <SSXMultipleDropdownSelector
              label={t('automations.select_devices')}
              placeholder={t('automations.no_device_selected')}
              options={connectedDevices
                .filter((d) => d.device_type_name === selectedDeviceType)
                .map((d) => ({ ...d, key: d.device_id }))}
              onDisplay={(device) => device.name}
              values={selectedDevices}
              onValueCompare={(a, b) => a.device_id === b.device_id}
              onSelect={(item) => {
                setSelectedDevicesDirty(true)
                setSelectedDevices((devs) => {
                  const includes = devs.findIndex((d) => d.device_id === item.device_id)
                  if (includes < 0) {
                    setValidationMessage('')
                    return [...devs, item]
                  }

                  const copy = [...devs]
                  copy.splice(includes, 1)

                  return copy
                })
              }}
            ></SSXMultipleDropdownSelector>
          </div>
        )}
        {validationMessage && <div className="validation-message">{validationMessage}</div>}
        {automationId && (
          <div className="search-container">
            <div className="automation-name-buttons">
              <DefaultButton
                onClick={() => validateAutomation()}
                customStyle={'save-btn'}
                leftIcon
                customLeftIcon={<SaveIcon />}
              >
                {t('automations.save_automation')}
              </DefaultButton>
              {selectedDevices.length > 0 && !selectedDevicesDirty && planActive && (
                <DefaultButton
                  onClick={() => changeAutomationStatus(false)}
                  leftIcon
                  customLeftIcon={<DeactivatePlan />}
                >
                  {t('automations.deactivate_plan')}
                </DefaultButton>
              )}
              {selectedDevices.length > 0 && !selectedDevicesDirty && !planActive && (
                <DefaultButton
                  onClick={() => changeAutomationStatus(true)}
                  leftIcon
                  customLeftIcon={<ActivatePlan />}
                >
                  {t('automations.activate_plan')}
                </DefaultButton>
              )}
            </div>
          </div>
        )}

        {showStages && (
          <div className="stage-table">
            <SectionHeader
              icon={<AutomationsIcon className="automation-icon" />}
              title={'List of Stages'}
            />
            {(stageData || [])
              .sort((a, b) => new Date(a.start) - new Date(b.start))
              .map((data, i) => {
                return (
                  <SSXTableRow
                    key={data.stage_id}
                    onClick={() => {
                      setSelectedStage(data)
                      setActiveModal('stage')
                    }}
                    odd={i % 2 !== 0}
                    leftColumns={[
                      {
                        key: 'date',
                        item: (
                          <div className="entry-date">
                            {formmatedListDate(data.start, data.end)}
                          </div>
                        ),
                      },
                      {
                        key: 'name',
                        item: <div className="entry-text">{data.name}</div>,
                      },
                    ]}
                    rightColumns={[
                      {
                        key: 'delete',
                        item: (
                          <button
                            className="delete-icon"
                            onClick={(e) => {
                              e.stopPropagation()
                              setSelectedStage(data)
                              setActiveModal('delete')
                            }}
                          >
                            <DeleteIcon></DeleteIcon>
                          </button>
                        ),
                      },
                      {
                        key: 'edit',
                        item: (
                          <button className="edit-icon">
                            <EditIcon />
                          </button>
                        ),
                      },
                    ]}
                  ></SSXTableRow>
                )
              })}
            <SSXTableRow
              odd={(stageData || []).length % 2 !== 0}
              onClick={() => {
                if (!automationId) {
                  createAutomation()
                }
                setSelectedStage(undefined)
                setActiveModal('stage')
              }}
              useSpacer={false}
              leftColumns={[
                {
                  key: 'status',
                  item: (
                    <span className="stage-add-button">
                      <AddIcon />
                    </span>
                  ),
                },
              ]}
              rightColumns={[]}
            />
          </div>
        )}
      </div>
      {activeModal === 'load_template' && (
        <StageTemplateModalV2
          onClose={() => {
            setActiveModal(undefined)
          }}
          onEdit={(st) => {
            setSelectedStage({
              ...st,
              light_intervals: [...st.light_interval_templates],
            })
            setActiveModal('stage_template')
          }}
          onLoad={(st) => {
            setSelectedStage({
              name: st.name,
              start: st.start,
              end: st.end,
              temperature_min: st.temperature_min,
              temperature_max: st.temperature_max,
              light_intervals: st.light_interval_templates.map((lt) => ({
                start_time: lt.start_time,
                end_time: lt.end_time,
              })),
            })
            setActiveModal('stage')
          }}
        />
      )}
      <SSXModal
        hideCloseButton={loadingAutomation}
        show={activeModal === 'stage' || activeModal === 'stage_template'}
        onClose={() => {
          if (!loadingAutomation) {
            setActiveModal(undefined)
          }
        }}
        header={
          loadingAutomation
            ? undefined
            : () => (
                <div className="create-stage-header">
                  <AutomationsIcon className="automation-icon" />
                  <strong>Stage setup</strong>
                </div>
              )
        }
      >
        {loadingAutomation && <CircularProgress sx={{ mx: 'auto' }} color="success" />}
        {!loadingAutomation && activeModal === 'stage' && (
          <StageCreateEditForm
            automationId={automationId}
            initialData={selectedStage}
            onLoadTemplatePressed={() => {
              setActiveModal('load_template')
            }}
            onSaved={() => {
              setActiveModal(undefined)
              getAutomationStages(automationId)
            }}
          ></StageCreateEditForm>
        )}
        {!loadingAutomation && activeModal === 'stage_template' && (
          <StageTemplateEditForm
            onClose={() => {
              setActiveModal(undefined)
            }}
            initialData={selectedStage}
            onSaved={() => {
              setActiveModal(undefined)
            }}
          />
        )}
      </SSXModal>

      <SSXModal
        show={activeModal === 'delete' && selectedStage}
        modalLoading={false}
        onClose={() => {
          if (!loadingAutomation) {
            setActiveModal(false)
          }
        }}
        hideCloseButton={true}
        header={() => (
          <div>
            Are you sure you want to delete stage <strong>{selectedStage?.name}?</strong>
          </div>
        )}
        footer={() => (
          <div className="modal-button-row">
            <DefaultButton
              onClick={() => {
                deleteStage(selectedStage.stage_id)
              }}
              variant={'red'}
              leftIcon
              customLeftIcon={
                <span className="modal-delete-btn">
                  <DeleteIcon />
                </span>
              }
            >
              {t('modals.remove')}
            </DefaultButton>
            <DefaultButton
              onClick={() => {
                setActiveModal(undefined)
              }}
              leftIcon
              customLeftIcon={<span className="modal-close-btn-icon">{<CloseIcon />}</span>}
            >
              {t('modals.close')}
            </DefaultButton>
          </div>
        )}
      ></SSXModal>
      <Warning />
    </LocalizationProvider>
  )
}

export default CustomAutomationV2
