import React from 'react'
import './DefaultDialog.css'
import { CSSTransition } from 'react-transition-group'
import { ReactComponent as CloseIcon } from '../../../assets/CloseIcon.svg'

const DefaultDialog = ({
  children,
  classModifier,
  header,
  open,
  onClose,
  enter,
  exit,
  disableDefaultAnimation,
  ...restProps
}) => {
  return (
    <CSSTransition
      in={open}
      timeout={{
        enter: enter || 0,
        exit: exit || 900,
      }}
      unmountOnExit
    >
      <div
        {...restProps}
        className={`default-dialog-container ${
          disableDefaultAnimation ? '' : open ? 'open' : 'closed'
        } ${classModifier || ''}`}
      >
        <div className="default-dialog-header-container">
          <div className="default-dialog-header">{header}</div>
          {onClose && (
            <div
              onClick={() => {
                onClose()
              }}
              className="default-dialog-close-icon"
            >
              <CloseIcon />
            </div>
          )}
        </div>
        <div className="default-dialog-content">{children}</div>
      </div>
    </CSSTransition>
  )
}

export default DefaultDialog
