import './LatestPhoto.css'
import React from 'react'

const SmallPhoto = ({ image, infoTitle, photoAlt, setImageData, setShow }) => {
  return (
    <div className="latest-photo-small-container">
      <img
        onClick={() => {
          setImageData(image, infoTitle)
          setShow(true)
        }}
        className="latest-photo-small"
        src={image}
        alt={photoAlt}
      ></img>
      <div className="info-small">{infoTitle}</div>
    </div>
  )
}

export default SmallPhoto
