import React from 'react'
import './AddNewPlantModal.css'
import { useTranslation } from 'react-i18next'

const PredefinedAutomation = () => {
  const { t } = useTranslation('translations')
  return (
    <div>
      <div className="add-plant-normal-text text-margin-bottom">{t('automations.plan_set')}</div>
      <div className="form-box">
        <label className="add-plant-normal-text">{t('automations.plan_name')}</label>
        <input className="add-plant-input" type="text" placeholder="Enter plan name" />
      </div>
      <div className="form-box">
        <label className="add-plant-normal-text">{t('automations.set_box_name')}</label>
        <input className="add-plant-input" type="text" placeholder="Enter box name" />
      </div>
    </div>
  )
}

export default PredefinedAutomation
