import React, { useState, useEffect } from 'react'
import AutomationBreadCrumbs from './AutomationBreadCrumbs'
import DeviceSelector from '../DeviceSelector/DeviceSelector'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../hooks/useAuth'
import { getDevicesByType } from '../../util/util'
import DataService from '../../data/DataService'
import SectionHeader from '../DefaultComponents/SectionHeader'
import './CustomAutomation.css'
import { ReactComponent as CustomAutomationIcon } from '../../assets/CustomAutomationIcon.svg'

const ManageDeviceAutomations = () => {
  const [devices, setDevices] = useState([])
  const [automationOptions, setAutomationOptions] = useState([])
  const [updateCount, setUpdateCount] = useState(1)
  const { connectedDevices, selectedDeviceType, setConnectedDevices, user } = useAuth()
  const { t } = useTranslation('translations')

  useEffect(() => {
    if (connectedDevices.length && selectedDeviceType) {
      let devicesByType = getDevicesByType(connectedDevices, selectedDeviceType)
      if (devicesByType.length) {
        setDevices(devicesByType)
      }
    }
    getAutomations()
  }, [connectedDevices, selectedDeviceType, updateCount, user])

  const getAutomations = async () => {
    const automationData = await DataService.getAutomations({ filter: '', user_id: user.user_id })
    if (automationData.length) {
      let options = []
      for (const automation of automationData) {
        options.push({ name: automation.title, value: automation.automation_id })
      }
      options.unshift({ name: '', value: 0 })
      setAutomationOptions(options)
    }
  }

  const updateDeviceAutomation = async (automation_id, device_id) => {
    const updateDevice = await DataService.updateDeviceAutomation({
      automation_id,
      device_id,
      user_id: user.user_id,
    })
    if (updateDevice) {
      let tempConnectedDevices = [...connectedDevices]
      connectedDevices.forEach((value, index, array) => {
        if (value.device_id === device_id) {
          tempConnectedDevices[index].automation_id = automation_id
        }
      })
      setConnectedDevices(tempConnectedDevices)
    }
  }

  return (
    <div>
      <div className="breadcrumbs">
        <AutomationBreadCrumbs route={t('automations.manage_device_automation')} />
      </div>
      <SectionHeader
        icon={<CustomAutomationIcon className="automation-icon" />}
        title={t('automations.devices')}
      />
      <div className="indicator-container">
        {devices.length &&
          automationOptions.length &&
          devices.map((e, i) => (
            <div key={i} className="device-automation-container">
              <div className="default-input-label">{e.name}</div>
              <DeviceSelector
                onSelect={(value) => updateDeviceAutomation(value, e.device_id)}
                value={e?.automation_id || 0}
                options={automationOptions}
              />
            </div>
          ))}
      </div>
    </div>
  )
}

export default ManageDeviceAutomations
