import React from 'react'
import './GalleryImage.css'
import { getFormatedDateGallery } from '../../util/formatDate'

const GalleryImage = ({ image, imageAlt, imageDate }) => {
  return (
    <div className="gallery-image-container">
      <img className="gallery-image" src={image} alt={imageAlt}></img>
      <div className="gallery-image-title">{getFormatedDateGallery(imageDate)}</div>
    </div>
  )
}

export default GalleryImage
