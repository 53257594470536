import './HelpScreen.css'
import React, { useState } from 'react'
import SectionHeader from '../../components/DefaultComponents/SectionHeader'
import { ReactComponent as SupportIcon } from '../../assets/SupportIcon.svg'
import { getFormatedDateNowDiary, getDataForLast30Days } from '../../util/formatDate'
import { DefaultButton } from '../../components/DefaultComponents'
import DeviceSelector from '../../components/DeviceSelector/DeviceSelector'
import { useAuth } from '../../hooks/useAuth'
import { ReactComponent as SendTicketIcon } from '../../assets/SendTicketIcon.svg'
import { ReactComponent as LatestUpdatesIcon } from '../../assets/LatestUpdatesIcon.svg'
import Table from '../../components/Table/Table'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import DataService from '../../data/DataService'

const HelpScreen = ({ updateLogs }) => {
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [sentStatus, setSentStatus] = useState('')
  const [loadingData, setLoadingData] = useState(false)
  const [update, setUpdate] = useState(false)
  const { t } = useTranslation('translations')
  const { user } = useAuth()

  const handleMessageStatus = (sent) => {
    switch (sent) {
      case 'success':
        return <span className="help-screen-success-message">{t('help.message_sent')}</span>
      case 'fail':
        return <span className="help-screen-fail-message">{t('help.message_not_sent')}</span>
      default:
        return <></>
    }
  }

  return (
    <div className="help-screen-container">
      <SectionHeader title={'Support'} icon={<SupportIcon />} classModifier={'pt0'} />
      <div className="date-info">{getFormatedDateNowDiary(new Date())}</div>
      <form className="form-container">
        <div className="helpScreen-deviceSelector-wrapper">
          <DeviceSelector onSelect={() => {}} />
        </div>
        <div className="title">{t('help.subject')}</div>
        <input
          onChange={(e) => setSubject(e.target.value)}
          value={subject}
          className="input-title"
          type="text"
          placeholder={t('help.subject_placeholder')}
        />
        <div className="title">{t('help.message')}</div>
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className="input-log"
        />
      </form>
      <div className="save-section">
        <DefaultButton
          loading={loadingData}
          disabled={loadingData}
          onClick={() => {
            DataService.handleTicketSubmit(subject, message, user, setLoadingData, setSentStatus)
          }}
          leftIcon
          customLeftIcon={<SendTicketIcon />}
        >
          {t('help.send')}
        </DefaultButton>
        {sentStatus && handleMessageStatus(sentStatus)}
      </div>
      <div className="all-entries-container">
        <SectionHeader title={t('help.latest_updates')} icon={<LatestUpdatesIcon />} />
        {updateLogs &&
          updateLogs.length > 0 &&
          getDataForLast30Days(updateLogs).map((d, i) => {
            return (
              d.entries && (
                <div key={i}>
                  <div className="month-title">{d.date}</div>
                  <Table type={'updateLog'} dataUpdated={setUpdate} tableData={d.entries} />
                </div>
              )
            )
          })}
      </div>
    </div>
  )
}

HelpScreen.propTypes = {
  updateLogs: PropTypes.array.isRequired,
}

HelpScreen.defaultProps = {
  updateLogs: [
    { created_at: new Date(), title: 'Senzorix Platform 1.0.0 - Relase', content: 'Deep dive into the release' },
  ],
}

export default HelpScreen
