import React from 'react'
import './VersionModal.css'
import { DefaultButton } from '../../DefaultComponents'
import parse from 'html-react-parser'
import { useTranslation } from 'react-i18next'

const VersionModal = ({ versionData, onClose }) => {
  const { t } = useTranslation('translations')
  return (
    <div className="version-modal-container">
      <div className="version-modal-content">
        <div className="version-modal-title">{versionData.title}</div>
        <div className="version-modal-text">{parse(versionData.content)}</div>
        <DefaultButton onClick={onClose} customStyle={'version-modal-button'}>
          {t('modals.close')}
        </DefaultButton>
      </div>
    </div>
  )
}

export default VersionModal
