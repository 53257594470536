import React from 'react'
import './DefaultInput.css'

const DefaultInput = ({
  label,
  error,
  message,
  icon,
  classModifier,
  removeMessage,
  removeLabel,
  ...restProps
}) => {
  return (
    <div className={`default-input-container ${classModifier || ''}`}>
      {removeLabel ? null : <div className="default-input-label">{label}</div>}
      <div className={`default-input-wrapper ${error ? 'error' : ''}`}>
        <input {...restProps} className="default-input" />
        {icon}
      </div>
      {removeMessage ? (
        ''
      ) : (
        <div className={`default-input-message ${error ? 'error' : ''}`}>{message || '\u00a0'}</div>
      )}
    </div>
  )
}

export default DefaultInput
