import React, { useEffect, useRef, useState } from 'react'
import './AddNewPlantModal.css'
import { DefaultButton } from '../../DefaultComponents'
import { ReactComponent as ButtonIconMore } from '../../../assets/ButtonIconMore.svg'
import { ReactComponent as CheckmarkIconCircle } from '../../../assets/CheckmarkIconCircle.svg'
import CustomAutomation from './CustomAutomation'
import { useTranslation } from 'react-i18next'
import PredefinedAutomation from './PredefinedAutomation'
import ChooseAutomationType from './ChooseAutomationType'
import DataService from '../../../data/DataService'
import { CircularProgress } from '@mui/material'

const AddNewPlantModal = ({ onClose }) => {
  const { t } = useTranslation('translations')
  const [step, setStep] = useState(0)
  const [customAutomation, setCustomAutomation] = useState(false)
  const [predefinedAutomation, setPredefinedAutomation] = useState(false)
  const [selectedPlantIndex, setSelectedPlantIndex] = useState()

  const [searchString, setSearchString] = useState('')
  const [loadingResults, setLoadingResults] = useState(false)
  const searchTimeoutRef = useRef()
  const [searchResults, setSearchResults] = useState()

  useEffect(() => {
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current)
    }
    searchTimeoutRef.current = setTimeout(() => {
      if (searchTimeoutRef.current) {
        searchTimeoutRef.current = undefined
      }

      if (searchString.length < 2) {
        return
      }

      setLoadingResults(true)
      DataService.searchPlants(searchString)
        .then((response) => {
          setSearchResults(response?.data ?? [])
        })
        .finally(() => {
          setLoadingResults(false)
        })
    }, 500)

    return () => {
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current)
        searchTimeoutRef.current = undefined
      }
    }
  }, [searchString])

  const finishSetupButtons = () => {
    return (
      <div className="finish-setup-btns">
        <DefaultButton onClick={onClose} customStyle={'add-plant-btn'}>
          {t('recaptcha_dialog.cancel')}
        </DefaultButton>
        <DefaultButton
          leftIcon
          customLeftIcon={<CheckmarkIconCircle />}
          onClick={onClose}
          customStyle={'finish-setup-btn'}
        >
          {t('automations.finish_setup')}
        </DefaultButton>
      </div>
    )
  }

  return (
    <div className="modal-body">
      <div className="add-plant-title">{t('automations.new_plant_wizard')}</div>
      {customAutomation ? (
        <div>
          <CustomAutomation />
          {finishSetupButtons()}
        </div>
      ) : predefinedAutomation ? (
        <div>
          <PredefinedAutomation />
          {finishSetupButtons()}
        </div>
      ) : (
        <div>
          {step === 0 && <div className="add-plant-text">{t('automations.steps.first_step')}</div>}
          {step === 1 && (
            <div>
              <div className="add-plant-input-container">
                <div className="add-plant-text">{t('automations.steps.second_step')}</div>
                <input
                  className="add-plant-input"
                  type="text"
                  onChange={(e) => setSearchString(e.target.value)}
                  value={searchString}
                />
              </div>
              <div className="add-plant-input-container">
                {loadingResults && <CircularProgress sx={{ mx: 'auto' }} color="success" />}
                {!loadingResults && searchResults && (
                  <>
                    {searchResults.length === 0 ? (
                      <div className="add-plant-text">{t('automations.no_results')}</div>
                    ) : (
                      <div className="add-plant-text">{t('automations.choose_from_result')}</div>
                    )}
                    {searchResults.map((plant) => (
                      <div
                        key={plant.plant_id}
                        onClick={() => setSelectedPlantIndex(plant.plant_id)}
                        className="add-plant-result"
                      >
                        <input
                          className="add-plant-result-checkmark"
                          checked={plant.plant_id === selectedPlantIndex}
                          type="radio"
                          onChange={() => {
                            setSelectedPlantIndex(plant.plant_id)
                          }}
                          value={'Tomato'}
                        />
                        <div className="add-plant-result-text">{`${plant.plant_name} - ${plant.plant_type}`}</div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          )}
          {step === 2 && (
            <ChooseAutomationType
              setCustomAutomation={setCustomAutomation}
              setPredefinedAutomation={setPredefinedAutomation}
            />
          )}
          <div className="add-plant-btns">
            <DefaultButton onClick={onClose} customStyle={'add-plant-btn'}>
              {t('recaptcha_dialog.cancel')}
            </DefaultButton>
            {step < 2 && (
              <DefaultButton
                onClick={() => setStep((prev) => prev + 1)}
                rightIcon
                customRightIcon={<ButtonIconMore className="btn-proceed-icon" />}
                customStyle={'add-plant-btn btn-proceed'}
              >
                {t('system_checks.proceed')}
              </DefaultButton>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default AddNewPlantModal
