import React, { useState, useRef } from 'react'
import { ReactComponent as SaveIcon } from '../../assets/SaveIcon.svg'
import { ReactComponent as RemoveIcon } from '../../assets/RemoveIcon.svg'
import { ReactComponent as CloseIcon } from '../../assets/CloseIcon.svg'
import { DefaultButton } from '../DefaultComponents'
import { useTranslation } from 'react-i18next'
import './CustomAutomation.css'
import StagesForm from './StagesForm'

const StageModal = ({ modalState, onClose, stageData, dataUpdated, isTemplate, templateData }) => {
  const stageFormRef = useRef(null)
  const { t } = useTranslation('translations')
  const deleteStage = async () => {
    if (isTemplate) {
      const res = await stageFormRef.current.deleteStage(templateData.stage_id, isTemplate)
      if (res) {
        onClose()
      }
    } else {
      const res = await stageFormRef.current.deleteStage(stageData.stage_id, isTemplate)
      if (res) {
        onClose()
      }
    }
  }

  const updateStage = async () => {
    if (isTemplate) {
      const res = await stageFormRef.current.updateStage(templateData.stage_id, isTemplate)
      if (res) {
        dataUpdated(res)
        onClose()
      }
    } else {
      const res = await stageFormRef.current.updateStage(stageData.stage_id, isTemplate)
      if (res) {
        onClose()
      }
    }
  }

  const closeModal = () => {
    onClose()
  }

  return (
    <div className="edit-modal">
      <div className="edit-modal-container">
        <div className="edit-modal-box">
          <div className="edit-modal-control">
            <CloseIcon className="edit-modal-close-icon" onClick={() => closeModal()} />
          </div>
          <StagesForm
            modalState={modalState}
            stageData={stageData}
            isTemplate={isTemplate}
            templateData={templateData}
            ref={stageFormRef}
          />
          <div className="button-row">
            <DefaultButton
              onClick={() => updateStage()}
              variant={'green'}
              leftIcon
              customLeftIcon={<SaveIcon />}
            >
              {t('modals.save')}
            </DefaultButton>
            <DefaultButton
              onClick={() => deleteStage(stageData.stage_id)}
              variant={'red'}
              leftIcon
              customLeftIcon={<RemoveIcon />}
            >
              {t('modals.remove')}
            </DefaultButton>
            <DefaultButton onClick={() => closeModal()}>{t('modals.close')}</DefaultButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StageModal
