import React, { useEffect, useState } from 'react'
import './Diary.css'
import { ReactComponent as YourDiaryIcon } from '../../assets/YourDiaryIcon.svg'
import { ReactComponent as GalleryIcon } from '../../assets/GalleryIcon.svg'
import { ReactComponent as FolderIcon } from '../../assets/FolderIcon.svg'
import SectionHeader from '../../components/DefaultComponents/SectionHeader'
import Table from '../../components/Table/Table'
import { getFormatedDateNowDiary, getDataForLast30Days } from '../../util/formatDate'
import DeviceSelector from '../../components/DeviceSelector/DeviceSelector'
import { useAuth } from '../../hooks/useAuth'
import DataService from '../../data/DataService'
import { useTranslation } from 'react-i18next'
import { DefaultButton } from '../../components/DefaultComponents'

const Diary = () => {
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [disabled, setDisabled] = useState(true)
  const [logSaved, setLogSaved] = useState(false)
  const [update, setUpdate] = useState(false)
  const [deviceDiaries, setDeviceDiaries] = useState([])
  const [selectedTypeName, setSelectedTypeName] = useState('')
  const [deviceBoxOptions, setDeviceBoxOptions] = useState([])
  const [deviceSystemOptions, setDeviceSystemOptions] = useState([])
  const [deviceControlOptions, setDeviceControlOptions] = useState([])
  const [selectedBoxDeviceId, setSelectedBoxDeviceId] = useState(0)
  const [selectedSystemDeviceId, setSelectedSystemDeviceId] = useState(0)
  const [selectedControlDeviceId, setSelectedControlDeviceId] = useState(0)
  const { t } = useTranslation('translations')
  const {
    connectedDevices,
    selectedDeviceType,
    selectedBoxDevice,
    selectedSystemDevice,
    selectedControlDevice,
    changeSelectedBoxDevice,
    changeSelectedSystemDevice,
    changeSelectedControlDevice,
    changeSelectedDeviceType,
    user,
  } = useAuth()

  const getAllDiariesForDevice = async () => {
    let deviceId = 0
    if (selectedDeviceType === 'groovyBox') {
      deviceId = selectedBoxDeviceId
    }
    if (selectedDeviceType === 'groovySystem') {
      deviceId = selectedSystemDeviceId
    }
    if (selectedDeviceType === 'groovyControl') {
      deviceId = selectedControlDeviceId
    }
    const userPlan = 'trial'
    if (deviceId) {
      const responseDiaries = await DataService.getAllDiariesForDevice({
        userId: user.user_id,
        deviceId,
        userPlan,
      })
      if (responseDiaries) {
        setDeviceDiaries(responseDiaries)
      } else {
        setDeviceDiaries([])
      }
    }
  }

  useEffect(() => {
    if (selectedDeviceType) {
      setSelectedTypeName(selectedDeviceType)
    }
    if (selectedBoxDevice) {
      setSelectedBoxDeviceId(selectedBoxDevice)
    }
    if (selectedSystemDevice) {
      setSelectedSystemDeviceId(selectedSystemDevice)
    }
    if (selectedControlDevice) {
      setSelectedControlDeviceId(selectedControlDevice)
    }
    if (connectedDevices.length > 0) {
      let boxOptions = []
      let systemOptions = []
      let controlOptions = []
      connectedDevices.forEach((value) => {
        if (value.device_type_name === 'groovyBox') {
          boxOptions.push({ value: value.device_id, name: value.name })
        }
        if (value.device_type_name === 'groovySystem') {
          systemOptions.push({ value: value.device_id, name: value.name })
        }
        if (value.device_type_name === 'groovyControl') {
          controlOptions.push({ value: value.device_id, name: value.name })
        }
      })
      setDeviceBoxOptions(boxOptions)
      setDeviceSystemOptions(systemOptions)
      setDeviceControlOptions(controlOptions)
    }
  }, [
    selectedDeviceType,
    selectedBoxDevice,
    selectedSystemDevice,
    selectedControlDevice,
    connectedDevices,
    changeSelectedDeviceType,
  ])

  useEffect(() => {
    getAllDiariesForDevice()
    setUpdate(false)
  }, [
    selectedBoxDeviceId,
    selectedControlDeviceId,
    selectedSystemDeviceId,
    changeSelectedDeviceType,
  ])

  useEffect(() => {
    getAllDiariesForDevice()
    setUpdate(false)
  }, [update])

  useEffect(() => {
    setDisabled(title.trim().length === 0 || content.trim().length === 0)
  }, [title, content])

  const dataChanged = (update) => {
    setUpdate(update)
  }

  const handleSubmit = async () => {
    let deviceId
    if (selectedTypeName === 'groovyBox') {
      deviceId = selectedBoxDeviceId
    }
    if (selectedTypeName === 'groovySystem') {
      deviceId = selectedSystemDeviceId
    }
    if (selectedTypeName === 'groovyControl') {
      deviceId = selectedControlDeviceId
    }
    const body = {
      title: title,
      content: content,
      deviceId: parseInt(deviceId),
      userId: user.user_id,
    }
    const response = await DataService.saveDiary(body)
    if (response === 200) {
      setTitle('')
      setContent('')
      setLogSaved(true)
      setTimeout(() => {
        setLogSaved(false)
      }, 2500)
    }
    getAllDiariesForDevice()
  }

  return (
    <div className="diary-container">
      {selectedTypeName === 'groovyBox' && (
        <DeviceSelector
          onSelect={(value) => changeSelectedBoxDevice(value)}
          options={deviceBoxOptions}
          value={parseInt(selectedBoxDeviceId)}
        />
      )}
      {selectedTypeName === 'groovySystem' && (
        <DeviceSelector
          onSelect={(value) => changeSelectedSystemDevice(value)}
          options={deviceSystemOptions}
          value={parseInt(selectedSystemDeviceId)}
        />
      )}
      {selectedTypeName === 'groovyControl' && (
        <DeviceSelector
          onSelect={(value) => changeSelectedControlDevice(value)}
          options={deviceControlOptions}
          value={parseInt(selectedControlDeviceId)}
        />
      )}
      <SectionHeader title={t('diary.your_diary')} icon={<YourDiaryIcon />} />
      <div className="date-info">{getFormatedDateNowDiary(new Date())}</div>
      <div className="form-container">
        <div className="title">{t('diary.title')}</div>
        <input
          onChange={(e) => setTitle(e.target.value)}
          value={title}
          className="input-title"
          type="text"
          placeholder={t('diary.title_placeholder')}
        />
        <div className="title">{t('diary.log')}</div>
        <textarea
          value={content}
          onChange={(e) => setContent(e.target.value)}
          className="input-log"
        />
        <div className="save-section">
          <DefaultButton
            onClick={() => handleSubmit()}
            leftIcon
            customLeftIcon={<GalleryIcon className="photo-icon" />}
            disabled={disabled}
          >
            {t('modals.save')}
          </DefaultButton>
          {logSaved && <span className="success-message">{t('diary.log_saved')}</span>}
        </div>
      </div>
      {deviceDiaries.length > 0 && (
        <div className="all-entries-container">
          <SectionHeader title={t('diary.all_entries')} icon={<FolderIcon />} />
          {getDataForLast30Days(deviceDiaries).map((d, i) => {
            return (
              d.entries && (
                <div key={i}>
                  <div className="month-title">{d.date}</div>
                  <Table type={'diary'} dataUpdated={(e) => dataChanged(e)} tableData={d.entries} />
                </div>
              )
            )
          })}
        </div>
      )}
    </div>
  )
}

export default Diary
