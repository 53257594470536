import React, { useEffect, useState, useRef } from 'react'
import { ReactComponent as NextIcon } from '../../assets/NextIcon.svg'
import { ReactComponent as PreviousIcon } from '../../assets/PreviousIcon.svg'
import { ReactComponent as CloseIcon } from '../../assets/CloseIcon.svg'

const GalleryCarousel = ({
  imagesData,
  intialImageIndex,
  onClose,
  showThumbnails,
  showActionArrows,
}) => {
  const [imageIndex, setImageIndex] = useState(intialImageIndex)
  const [thumbnails, setThumbnails] = useState([])
  const imageRef = useRef(null)
  const thumbnailRef = useRef(null)
  const actionRefNext = useRef(null)
  const actionRefPrev = useRef(null)

  useEffect(() => {
    getThumbnails()
  }, [imageIndex])

  const getThumbnails = () => {
    let thumbnailsImages = []
    let prevIndex, nextIndex
    prevIndex = imageIndex - 3
    nextIndex = imageIndex + 3
    if (nextIndex > imagesData.length - 1) {
      nextIndex = imagesData.length - 1
    }
    if (prevIndex < 0) {
      prevIndex = 0
    }

    for (let i = prevIndex; i <= nextIndex; i++) {
      thumbnailsImages.push({
        index: i,
        image: imagesData[i].url,
        imageTitle: imagesData[i].imageTitle,
      })
    }
    setThumbnails(thumbnailsImages)
  }

  const nextImage = (e) => {
    e.stopPropagation()
    if (imageIndex === imagesData.length - 1) {
      setImageIndex(0)
      return
    }
    setImageIndex(imageIndex + 1)
  }

  const previousImage = (e) => {
    e.stopPropagation()
    if (imageIndex === 0) {
      setImageIndex(imagesData.length - 1)
      return
    }
    setImageIndex(imageIndex - 1)
  }

  return (
    <div className="gallery-carousel">
      <div
        className="close-carousel"
        onClick={(e) => {
          e.stopPropagation()
          onClose(false)
        }}
      >
        <CloseIcon width={25} height={25} />
      </div>
      {showActionArrows && (
        <div>
          <div ref={actionRefPrev} className="gallery-carousel-prev" onClick={previousImage}>
            <PreviousIcon />
          </div>
          <div ref={actionRefNext} className="gallery-carousel-next" onClick={nextImage}>
            <NextIcon />
          </div>
        </div>
      )}
      <img
        onClick={(e) => e.stopPropagation()}
        className={`${showThumbnails ? 'gallery-carousel-image' : 'gallery-image-preview'}`}
        src={imageIndex !== undefined ? imagesData[imageIndex].url : imagesData.url}
      />
      <span className="gallery-carousel-image-text">
        {imageIndex !== undefined ? imagesData[imageIndex].imageTitle : imagesData.imageTitle}
      </span>
      {showThumbnails && (
        <div onClick={(e) => e.stopPropagation()} ref={thumbnailRef} className="thumbnails">
          {thumbnails.map((image, index) => (
            <img
              key={index}
              onClick={() => {
                setImageIndex(image.index)
              }}
              className={`${
                imageIndex === image.index
                  ? 'gallery-carousel-thumbnail-active'
                  : 'gallery-carousel-thumbnail'
              }`}
              src={image.url}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default GalleryCarousel
