import { ReactComponent as DashboardIcon } from '../assets/DashboardIcon.svg'
import { ReactComponent as DiaryIcon } from '../assets/DiaryIcon.svg'
import { ReactComponent as AutomationIcon } from '../assets/AutomationsIcon.svg'
import { ReactComponent as HelpIcon } from '../assets/HelpIcon.svg'
import { ReactComponent as SettingsIcon } from '../assets/SettingsIcon.svg'
import { ReactComponent as UpgradeIcon } from '../assets/UpgradeIcon.svg'

export const authBaseUrl = 'https://auth.senzorix.co'
// export const befeBaseUrl = 'http://localhost:3001'
export const befeBaseUrl = 'https://befe.senzorix.co'
export const mailerBaseUrl = 'https://mailer.senzorix.co'

export const sensor_type_colors = {
  'Soil Moisture Sensor': '#4acadc',
  'Soil Acidity Sensor': '#8cd38d',
  'Air Moisture Sensor': '#6d85ff',
  'Air Temperature Sensor': '#f98c8a',
}

export const navOptions = [
  {
    icon: <DashboardIcon />,
    title: 'Dashboard',
    url: '/dashboard',
  },
  {
    icon: <AutomationIcon />,
    title: 'Automations',
    url: '/automations',
  },
  {
    icon: <DiaryIcon />,
    title: 'Diary',
    url: '/diary',
  },
  {
    icon: <SettingsIcon />,
    title: 'Settings',
    url: '/settings',
  },
  {
    icon: <HelpIcon />,
    title: 'Help',
    url: '/help',
  },
  // {
  //   icon: <UpgradeIcon />,
  //   title: 'Upgrade',
  //   url: '/upgrade',
  // },
  // {
  //   icon: <UpgradeIcon />,
  //   title: 'Rule Engine',
  //   url: '/ruleengine',
  // },
]

export const device_statuses = {
  1: true,
  2: false,
}

export const module_statuses = {
  TURNON: true,
  TURNOFF: false,
  rERN: true,
  rERF: false,
  rIRN: true,
  rIRF: false,
  rLRN: true,
  rLRF: false,
  rLN: true,
  rLF: false,
  rHN: true,
  rHF: false,
}

export const plans = [
  {
    type: 1,
    name: 'Free',
    price: {
      monthly: 0,
      annual: 0,
    },
    controls: [
      {
        name: 'Conditions control',
        included: true,
      },
    ],
    soilConditions: '30',
    airConditions: '30',
    phConditions: null,
    dataRetention: '3 days',
    devices: 1,
    scheduler: false,
    growingPlans: false,
    reports: false,
  },
  {
    type: 2,
    name: 'Premium',
    price: {
      monthly: 6,
      annual: 60,
    },
    controls: [
      {
        name: 'Conditions control',
        included: true,
      },
      {
        name: 'Automated plans',
        included: true,
      },
    ],
    soilConditions: '20',
    airConditions: '20',
    phConditions: null,
    dataRetention: '6 months',
    devices: 3,
    scheduler: true,
    growingPlans: true,
    reports: false,
  },
  {
    type: 3,
    name: 'Ultimate',
    price: {
      monthly: 30,
      annual: 300,
    },
    controls: [
      {
        name: 'Conditions control',
        included: true,
      },
      {
        name: 'Automated plans',
        included: true,
      },
    ],
    soilConditions: '5-30',
    airConditions: '5-30',
    phConditions: 'Per measurement',
    dataRetention: '1 year',
    devices: 15,
    scheduler: true,
    growingPlans: true,
    reports: true,
  },
]

export const stepMoisture = [
  {
    stepNumber: 1,
    title: 'Moisture Calibration',
    text: 'Follow the next steps to successfully calibrate moisture sensor. You can also reset calibration, this will reset calibration data for ALL device sensors.',
  },
  {
    stepNumber: 2,
    title: 'Moisture Calibration - Dry out',
    text: 'Dry out the sensor. Take the mop that is included in the package and carefully dry out the sensor.',
  },
  {
    stepNumber: 3,
    title: 'Moisture Calibration - Dry out',
    text: 'Confirm that the sensor is completely dry. Once you confirm this, the system will start the calibration.',
  },
  {
    stepNumber: 4,
    title: 'Moisture Calibration - In process...',
    text: 'The moisture calibration is in the process now. Please wait for a few seconds.',
  },
  {
    stepNumber: 5,
    title: "Moisture Calibration - It's dry!",
    text: 'Dry sensor is calibrated now. Now, let’s calibrate the wet sensor!',
  },
  {
    stepNumber: 6,
    title: 'Moisture Calibration - Wet sensor',
    text: 'Put the sensor in a glass of water for 10 to 15 seconds.',
  },
  {
    stepNumber: 7,
    title: 'Moisture Calibration - Wet sensor',
    text: 'Please confirm that the sensor is in the water so that system can start the calibration process.',
  },
  {
    stepNumber: 8,
    title: 'Moisture Calibration - Wet calibration in process...',
    text: 'The moisture calibration is in the process now. Please wait for a few seconds.',
  },
  {
    stepNumber: 9,
    title: "Moisture Calibration - It's wet!",
    text: 'Wet sensor is calibrated now. You have successfully calibrated the moisture sensor.',
  },
]

export const stepPh = [
  {
    stepNumber: 1,
    title: 'pH Calibration',
    text: 'Follow the next steps to successfully calibrate pH sensor.',
  },
  {
    stepNumber: 2,
    title: 'pH Calibration',
    text: 'To calibrate pH sensor you need to get Offset from 7.00 standard buffer solution and then manualy configure in desired solution.',
  },
  {
    stepNumber: 3,
    title: 'pH Calibration - pH 7',
    text: 'Put pH probe into 7.00 standard buffer solution and wait for 1 minute. Then press the “confirm” button.',
  },
  {
    stepNumber: 4,
    title: 'pH Calibration - pH 7',
    text: 'The pH calibration is in the process now. Please wait for a few seconds.',
  },
  {
    stepNumber: 5,
    title: 'pH Calibration',
    text: 'Offset calibrated successfully. Now for 4.00 standard solution.',
  },
  {
    stepNumber: 6,
    title: 'pH Calibration',
    text: 'Clean the pH probe with pure water. Distilled Water is recommended. Then absorb the residual water traces with filter paper.',
  },
  {
    stepNumber: 7,
    title: 'pH Calibration',
    text: ' Put the pH probe into the 4.00 pH standard solution and wait for 1 minute. Then press the “confirm” button.',
  },
  {
    stepNumber: 8,
    title: 'pH Calibration',
    text: 'Open the serial monitor of the Arduino IDE and adjust the potentiometer on the transmitter board to tune the gain until the printed value is stable at around 4.00',
  },
  {
    stepNumber: 9,
    title: 'pH Calibration',
    text: 'pH sensor is successfully calibrated now. Click the button to finish the calibration process.',
  },
]

export const SOIL_MOISTURE_SENSOR_ID = 1 // defined in DB
export const SOIL_PH_SENSOR_ID = 3 // defined in DB
export const SOIL_MOISTURE_SENSOR_ID_1 = 25 // defined in DB
export const SOIL_MOISTURE_SENSOR_ID_2 = 26 // defined in DB
export const SOIL_MOISTURE_SENSOR_ID_3 = 27 // defined in DB
export const SOIL_MOISTURE_SENSOR_ID_4 = 28 // defined in DB
export const INTAKE_FAN_ID = 12 // defined in DB
export const EXHAUST_FAN_ID = 17 // defined in DB
export const LITTLE_FAN_ID = 33 // defined in DB
export const MODULE_MIN_PAYLOAD = 'MN'
export const MODULE_MAX_PAYLOAD = 'MX'
export const MODULE_PH_PAYLOAD = 'PH'
export const HEATER_ID = 10 // defined in DB
export const LIGHT_ID = 13 // defined in DB
export const SWITCH_ID = 19 //defined in DB
export const AIR_TEMPERATURE_CONTROLLER_ID = 35 // defined in DB

export const CUSTOM_MODULE_MIN_PAYLOADS = {
  25: 'MN25',
  26: 'MN26',
  27: 'MN27',
  28: 'MN28',
}

export const CUSTOM_MODULE_MAX_PAYLOADS = {
  25: 'MX25',
  26: 'MX26',
  27: 'MX27',
  28: 'MX28',
}

export const defaultCustomNoficiations = {
  systemUpdates: true,
  progress: true,
  alerts: false,
  advices: false,
  plantStatus: false,
  promotional: false,
}

export const dropdownOptions = () => {
  const arr = []

  for (let i = 1; i <= 12; i++) {
    arr.push({
      display: i.toString().padStart(2, '0'),
      value: i,
    })
  }

  return arr
}
