import React, { useState, useEffect } from 'react'
import './ConnectDevice.css'
import { useNavigate } from 'react-router-dom'
import WalkthroughContainer from '../WalkthroughContainer/WalkthroughContainer'
import { DefaultButton, DefaultDialog, DefaultLink } from '../../DefaultComponents'
import DataService from '../../../data/DataService'
import { ReactComponent as ButtonIconMore } from '../../../assets/ButtonIconMore.svg'
import { ReactComponent as InfoIcon } from '../../../assets/InfoIcon.svg'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../../hooks/useAuth'
import { Trans } from 'react-i18next'

const ConnectDevice = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('translations')
  const [connected, setConnected] = useState(false)
  const [displayDialog, setDisplayDailog] = useState(false)
  const [deviceData, setDeviceData] = useState({})
  const { user, updateUser, logoutUser } = useAuth()

  useEffect(() => {
    const intervalId = setInterval(async () => checkDeviceConnection(intervalId), 5000)
    return () => clearInterval(intervalId)
  }, [])

  const checkDeviceConnection = async (intervalId) => {
    if (user.email) {
      const response = await DataService.checkDeviceConnection(user.email)
      if (response?.data.connected) {
        setDeviceData(response.data.devices[0])
        setConnected(true)
        return clearInterval(intervalId)
      } else {
        setConnected(false)
      }
    }
  }

  return (
    <WalkthroughContainer>
      {
        <div className="connect-info-container">
          <div className="connect-info-icon-container">
            <InfoIcon
              className="connect-info-icon"
              onClick={() => setDisplayDailog(!displayDialog)}
            />
          </div>
          <DefaultDialog
            header={
              <div className="connect-dialog-header-container">
                <div className="connect-dialog-header">{t('connect.dialog_message')}</div>
                <DefaultLink classModifier={'connect-dialog-link'}>
                  {t('connect.dialog_link')}
                </DefaultLink>
              </div>
            }
            open={displayDialog}
            onClose={() => setDisplayDailog(false)}
          ></DefaultDialog>
        </div>
      }
      <div className="connect-container">
        <div className="connect-title">
          {connected ? t('connect.connection_success_title') : t('connect.title')}
        </div>
        {isMobile && !connected ? (
          <div className="connect-mobile-content">
            <div className="connect-text">
              <Trans t={t} i18nKey="connect.text" />
            </div>
            <div className="connect-scan-container">
              <div className="connect-separator-container">
                <div className="connect-separator" />
                <div className="connect-separator-text">{t('connect.or')}</div>
                <div className="connect-separator" />
              </div>
              <div className="connect-scan-text">{t('connect.scann')}</div>
              <div className="connect-qr-container">
                <div className="connect-qr-code" />
              </div>
            </div>
          </div>
        ) : (
          <div className="connect-text-container">
            {connected ? (
              t(t('connect.connection_success_message'))
            ) : (
              <div className="connect-text">
                <Trans t={t} i18nKey="connect.text" />
              </div>
            )}
          </div>
        )}
        <div className="connect-button-container">
          <DefaultButton
            onClick={async () => {
              await logoutUser()
              navigate('/login')
            }}
          >
            {t('login.logout')}
          </DefaultButton>
          <DefaultButton
            onClick={async () => {
              if (connected) {
                await updateUser()
                navigate('/system-check', {
                  state: {
                    deviceId: deviceData.device_id,
                    deviceType: deviceData.device_type_name,
                  },
                })
              }
            }}
            disabled={!connected}
          >
            {t('connect.proceed')}
            <ButtonIconMore />
          </DefaultButton>
        </div>
      </div>
    </WalkthroughContainer>
  )
}

export default ConnectDevice
