import React, { useState } from 'react'
import './CalibrationModal.css'
import DefaultButton from '../../DefaultComponents/DefaultButton/DefaultButton'
import { ReactComponent as SettingsIcon } from '../../../assets/SettingsIcon.svg'
import { ReactComponent as CheckmarkIconCircle } from '../../../assets/CheckmarkIconCircle.svg'
import { ReactComponent as ButtonIconLess } from '../../../assets/ButtonIconLess.svg'
import { ReactComponent as CloseIcon } from '../../../assets/CloseIcon.svg'
import {
  stepMoisture,
  stepPh,
  SOIL_MOISTURE_SENSOR_ID,
  SOIL_PH_SENSOR_ID,
  SOIL_MOISTURE_SENSOR_ID_1,
  SOIL_MOISTURE_SENSOR_ID_2,
  SOIL_MOISTURE_SENSOR_ID_3,
  SOIL_MOISTURE_SENSOR_ID_4,
  MODULE_PH_PAYLOAD,
  MODULE_MIN_PAYLOAD,
  MODULE_MAX_PAYLOAD,
  CUSTOM_MODULE_MIN_PAYLOADS,
  CUSTOM_MODULE_MAX_PAYLOADS,
} from '../../../util/constants'
import { useTranslation } from 'react-i18next'
import DataService from '../../../data/DataService'
import { useAuth } from '../../../hooks/useAuth'
import { getDeviceMacAddressById, deviceHasCustomModules } from '../../../util/util'
import DeviceSelector from '../../../components/DeviceSelector/DeviceSelector'

const CalibrationModal = ({ onClose, deviceType }) => {
  const [calibrationStep, setCalibrationStep] = useState(null)
  const [calibrationType, setCalibrationType] = useState([])
  const [type, setType] = useState('')
  const [calibrationError, setCalibrationError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedModule, setSelectedModule] = useState(SOIL_MOISTURE_SENSOR_ID_1)
  const { t } = useTranslation('translations')
  const { connectedDevices, selectedSystemDevice, selectedBoxDevice } = useAuth()

  const moduleOptions = [
    { name: 'Soil Moisture #1', value: SOIL_MOISTURE_SENSOR_ID_1 },
    { name: 'Soil Moisture #2', value: SOIL_MOISTURE_SENSOR_ID_2 },
    { name: 'Soil Moisture #3', value: SOIL_MOISTURE_SENSOR_ID_3 },
    { name: 'Soil Moisture #4', value: SOIL_MOISTURE_SENSOR_ID_4 },
  ]

  const calibrate = async (type, step, retry) => {
    if (!retry) setCalibrationStep((prev) => prev + 1)
    let payload = ''
    if (type === 'ph') {
      payload = MODULE_PH_PAYLOAD
    } else {
      if (step === 2 || step === 3) {
        if (deviceHasCustomModules(connectedDevices, selectedSystemDevice)) {
          payload = CUSTOM_MODULE_MIN_PAYLOADS[selectedModule]
        } else {
          payload = MODULE_MIN_PAYLOAD
        }
      } else {
        if (deviceHasCustomModules(connectedDevices, selectedSystemDevice)) {
          payload = CUSTOM_MODULE_MAX_PAYLOADS[selectedModule]
        } else {
          payload = MODULE_MAX_PAYLOAD
        }
      }
    }
    const deviceAddress = getDeviceMacAddressById(
      connectedDevices,
      deviceType === 'GroovySystem' ? selectedSystemDevice : selectedBoxDevice,
    )
    const res = await DataService.sendActionRequest({
      payload: payload,
      action_name: 'Calibration',
      device_type: deviceType,
      device_mac_address: deviceAddress,
    })
    if (res) {
      setTimeout(() => checkCalibrationStatus(), 9000)
    } else {
      setCalibrationError(true)
    }
  }

  const resetCalibration = async () => {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 7000)
    const deviceAddress = getDeviceMacAddressById(
      connectedDevices,
      deviceType === 'GroovySystem' ? selectedSystemDevice : selectedBoxDevice,
    )
    const res = await DataService.sendActionRequest({
      payload: 'RC',
      action_name: 'ResetCalibration',
      device_type: deviceType,
      device_mac_address: deviceAddress,
    })
  }

  const checkCalibrationStatus = async () => {
    let module_id = SOIL_MOISTURE_SENSOR_ID
    if (type === 'ph') {
      module_id = SOIL_PH_SENSOR_ID
    }
    if (
      deviceHasCustomModules(
        connectedDevices,
        deviceType === 'GroovySystem' ? selectedSystemDevice : selectedBoxDevice,
      )
    ) {
      module_id = selectedModule
    }
    let res = await DataService.checkCalibrationStatus({
      device_id: parseInt(deviceType === 'GroovySystem' ? selectedSystemDevice : selectedBoxDevice),
      module_id: module_id,
    })
    if (res === 'OK') {
      setCalibrationStep((prev) => prev + 1)
    } else {
      setCalibrationError(true)
    }
  }

  const renderButtons = () => {
    return (
      <div className="calibration-btns">
        {calibrationStep === 0 || calibrationStep === 3 || calibrationStep === 7 ? (
          ''
        ) : (
          //First step button
          <DefaultButton
            customLeftIcon={<ButtonIconLess />}
            customStyle={'calibration-btn'}
            onClick={() => {
              setCalibrationStep((prev) =>
                calibrationStep === 4 || calibrationStep === 8 ? prev - 2 : prev - 1,
              )
            }}
            leftIcon
          >
            {t('modals.previous_step')}
          </DefaultButton>
        )}
        {calibrationStep === 2 || calibrationStep === 6 ? (
          //Button to confirm step
          <DefaultButton
            customStyle={'calibration-btn'}
            onClick={() => {
              if (calibrationStep === 6 && type === 'ph') {
                setCalibrationStep(8)
              } else {
                calibrate(type, calibrationStep, false)
              }
            }}
            customLeftIcon={<CheckmarkIconCircle />}
            leftIcon
          >
            {t('modals.confirm')}
          </DefaultButton>
        ) : calibrationStep === 3 || calibrationStep === 7 || calibrationStep === 8 ? (
          ''
        ) : (
          //Button to proceed to next step
          <DefaultButton
            customStyle={'calibration-btn'}
            onClick={() => {
              setCalibrationStep((prev) => prev + 1)
            }}
            leftIcon
          >
            {t('modals.next_step')}
          </DefaultButton>
        )}
        {calibrationStep === 0 && type === 'soil' ? (
          <DefaultButton
            customStyle={'calibration-btn'}
            loading={isLoading}
            loadingText={'Reseting ...'}
            onClick={() => {
              resetCalibration()
            }}
          >
            {t('modals.calibration.reset_calibration')}
          </DefaultButton>
        ) : null}
        {calibrationStep < calibrationType.length - 1 ? (
          //Cancel button on all steps except last one
          <DefaultButton customStyle={'calibration-step-cancel calibration-btn'} onClick={onClose}>
            {t('modals.calibration.cancel')}
          </DefaultButton>
        ) : (
          //Finish button only on last step
          <DefaultButton
            customStyle={'calibration-btn'}
            leftIcon
            customLeftIcon={<CheckmarkIconCircle />}
            onClick={onClose}
          >
            {t('modals.calibration.finish_the_process')}
          </DefaultButton>
        )}
      </div>
    )
  }

  const renderInitialButtons = () => {
    return (
      <div className="calibration-btns">
        <DefaultButton
          customLeftIcon={<SettingsIcon className="calibration-icon-color" />}
          customStyle={'calibration-btn'}
          leftIcon
          onClick={() => {
            setCalibrationStep(0)
            setCalibrationType(stepMoisture)
            setType('soil')
          }}
        >
          {t('modals.calibration.moisture_calibration')}
        </DefaultButton>
        <DefaultButton
          customLeftIcon={<SettingsIcon className="calibration-icon-color" />}
          leftIcon
          customStyle={'calibration-btn'}
          onClick={() => {
            setCalibrationStep(0)
            setCalibrationType(stepPh)
            setType('ph')
          }}
        >
          {t('modals.calibration.ph_calibration')}
        </DefaultButton>
        <DefaultButton customStyle={'calibration-step-cancel calibration-btn'} onClick={onClose}>
          {t('modals.calibration.cancel')}
        </DefaultButton>
      </div>
    )
  }

  return (
    <div className="calibration-modal">
      <div className="edit-modal-control-calibration">
        <CloseIcon className="edit-modal-close-icon" onClick={onClose} />
      </div>
      {calibrationStep === null ? (
        <div>
          <div className="calibration-step-title">{t('modals.calibration.calibration')}</div>
          <div className="calibration-step-text">{t('modals.calibration.calibration_text')}</div>
          {renderInitialButtons()}
        </div>
      ) : calibrationError ? (
        <div>
          <div className="calibration-step-title">{t('modals.calibration.calibration_error')}</div>
          <div className="calibration-step-text">
            {t('modals.calibration.calibration_error_text')}
          </div>
          <div className="calibration-btns">
            <DefaultButton
              customLeftIcon={<ButtonIconLess />}
              customStyle={'calibration-btn'}
              onClick={() => {
                setCalibrationStep((prev) => prev - 1)
                setCalibrationError(false)
              }}
              leftIcon
            >
              {t('modals.previous_step')}
            </DefaultButton>
            <DefaultButton
              customStyle={'calibration-btn'}
              onClick={() => {
                calibrate(type, calibrationStep, true)
                setCalibrationError(false)
              }}
            >
              {t('modals.try_again')}
            </DefaultButton>
          </div>
        </div>
      ) : (
        <div>
          <div className="calibration-step-title">
            {calibrationType[calibrationStep].stepNumber}/{calibrationType.length} -{' '}
            {t(
              `calibration_steper.${type === 'soil' ? 'moisture_calibration' : 'ph_calibration'}.${
                calibrationType[calibrationStep].stepNumber
              }.title`,
            )}
          </div>
          <div className="calibration-step-text">
            {t(
              `calibration_steper.${type === 'soil' ? 'moisture_calibration' : 'ph_calibration'}.${
                calibrationType[calibrationStep].stepNumber
              }.text`,
            )}
          </div>
          <div style={{ paddingBottom: '1rem' }}>
            {deviceHasCustomModules(connectedDevices, selectedSystemDevice) &&
            calibrationStep === 0 &&
            type === 'soil' &&
            deviceType === 'GroovySystem' ? (
              <div>
                <div className="default-input-label">{'Select sensor'}</div>
                <DeviceSelector
                  onSelect={(value) => setSelectedModule(value)}
                  value={selectedModule}
                  options={moduleOptions}
                />
              </div>
            ) : (
              ''
            )}
          </div>
          {renderButtons()}
        </div>
      )}
    </div>
  )
}

export default CalibrationModal
