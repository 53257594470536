import React, { useState, useEffect } from 'react'
import './Dashboard.css'
import GrowingPlan from '../../components/GrowingPlan/GrowingPlan'
import Charts from '../../components/Charts/Charts'
import LatestPhoto from '../../components/LatestPhoto/LatestPhoto'
import { useAuth } from '../../hooks/useAuth'
import BoxIndicators from './DashboardDeviceIndicators/BoxIndicators'
import ControlIndicators from './DashboardDeviceIndicators/ControlIndicators'
import SystemIndicators from './DashboardDeviceIndicators/SystemIndicators'
import DeviceSelector from '../../components/DeviceSelector/DeviceSelector'
import { ReactComponent as LabelIcon } from '../../assets/LabelIcon.svg'
import { getDeviceOptionsByType, getDeviceMacAddressById } from '../../util/util'
import DataService from '../../data/DataService'
import { CircularProgress } from '@mui/material'
import { useDevice } from '../../hooks/useDevice'

const plantInfo = [
  { property: 'Plant Name:', value: 'Rose', icon: <LabelIcon /> },
  { property: 'Plant Type:', value: 'Rosa rubiginosa', icon: <LabelIcon /> },
  // { property: 'Next action:', value: 'Water me please!', icon: <CalendarIcon /> },
]

const Dashboard = () => {
  const {
    connectedDevices,
    selectedDeviceType,
    selectedBoxDevice,
    selectedSystemDevice,
    selectedControlDevice,
    changeSelectedBoxDevice,
    changeSelectedSystemDevice,
    changeSelectedControlDevice,
  } = useAuth()
  const { deviceData: deviceCurrentData, refreshAutomationStatus } = useDevice()

  const [hasCamera, setHasCamera] = useState(false)
  const [galleryImages, setGalleryImages] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [refreshCharts, setRefreshCharts] = useState(false)

  const deviceOptions = getDeviceOptionsByType(connectedDevices, selectedDeviceType)

  useEffect(() => {
    setIsLoading(true)
    if (selectedBoxDevice) {
      checkIfDeviceHasCamera()
    }
  }, [selectedDeviceType, selectedBoxDevice])

  const checkIfDeviceHasCamera = async () => {
    const hasCamera = await DataService.getDeviceHasCamera(selectedBoxDevice)
    setHasCamera(hasCamera)

    if (hasCamera) {
      const pictures = await DataService.getGalleryPictures({
        deviceId: selectedBoxDevice,
        months: 12,
        desc: true,
      })
      setGalleryImages(pictures)
    }
    setIsLoading(false)
  }

  const handleRefresh = () => {
    setRefreshCharts((prevState) => !prevState)
  }

  const refreshDeviceData = () => {
    refreshAutomationStatus()
  }

  return connectedDevices.length ? (
    <div className="dashboard-content">
      <div className="dashboard-info-section">
        {selectedDeviceType === 'groovyBox' && (
          <DeviceSelector
            onSelect={(value) => {
              refreshDeviceData()
              changeSelectedBoxDevice(value)
            }}
            options={deviceOptions}
            value={parseInt(selectedBoxDevice)}
          />
        )}
        {selectedDeviceType === 'groovySystem' && (
          <DeviceSelector
            onSelect={(value) => {
              refreshDeviceData()
              changeSelectedSystemDevice(value)
            }}
            options={deviceOptions}
            value={parseInt(selectedSystemDevice)}
          />
        )}
        {selectedDeviceType === 'groovyControl' && (
          <DeviceSelector
            onSelect={(value) => {
              refreshDeviceData()
              changeSelectedControlDevice(value)
            }}
            options={deviceOptions}
            value={parseInt(selectedControlDevice)}
          />
        )}
        {/* <InfoContainer data={plantInfo} /> */}
      </div>
      {deviceCurrentData && <GrowingPlan currentDeviceSettings={deviceCurrentData}></GrowingPlan>}
      {selectedDeviceType === 'groovyBox' && (
        <BoxIndicators
          device_id={selectedBoxDevice}
          device_address={getDeviceMacAddressById(connectedDevices, selectedBoxDevice)}
          handleRefresh={handleRefresh}
        />
      )}
      {selectedDeviceType === 'groovySystem' && (
        <SystemIndicators
          device_id={selectedSystemDevice}
          device_address={getDeviceMacAddressById(connectedDevices, selectedSystemDevice)}
          handleRefresh={handleRefresh}
        />
      )}
      {selectedDeviceType === 'groovyControl' && (
        <ControlIndicators device_id={selectedControlDevice} />
      )}

      {selectedDeviceType === 'groovyBox' && (
        <div>
          {isLoading && <CircularProgress sx={{ mx: 'auto' }} color="success" />}
          {!isLoading && hasCamera && (
            <LatestPhoto
              photo1HourAgo={galleryImages[0]}
              photo2HourAgo={galleryImages[1]}
              photo3HourAgo={galleryImages[2]}
              photo4HourAgo={galleryImages[3]}
            />
          )}
        </div>
      )}
      <Charts refreshData={refreshCharts} />
    </div>
  ) : (
    ''
  )
}

export default Dashboard
