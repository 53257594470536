import axios from 'axios'
import React, { useState, createContext, useContext, useEffect } from 'react'
import DataService from '../data/DataService'
import { checkIfTypeHasDevice, checkIfUserHasDevice } from '../util/util'
import { defaultCustomNoficiations } from '../util/constants'

const AuthContext = createContext(null)

export const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false)
  const [user, setUser] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [connectedDevices, setConnectedDevices] = useState([])
  const [selectedDeviceType, setSelectedDeviceType] = useState('')
  const [selectedBoxDevice, setSelectedBoxDevice] = useState(0)
  const [selectedSystemDevice, setSelectedSystemDevice] = useState(0)
  const [selectedControlDevice, setSelectedControlDevice] = useState(0)
  const [userSettings, setUserSettings] = useState({
    notificationType: 'custom',
    customNotifications: defaultCustomNoficiations,
  })

  let baseUrl
  baseUrl = 'https://auth.senzorix.co/auth/verify'
  // baseUrl = 'http://localhost:3001/auth/verify'

  const fetchConnectedDevices = async (email) => {
    try {
      if (email) {
        const deviceResp = await DataService.checkDeviceConnection(email)
        if (deviceResp?.data) {
          setConnectedDevices(deviceResp.data.devices)
        }
      }
    } catch (error) {
      console.error(error, 'error')
    }
  }

  const refreshConnectedDevices = () => {
    if (user.email) {
      fetchConnectedDevices(user.email)
    }
  }

  const logoutUser = async () => {
    const resp = await DataService.logoutUser()
    if (resp === 200) {
      localStorage.clear()
      setConnectedDevices([])
      setAuthenticated(false)
    }
  }

  const updateUser = async () => {
    try {
      const resp = await axios.get(baseUrl, {
        withCredentials: true,
      })
      if (resp.status === 200) {
        const user = resp.data
        const res = await DataService.userRole(user?.user_id)
        if (res) {
          user.role = res.role_name
        }
        setUser(user)
      }
    } catch (error) {
      console.error(error, 'error')
    }
  }

  const changeSelectedDeviceType = (deviceType) => {
    setSelectedDeviceType(deviceType)
    localStorage.setItem('selectedType', deviceType)
  }

  const changeSelectedBoxDevice = (deviceId) => {
    setSelectedBoxDevice(deviceId)
    localStorage.setItem('selectedBoxDevice', deviceId)
  }

  const changeSelectedSystemDevice = (deviceId) => {
    setSelectedSystemDevice(deviceId)
    localStorage.setItem('selectedSystemDevice', deviceId)
  }

  const changeSelectedControlDevice = (deviceId) => {
    setSelectedControlDevice(deviceId)
    localStorage.setItem('selectedControlDevice', deviceId)
  }

  const getUserAccessToken = async () => {
    try {
      const resp = await axios.get(baseUrl, {
        withCredentials: true,
      })
      if (resp.status === 200) {
        const user = resp.data
        const res = await DataService.userRole(user?.user_id)
        if (res) {
          user.role = res.role_name
        }
        if (user.email) {
          fetchConnectedDevices(user.email)
        }
        setAuthenticated(true)
        setUser(user)
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      console.error(error, 'error')
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (connectedDevices && connectedDevices.length) {
      const selectedType = localStorage.getItem('selectedType')
      const selectedBoxDeviceId = localStorage.getItem('selectedBoxDevice')
      const selectedSystemDeviceId = localStorage.getItem('selectedSystemDevice')
      const selectedControlDeviceId = localStorage.getItem('selectedControlDevice')
      let devices = { groovyBox: [], groovySystem: [], groovyControl: [] }
      connectedDevices.forEach((value) => {
        if (value.device_type_name === 'groovyBox') {
          devices.groovyBox.push(value)
        }
        if (value.device_type_name === 'groovySystem') {
          devices.groovySystem.push(value)
        }
        if (value.device_type_name === 'groovyControl') {
          devices.groovyControl.push(value)
        }
      })

      if (selectedType && checkIfTypeHasDevice(connectedDevices, selectedType)) {
        setSelectedDeviceType(selectedType)
      } else {
        if (devices.groovyBox.length) {
          changeSelectedDeviceType(devices.groovyBox[0].device_type_name)
        } else if (devices.groovySystem.length) {
          changeSelectedDeviceType(devices.groovySystem[0].device_type_name)
        } else if (devices.groovyControl.length) {
          changeSelectedDeviceType(devices.groovyControl[0].device_type_name)
        }
      }

      if (selectedBoxDeviceId && checkIfUserHasDevice(connectedDevices, selectedBoxDeviceId)) {
        changeSelectedBoxDevice(selectedBoxDeviceId)
      } else {
        if (devices.groovyBox.length) {
          changeSelectedBoxDevice(devices.groovyBox[0].device_id)
        }
      }

      if (
        selectedSystemDeviceId &&
        checkIfUserHasDevice(connectedDevices, selectedSystemDeviceId)
      ) {
        changeSelectedSystemDevice(selectedSystemDeviceId)
      } else {
        if (devices.groovySystem.length) {
          changeSelectedSystemDevice(devices.groovySystem[0].device_id)
        }
      }

      if (
        selectedControlDeviceId &&
        checkIfUserHasDevice(connectedDevices, selectedControlDeviceId)
      ) {
        changeSelectedControlDevice(selectedControlDeviceId)
      } else {
        if (devices.groovyControl.length) {
          changeSelectedControlDevice(devices.groovyControl[0].device_id)
        }
      }
    }
    if (!authenticated) {
      getUserAccessToken()
    }
  }, [connectedDevices, authenticated])
  return (
    <AuthContext.Provider
      value={{
        authenticated,
        setAuthenticated,
        user,
        isLoading,
        getUserAccessToken,
        setConnectedDevices,
        connectedDevices,
        updateUser,
        selectedDeviceType,
        setSelectedDeviceType,
        changeSelectedDeviceType,
        selectedBoxDevice,
        selectedSystemDevice,
        selectedControlDevice,
        changeSelectedBoxDevice,
        changeSelectedSystemDevice,
        changeSelectedControlDevice,
        fetchConnectedDevices,
        userSettings,
        refreshConnectedDevices,
        logoutUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
