import React, { useRef, useState, useEffect } from 'react'
import './HamburgerMenu.css'
import { ReactComponent as CloseIcon } from '../../assets/CloseIcon.svg'
import { ReactComponent as BellIcon } from '../../assets/BellIcon.svg'
import { ReactComponent as OnlineStatus } from '../../assets/OnlineStatus.svg'
import { ReactComponent as OfflineStatus } from '../../assets/OfflineStatus.svg'
import { ReactComponent as SwitchDeviceIcon } from '../../assets/SwitchDeviceIcon.svg'
import { ReactComponent as SignOutIcon } from '../../assets/SignOutIcon.svg'
import { ReactComponent as DashboardIcon } from '../../assets/DashboardIcon.svg'
import MenuLink from './MenuLink'
import { NavLink } from 'react-router-dom'
import { navOptions } from '../../util/constants'
import { useAuth } from '../../hooks/useAuth'
import { classNameTypeExtractor, capitalizeFirstChar, checkIfTypeHasDevice } from '../../util/util'
import { CSSTransition } from 'react-transition-group'
import { useTranslation } from 'react-i18next'
import useWarning from '../Modal/WarningModal/WarningModal'

const HamburgerMenu = ({ deviceStatus, automationStatus, closeMenu, showMenu }) => {
  const [deviceType, setDeviceType] = useState('')
  const { selectedDeviceType, changeSelectedDeviceType, connectedDevices, logoutUser, user } =
    useAuth()
  const [getConfirmation, Warning] = useWarning('Are you sure you want to logout?', false)
  const types = ['groovyBox', 'groovySystem', 'groovyControl']
  const menuRef = useRef(null)
  const { t } = useTranslation('translations')

  useEffect(() => {
    if (selectedDeviceType) {
      setDeviceType(selectedDeviceType)
    }
  }, [selectedDeviceType])

  const changeType = (type) => {
    changeSelectedDeviceType(type)
    setDeviceType(type)
    closeMenu(false)
  }

  const handleLogoutButtonClick = async () => {
    const confirmed = await getConfirmation()
    if (confirmed) {
      logoutUser()
    }
  }

  return (
    <CSSTransition
      in={showMenu}
      timeout={{
        enter: 0,
        exit: 900,
      }}
    >
      <div
        onClick={(e) => {
          if (menuRef.current && !menuRef.current.contains(e.target)) {
            closeMenu(false)
          }
        }}
        className={`${showMenu ? 'body-background' : 'body-background-hidden'}`}
      >
        <div ref={menuRef} className={`${showMenu ? 'menu-active' : 'menu-hidden'}`}>
          <div className="menu-header">
            <div className="menu-statuses">
              <span>Status:</span>
              <div className="status-bell">
                {deviceStatus ? <OnlineStatus /> : <OfflineStatus />}
                Device
                {/* <BellIcon /> */}
              </div>
              <div className="status-bell">
                {automationStatus ? <OnlineStatus /> : <OfflineStatus />}
                Automation
                {/* <BellIcon /> */}
              </div>
            </div>
            <CloseIcon onClick={() => closeMenu(false)} className="menu-close-icon" />
          </div>
          <div className="menu-content">
            {/* <div className="remove-plant">
              <div className="close-icon">
                <CloseIcon className="menu-close-icon-blue" />
              </div>
              {t('menu.remove_plant')}
            </div> */}
            <div className="buttons-list">
              <span className="section-title">{t('menu.main_menu')}</span>
              {navOptions.map((nav, index) => {
                return (
                  <NavLink
                    className="menu-navigation-link"
                    key={nav.url + index}
                    onClick={() => closeMenu(false)}
                    to={nav.url}
                  >
                    <MenuLink title={nav.title} icon={nav.icon} />
                  </NavLink>
                )
              })}
              {user?.role === 'admin' && (
                <NavLink
                  onClick={() => closeMenu(false)}
                  key={'admin-dashboard'}
                  to="/admin-dashboard"
                >
                  <MenuLink title={'Admin'} icon={<DashboardIcon />} />
                </NavLink>
              )}
            </div>
            <div className="buttons-list">
              <span className="section-title">{t('menu.switch')}</span>
              {types.map((e, i) => {
                if (deviceType !== e) {
                  return (
                    <div
                      key={i}
                      className={`header-dropdown-item ${
                        checkIfTypeHasDevice(connectedDevices, e) ? '' : 'disabled'
                      }`}
                      onClick={() =>
                        checkIfTypeHasDevice(connectedDevices, e) ? changeType(e) : {}
                      }
                    >
                      <div
                        className={`header-dropdown-icon-container ${classNameTypeExtractor(e)} ${
                          checkIfTypeHasDevice(connectedDevices, e) ? '' : 'disabled'
                        }`}
                      >
                        <SwitchDeviceIcon />
                      </div>
                      {capitalizeFirstChar(e)}
                    </div>
                  )
                } else {
                  return ''
                }
              })}
            </div>
            <div className="buttons-list">
              <span className="section-title">{t('menu.user')}</span>
              <div className="menu-inline" onClick={handleLogoutButtonClick}>
                <SignOutIcon />
                <span>{t('menu.sign_out')}</span>
              </div>
            </div>
          </div>
        </div>
        <Warning />
      </div>
    </CSSTransition>
  )
}

export default HamburgerMenu
