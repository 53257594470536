import React, { useState } from 'react'
import {
  Container,
  Typography,
  TextField,
  MenuItem,
  Button,
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material'

const systems = [
  { value: 'GroovySystem', label: 'GroovySystem' },
  // Add more systems as needed
]

const properties = [
  { value: 'Temperature', label: 'Temperature' },
  { value: 'Humidity', label: 'Humidity' },
  // Add more properties as needed
]

const actions = [
  { value: 'turnOn', label: 'Turn On Lights' },
  { value: 'turnOff', label: 'Turn Off Lights' },
  { value: 'turnOnVent', label: 'Turn On Ventilation' },
  { value: 'turnOffVent', label: 'Turn Off Ventilation' },
  // Add more actions as needed
]

const comparators = [
  { value: 'greater', label: 'Greater Than' },
  { value: 'greaterEquals', label: 'Greater Than or Equals' },
  { value: 'equals', label: 'Equals' },
  // Add more comparators as needed
]

const RuleEngine = () => {
  const [system, setSystem] = useState('')
  const [property, setProperty] = useState('')
  const [propertyValue, setPropertyValue] = useState('')
  const [comparator, setComparator] = useState('')
  const [action, setAction] = useState('')
  const [executedRules, setExecutedRules] = useState([])

  const handleExecuteRule = () => {
    // Implement rule execution logic here
    const rule = {
      system: system,
      property: property,
      comparator: comparator,
      propertyValue: propertyValue,
      action: action,
    }
    setExecutedRules([...executedRules, rule])
    console.log(
      `If - ${system} ${property} ${comparator} ${propertyValue}% then - ${system} - ${action}`,
    )
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Create Rule
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField
            select
            fullWidth
            label="System"
            value={system}
            onChange={(e) => setSystem(e.target.value)}
          >
            {systems.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            select
            fullWidth
            label="Property"
            value={property}
            onChange={(e) => setProperty(e.target.value)}
          >
            {properties.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            select
            fullWidth
            label="Comparator"
            value={comparator}
            onChange={(e) => setComparator(e.target.value)}
          >
            {comparators.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Property Value"
            type="number"
            value={propertyValue}
            onChange={(e) => setPropertyValue(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            select
            fullWidth
            label="Action"
            value={action}
            onChange={(e) => setAction(e.target.value)}
          >
            {actions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleExecuteRule}>
            Execute Rule
          </Button>
        </Grid>
        {executedRules.length > 0 && (
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              Executed Rules
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>System</TableCell>
                    <TableCell>Property</TableCell>
                    <TableCell>Comparator</TableCell>
                    <TableCell>Property Value</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {executedRules.map((rule, index) => (
                    <TableRow key={index}>
                      <TableCell>{rule.system}</TableCell>
                      <TableCell>{rule.property}</TableCell>
                      <TableCell>{rule.comparator}</TableCell>
                      <TableCell>{rule.propertyValue}</TableCell>
                      <TableCell>{rule.action}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
    </Container>
  )
}

export default RuleEngine
