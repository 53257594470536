import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import './DefaultButton.css'
import { ReactComponent as CloseIcon } from '../../../assets/CloseIcon.svg'
import { ReactComponent as ButtonIconMore } from '../../../assets/ButtonIconMore.svg'

const DefaultButton = forwardRef(
  (
    {
      type,
      variant,
      leftIcon,
      customLeftIcon,
      rightIcon,
      customRightIcon,
      children,
      customStyle,
      loading,
      loadingText,
      ...restProps
    },
    ref,
  ) => {
    return (
      <button
        {...restProps}
        ref={ref}
        className={`${type} ${customStyle} ${variant} ${restProps.disabled ? 'disabled' : ''}`}
      >
        {loading ? (
          <div className="button-loader-wrapper">
            <div className="button-loader-animation" />
            <span>{loadingText}</span>
          </div>
        ) : (
          <>
            {leftIcon && type !== 'close-button' && customLeftIcon
              ? customLeftIcon
              : leftIcon && <ButtonIconMore />}

            {type === 'default-button' && children}

            {rightIcon && type !== 'close-button' && customRightIcon
              ? customRightIcon
              : rightIcon && <ButtonIconMore />}

            {type === 'close-button' && <CloseIcon></CloseIcon>}

            {type === 'sidebar-button' && children}
          </>
        )}
      </button>
    )
  },
)

DefaultButton.propTypes = {
  type: PropTypes.string,
  variant: PropTypes.string,
  leftIcon: PropTypes.bool,
  rightIcon: PropTypes.bool,
  customLeftIcon: PropTypes.element,
  customRightIcon: PropTypes.element,
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
}

DefaultButton.defaultProps = {
  type: 'default-button',
  variant: 'default',
  leftIcon: false,
  rightIcon: false,
  customLeftIcon: null,
  customRightIcon: null,
  loading: false,
  loadingText: 'Processing',
}
export default DefaultButton
