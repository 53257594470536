import './GrowingPlan.css'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { ReactComponent as GrowingPlanCalendar } from '../../assets/GrowingPlanCalendarIcon.svg'
import { formatDateToShortString } from '../../util/util'

const snapAndRoundPc = (val) => Math.round(Math.min(100, Math.max(0, val)))

const GrowingPlan = ({ currentDeviceSettings }) => {
  const { t } = useTranslation('translations')

  const [calculatedData, setCalculatedData] = useState()

  useEffect(() => {
    if (!currentDeviceSettings) {
      setCalculatedData()
      return
    }

    if (
      !Array.isArray(currentDeviceSettings.automation?.stages) ||
      currentDeviceSettings.automation.stages.length < 1
    ) {
      setCalculatedData()
      return
    }

    if (!currentDeviceSettings.automation_active) {
      setCalculatedData()
      return
    }

    const stages = currentDeviceSettings.automation.stages
      .map((stage) => ({ ...stage, start: new Date(stage.start), end: new Date(stage.end) }))
      .sort((a, b) => {
        return a.end.getTime() - b.end.getTime()
      })

    const firstStageStart = stages[0].start
    const lastStageEnd = stages[stages.length - 1].end

    const totalDuration = Math.abs(lastStageEnd.getTime() - firstStageStart.getTime())
    const totalDurationInDays = Math.ceil(totalDuration / (1000 * 60 * 60 * 24))

    const timeLeft = lastStageEnd.getTime() - new Date().getTime()
    const timeLeftInDays = Math.max(Math.ceil(timeLeft / (1000 * 60 * 60 * 24)), 0)

    const timeElapsed = new Date().getTime() - firstStageStart.getTime()
    const timeElapsedInDays = Math.min(
      Math.ceil(timeElapsed / (1000 * 60 * 60 * 24)),
      totalDurationInDays,
    )
    const timeElapsedPrecentage = snapAndRoundPc((timeElapsedInDays / totalDurationInDays) * 100)

    let previousStageEnd
    const mappedStages = stages.map((element, i) => {
      const endDiff = element.end.getTime() - firstStageStart.getTime()
      const daysDiff = Math.ceil(endDiff / (1000 * 60 * 60 * 24))
      const pcBased = snapAndRoundPc((daysDiff / totalDurationInDays) * 100)

      const stageDuration = element.end.getTime() - (previousStageEnd ?? element.start).getTime()
      const stageDurationDays = Math.ceil(stageDuration / (1000 * 60 * 60 * 24))
      const stageDurationPrecentage = snapAndRoundPc(
        (stageDurationDays / totalDurationInDays) * 100,
      )
      previousStageEnd = element.end

      return {
        ...element,
        startOffsetInDays: daysDiff,
        endsAtPrecentage: pcBased,
        stageDurationInDays: stageDurationDays,
        stageDurationPrecentage: stageDurationPrecentage,
      }
    })

    setCalculatedData({
      firstStageStart,
      lastStageEnd,
      timeLeftInDays,
      totalDurationInDays,
      timeElapsedPrecentage,
      stages: mappedStages,
    })
  }, [currentDeviceSettings])

  const growingDataLayout = [
    { name: t('growingPlan.days_overall'), value: calculatedData?.totalDurationInDays },
    { name: t('growingPlan.days_left'), value: calculatedData?.timeLeftInDays },
    {
      name: t('growingPlan.expected_harvest'),
      value: formatDateToShortString(calculatedData?.lastStageEnd, { year: true }),
    },
    {
      name: t('growingPlan.current_automation'),
      value: currentDeviceSettings?.automation?.name ?? '-',
    },
    {
      name: t('growingPlan.current_stage'),
      value: currentDeviceSettings?.automation?.active_stage_name ?? '-',
    },
  ]

  if (!calculatedData) {
    return <></>
  }

  return (
    <div className="growing-plan-container">
      <div className="growing-plan-first-row">
        <div className="growing-plan-first-row-title">
          <GrowingPlanCalendar />
          {'Automation plan / Full Yield Expectation'}
        </div>
        <hr className="growing-plan-divider-line" />
      </div>
      <div className="growing-plan-second-row">
        {growingDataLayout.map((dataSet, index) => (
          <div className="growing-plan-second-row-text-wrapper" key={index}>
            <div className="growing-plan-second-row-main-text">{`${dataSet.name}: \u00a0`}</div>
            <div className="growing-plan-second-row-secondary-text">{dataSet.value}</div>
          </div>
        ))}
      </div>
      <div className="growing-plan-timeline-custom">
        <div
          className="growing-plan-timeline-completed"
          style={{
            width: `${calculatedData.timeElapsedPrecentage}%`,
          }}
        ></div>
        {calculatedData.stages.map((stage) => (
          <div
            style={{ width: `${stage.stageDurationPrecentage}%` }}
            key={stage.stage_id}
            className="growing-plan-timeline-item"
          ></div>
        ))}
      </div>
    </div>
  )
}

GrowingPlan.propTypes = {
  currentDeviceSettings: PropTypes.shape({
    automation_active: PropTypes.bool.isRequired,
    automation_id: PropTypes.number,
    device_id: PropTypes.number.isRequired,
    enabled: PropTypes.bool.isRequired,
    mac_address: PropTypes.string.isRequired,
    user_id: PropTypes.number.isRequired,
    automation: PropTypes.shape({
      name: PropTypes.string.isRequired,
      active_stage_name: PropTypes.string,
      stages: PropTypes.arrayOf(
        PropTypes.shape({
          automation_id: PropTypes.number.isRequired,
          end: PropTypes.string.isRequired,
          moisture_value: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          stage_id: PropTypes.number.isRequired,
          start: PropTypes.string.isRequired,
          temperature_max: PropTypes.string.isRequired,
          temperature_min: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  }).isRequired,
}

export default GrowingPlan
