import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as MoistureIndicator } from '../../../assets/MoistureIndicator.svg'
import { ReactComponent as SoilMoistureImage } from '../../../assets/SoilMoistureImage.svg'
import { ReactComponent as PhIndicator } from '../../../assets/PhIndicator.svg'
import { ReactComponent as SoilPhImage } from '../../../assets/SoilPhImage.svg'
import { ReactComponent as Temperature } from '../../../assets/Temperature.svg'
import { ReactComponent as IndicatorsIcon } from '../../../assets/IndicatorsIcon.svg'
import { ReactComponent as AirMoisture } from '../../../assets/AirMoisture.svg'
import { ReactComponent as SensorCalibrationIcon } from '../../../assets/SensorCalibrationIcon.svg'
import DataService from '../../../data/DataService'
import './DashboardIndicators.css'
import { renderSensorIndicators } from '../../../util/util'
import DefaultButton from '../../../components/DefaultComponents/DefaultButton/DefaultButton'
import ModalHelper from '../../../components/Modal/ModalHelper'
import { useDevice } from '../../../hooks/useDevice'
import { CircularProgress } from '@mui/material'

const SystemIndicators = ({ device_id, device_address, handleRefresh }) => {
  const { t } = useTranslation('translations')
  const [deviceModuleStatus, setDeviceModuleStatus] = useState([])
  const [showCalibration, setShowCalibration] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [connectionStatus, setConnectionStatus] = useState(true)

  const { deviceStatus, isLoadingStatus: isDeviceLoading } = useDevice()

  useEffect(() => {
    function changeStatus() {
      setConnectionStatus(navigator.onLine)
    }
    window.addEventListener('online', changeStatus)
    window.addEventListener('offline', changeStatus)
    return () => {
      window.removeEventListener('online', changeStatus)
      window.removeEventListener('offline', changeStatus)
    }
  }, [])

  useEffect(() => {
    if (device_id) {
      getModuleStatus(device_id)
      const interval = setInterval(async () => getModuleStatus(device_id), 5000)
      return () => clearInterval(interval)
    }
  }, [device_id])

  const getModuleStatus = async (device_id) => {
    const deviceModules = await DataService.getModuleDataForDevice(device_id)

    setDeviceModuleStatus(deviceModules.data)
  }

  const renderIndicatorIcon = (indicator) => {
    switch (indicator) {
      case 'soil_moisture_sensor':
        return (
          <div className="indicator-card-icon-wrapper">
            <MoistureIndicator />
            <SoilMoistureImage />
          </div>
        )
      case 'air_moisture_sensor':
        return <AirMoisture />
      case 'soil_acidity_sensor':
        return (
          <div className="indicator-card-icon-wrapper">
            <PhIndicator />
            <SoilPhImage />
          </div>
        )
      case 'air_temperature_sensor':
        return <Temperature />
      default:
        return ''
    }
  }

  const getInstantModuleData = async (device_address) => {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 2900)
    await DataService.sendActionRequest({
      payload: 'D',
      device_type: 'GroovySystem',
      device_mac_address: device_address,
      action_name: 'InstantData',
    })
  }

  const handleRefreshButton = () => {
    getInstantModuleData(device_address)
    handleRefresh()
  }

  return (
    <div>
      <div className="indicator-header">
        <div className="indicator-title">
          <IndicatorsIcon />
          {t('dashboard.indicators.title')}
        </div>
        <hr className="indicator-divider-line" />
      </div>
      <div className="indicator-container-splitter">
        {isDeviceLoading ? (
          <CircularProgress sx={{ mx: 'auto' }} color="success" />
        ) : (
          renderSensorIndicators(
            deviceModuleStatus,
            deviceStatus,
            (indicator) => renderIndicatorIcon(indicator),
            t,
            connectionStatus,
          )
        )}
      </div>
      {showCalibration && (
        <ModalHelper
          deviceType={'GroovySystem'}
          onClose={() => setShowCalibration(false)}
          type={'calibration'}
        />
      )}
      <div className="inline-btns">
        {/* <DefaultButton
          leftIcon
          customLeftIcon={<SensorCalibrationIcon />}
          customStyle={'dashboard-sensor-calibration'}
          onClick={() => setShowCalibration(true)}
        >
          {t('dashboard.indicators.sensor_calibration')}
        </DefaultButton> */}
        <DefaultButton
          loading={isLoading}
          loadingText={t('dashboard.indicators.refresh_loader')}
          onClick={handleRefreshButton}
        >
          {t('dashboard.indicators.refresh_data')}
        </DefaultButton>
      </div>
    </div>
  )
}

export default SystemIndicators
