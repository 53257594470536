import React from 'react'
import { useTranslation } from 'react-i18next'
import { DefaultButton } from '../../DefaultComponents'
import { ReactComponent as CustomAutomationIcon } from '../../../assets/CustomAutomationIcon.svg'
import { ReactComponent as PredefinedAutomationIcon } from '../../../assets/PredefinedAutomationIcon.svg'

const ChooseAutomationType = ({ setCustomAutomation, setPredefinedAutomation }) => {
  const { t } = useTranslation('translations')
  return (
    <div>
      <div className="add-plant-input-container">
        <div className="add-plant-text">{t('automations.steps.third_step')}</div>
        <input className="add-plant-input" type="text" />
      </div>
      <div className="add-plant-input-container">
        <div className="checkbox-option">
          <input type="checkbox" />
          <label className="add-plant-normal-text">{t('automations.my_plans')}</label>
          <input type="checkbox" />
          <label className="add-plant-normal-text">{t('automations.predefined')}</label>
        </div>
        <div className="add-plant-normal-text">{t('automations.choose_automation_text')}</div>
        <div className="automation-btns">
          <DefaultButton
            onClick={() => setCustomAutomation(true)}
            leftIcon
            customLeftIcon={<CustomAutomationIcon />}
          >
            {t('automations.set_custom_automation')}
          </DefaultButton>
          <DefaultButton
            onClick={() => setPredefinedAutomation(true)}
            leftIcon
            customLeftIcon={<PredefinedAutomationIcon />}
          >
            {t('automations.set_predefined_automation')}
          </DefaultButton>
        </div>
      </div>
    </div>
  )
}

export default ChooseAutomationType
