import React, { useState, useEffect, useRef } from 'react'
import './DeviceSelector.css'
import { ReactComponent as SelectorIcon } from '../../assets/SelectorIcon.svg'
import PropTypes from 'prop-types'

const DeviceSelector = ({ onSelect, options, value }) => {
  const [active, setActive] = useState(false)
  const dropdownButtonRef = useRef(null)

  useEffect(() => {
    const closeOpenMenus = (e) => {
      if (dropdownButtonRef.current && active && !dropdownButtonRef.current.contains(e.target)) {
        setActive(false)
      }
    }

    document.addEventListener('mousedown', closeOpenMenus)

    return () => {
      document.removeEventListener('mousedown', closeOpenMenus)
    }
  }, [active])

  return (
    <div className="device-selector-container" ref={dropdownButtonRef}>
      <div className="device-selector-wrapper" onClick={() => setActive(!active)}>
        <div className={`device-selector-icon-container ${active ? 'active' : ''}`}>
          <SelectorIcon />
        </div>
        <div className="device-selector-text">
          {options.find((o) => o.value === value)
            ? options.find((o) => o.value === value).name
            : ''}
        </div>
      </div>
      <div className={`device-selector-item-container ${active ? 'active' : ''}`}>
        {options.map((e, i) => (
          <div
            key={i}
            className={`device-selector-item ${value === e?.value ? 'selected' : ''}`}
            onClick={() => {
              onSelect(e?.value)
              setActive(false)
            }}
          >
            {e?.name || '\u00a0'}
          </div>
        ))}
      </div>
    </div>
  )
}

DeviceSelector.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onSelect: PropTypes.func.isRequired,
}

DeviceSelector.defaultProps = {
  options: [{ value: 1, name: 'My tickets' }],
  value: 1,
}

export default DeviceSelector
