import React, { useState, useEffect, useRef } from 'react'
import './Header.css'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ReactComponent as MenuBurger } from '../../assets/MenuBurger.svg'
import { ReactComponent as AddIcon } from '../../assets/AddIcon.svg'
import { ReactComponent as OnlineStatus } from '../../assets/OnlineStatus.svg'
import { ReactComponent as OfflineStatus } from '../../assets/OfflineStatus.svg'
import { ReactComponent as BellIcon } from '../../assets/BellIcon.svg'
import { ReactComponent as SwitchDeviceIcon } from '../../assets/SwitchDeviceIcon.svg'
import { ReactComponent as SignOutIcon } from '../../assets/SignOutIcon.svg'
import { useAuth } from '../../hooks/useAuth'
import defaultGrower from '../../assets/defaultGrower.jpg'
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu'
import {
  classNameTypeExtractor,
  capitalizeFirstChar,
  checkIfTypeHasDevice,
  getSelectedDeviceByType,
} from '../../util/util'
import ModalHelper from '../Modal/ModalHelper'
import { useDevice } from '../../hooks/useDevice'
import useWarning from '../Modal/WarningModal/WarningModal'

const Header = ({ avatar, classModifier }) => {
  const [deviceType, setDeviceType] = useState('')
  const [active, setActive] = useState(false)
  const {
    user,
    selectedDeviceType,
    changeSelectedDeviceType,
    selectedBoxDevice,
    selectedSystemDevice,
    selectedControlDevice,
    connectedDevices,
    logoutUser,
  } = useAuth()
  const { t } = useTranslation('translations')
  const [showMenu, setShowMenu] = useState(false)
  // const [addNewPlant, setAddNewPlant] = useState(false)
  const [getConfirmation, Warning] = useWarning('Are you sure you want to logout?', false)

  const { deviceStatus, automationStatus } = useDevice()

  const device_id = getSelectedDeviceByType(
    selectedBoxDevice,
    selectedSystemDevice,
    selectedControlDevice,
    selectedDeviceType,
  )

  const closeMenu = (show) => {
    setShowMenu(show)
  }
  const dropdownRef = useRef(null)
  const dropdownItems = ['groovyBox', 'groovySystem', 'groovyControl']

  useEffect(() => {
    if (selectedDeviceType) {
      setDeviceType(selectedDeviceType)
    }

    const handleClickOutside = (event) => {
      if (active && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActive(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [selectedDeviceType, active, device_id])

  const changeType = (type) => {
    changeSelectedDeviceType(type)
    setDeviceType(type)
    setActive(false)
  }

  const handleLogoutButtonClick = async () => {
    const confirmed = await getConfirmation()
    if (confirmed) {
      logoutUser()
    }
  }

  return (
    <div className={`header ${classModifier || ''}`}>
      <div className="header-profile">
        <div className="header-avatar-container">
          <img className="header-avatar" alt="groovy" src={user?.image_url || avatar}></img>
        </div>
        <div className="header-info-box">
          <div className="header-info-greeting">{t('dashboard.header.greeting')}</div>
          <div className="header-info-user-name">{user?.nickname || 'Groover'}</div>
        </div>
      </div>
      <div className="header-middle-section">
        {/* <div onClick={() => setAddNewPlant(true)} className="header-add-plant-container">
          <div className="header-add-plant-button">
            <div className="header-add-plant-button-icon">
              <AddIcon />
            </div>
            {t('dashboard.header.add_plant')}
          </div>
        </div> */}
        <div>
          {selectedDeviceType !== 'groovyControl' && (
            <div className="header-status-inline">
              <div className="header-status-type">
                <div className="header-status-container">
                  {deviceStatus ? <OnlineStatus /> : <OfflineStatus />}
                </div>
                <div className="header-device-status-container">
                  <div className="header-device-status-text">
                    {t('dashboard.header.device_status')}
                  </div>
                  {deviceStatus ? t('dashboard.header.online') : t('dashboard.header.offline')}
                </div>
              </div>
              <div className="header-status-type">
                <div className="header-status-container">
                  {automationStatus ? <OnlineStatus /> : <OfflineStatus />}
                </div>
                <div className="header-device-status-container">
                  <div className="header-device-status-text">
                    {t('dashboard.header.automation_status')}
                  </div>
                  {automationStatus ? t('dashboard.header.active') : t('dashboard.header.inactive')}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="header-actions-container">
        {/* <div className="header-actions-icon">
          <BellIcon />
        </div> */}
        <div className="header-dropdown-container">
          <div className="header-actions-icon" onClick={() => setActive(!active)}>
            <SwitchDeviceIcon />
          </div>
          <div
            ref={dropdownRef}
            className={`header-dropdown-item-container ${active ? 'active' : ''}`}
          >
            {dropdownItems.map((e, i) => {
              if (deviceType !== e) {
                return (
                  <div
                    key={i}
                    className={`header-dropdown-item ${
                      checkIfTypeHasDevice(connectedDevices, e) ? '' : 'disabled'
                    }`}
                    onClick={() => (checkIfTypeHasDevice(connectedDevices, e) ? changeType(e) : {})}
                  >
                    <div
                      className={`header-dropdown-icon-container ${classNameTypeExtractor(e)} ${
                        checkIfTypeHasDevice(connectedDevices, e) ? '' : 'disabled'
                      }`}
                    >
                      <SwitchDeviceIcon />
                    </div>
                    {capitalizeFirstChar(e)}
                  </div>
                )
              } else {
                return ''
              }
            })}
          </div>
        </div>
        <div onClick={handleLogoutButtonClick} className="header-actions-icon">
          <SignOutIcon />
        </div>
      </div>
      <div className="header-menu-burger-container">
        <div className="header-menu-burger" onClick={() => setShowMenu((showMenu) => !showMenu)}>
          <MenuBurger />
        </div>
        <HamburgerMenu
          deviceStatus={deviceStatus}
          automationStatus={automationStatus}
          changeType={changeType}
          closeMenu={closeMenu}
          showMenu={showMenu}
        />
      </div>
      <Warning />
      {/* {addNewPlant && <ModalHelper type={'newPlant'} onClose={() => setAddNewPlant(false)} />} */}
    </div>
  )
}

Header.propTypes = {
  avatar: PropTypes.any,
}

Header.defaultProps = {
  avatar: defaultGrower,
}

export default Header
