import { useEffect, useState } from 'react'
import SSXModal from '../../Modal/SSXModal/SSXModal'
import SSXCheckbox from '../../UI/SSXCheckbox/SSXCheckbox'
import { DefaultButton } from '../../DefaultComponents'
import { ReactComponent as EditIcon } from '../../../assets/EditIcon.svg'
import { ReactComponent as CheckmarkIconCircle } from '../../../assets/CheckmarkIconCircle.svg'
import DataService from '../../../data/DataService'
import { useNotifications } from '../../../hooks/useNotification'
import PropTypes from 'prop-types'

const StageTemplateModalV2 = ({ onClose, onLoad, onEdit }) => {
  const [stageTemplates, setStageTemplates] = useState([])
  const [selectedTemplate, setSelectedTemplate] = useState()
  const [loading, setLoading] = useState(true)

  const { addErrorNotification } = useNotifications()

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      try {
        const templates = await DataService.getStageTemplates()
        setStageTemplates(templates)
      } catch (error) {
        addErrorNotification('Failed to load stage templates')
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  return (
    <SSXModal onClose={onClose} header={'Select a stage template'} modalLoading={loading}>
      <div className="stage-setup-form">
        {stageTemplates.map((st) => (
          <div
            key={st.stage_template_id}
            style={{
              display: 'inline-flex',
            }}
          >
            <SSXCheckbox
              onChange={(e) => {
                setSelectedTemplate(st)
              }}
              checked={selectedTemplate?.stage_template_id === st.stage_template_id}
              type={'radio'}
              value={st.stage_template_id}
            >
              {st.name}
            </SSXCheckbox>
          </div>
        ))}
        <div className="inline-btns">
          <DefaultButton
            leftIcon
            disabled={!selectedTemplate}
            customLeftIcon={
              <div className="stage-template-modal-check-icon">
                <CheckmarkIconCircle />
              </div>
            }
            onClick={() => {
              onLoad(selectedTemplate)
            }}
          >
            {'Load Template'}
          </DefaultButton>
          <DefaultButton
            leftIcon
            disabled={!selectedTemplate}
            customLeftIcon={
              <div className="stage-template-modal-edit-icon">
                <EditIcon />
              </div>
            }
            onClick={() => {
              onEdit(selectedTemplate)
            }}
          >
            {'Edit'}
          </DefaultButton>
        </div>
      </div>
    </SSXModal>
  )
}

StageTemplateModalV2.propTypes = {
  onClose: PropTypes.func,
  onLoad: PropTypes.func,
  onEdit: PropTypes.func,
}

StageTemplateModalV2.defaultProps = {
  onClose: () => {},
  onLoad: () => {},
  onEdit: () => {},
}

export default StageTemplateModalV2
