import React, { useState, useRef } from 'react'
import { DefaultButton } from '../../DefaultComponents'
import { useTranslation } from 'react-i18next'
import './WarningModal.css'

const useWarning = (title, text, showText) => {
  const { t } = useTranslation('translations')
  const [open, setOpen] = useState(false)
  const resolver = useRef()

  const getConfirmation = async () => {
    setOpen(true)
    return new Promise((resolve) => {
      resolver.current = resolve
    })
  }

  const onClick = async (status) => {
    setOpen(false)
    resolver.current(status)
  }

  const Warning = () =>
    open ? (
      <div className="warning-modal-container">
        <div className="warning-modal-content">
          <div className="warning-modal-content-title">
            {title ? title : t('modals.warning.title')}
          </div>
          {showText && (
            <div className="warning-modal-content-text">
              {text ? text : t('modals.warning.text')}
            </div>
          )}
          <div className="warning-modal-button-row">
            <DefaultButton
              onClick={() => onClick(true)}
              variant={'green'}
              customStyle={'warning-button'}
            >
              {t('modals.settings.yes')}
            </DefaultButton>
            <DefaultButton
              onClick={() => onClick(false)}
              variant={'red'}
              customStyle={'warning-button'}
            >
              {t('modals.settings.no')}
            </DefaultButton>
          </div>
        </div>
      </div>
    ) : null

  return [getConfirmation, Warning]
}

export default useWarning
