import React from 'react'
import PropTypes from 'prop-types'
import './SSXCheckbox.css'

const SSXCheckbox = ({ type, fill, children, ...restProps }) => {
  const selectedClass = `ssx-checkbox${type === 'radio' ? ' radio' : ''}`

  return (
    <label className={selectedClass}>
      {children}
      <input type={type} {...restProps} />
      <span className="checkmark"></span>
    </label>
  )
}

SSXCheckbox.propTypes = {
  type: PropTypes.string,
}

SSXCheckbox.defaultProps = {
  type: 'checkbox',
  fill: 'checkmark',
}

export default SSXCheckbox
