import PropTypes from 'prop-types'
import './ElementLoader.css'

const ElementLoader = ({ children, loading, extraStyle }) => {
  return (
    <div style={extraStyle} className={`element-loader ${loading ? 'loading' : ''}`.trim()}>
      {children}
    </div>
  )
}

ElementLoader.propTypes = {
  loading: PropTypes.bool,
  extraStyle: PropTypes.object,
}

ElementLoader.defaultProps = {
  loading: false,
  extraStyle: {},
}

export default ElementLoader
