import React from 'react'
import './DefaultProgressIndicator.css'

const DefaultProgressIndicator = ({ children, classModifier, value, ...restProps }) => {
  return (
    <div className={`default-progress-indicator-container ${classModifier || ''}`} {...restProps}>
      <div
        style={{ width: `${Math.abs(value) > 100 ? 100 : Math.abs(value)}%` }}
        className="default-progress-indicator-track"
      />
    </div>
  )
}

export default DefaultProgressIndicator
