import React, { useEffect, useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  TablePagination,
} from '@mui/material'
import DataService from '../../data/DataService'
import { ReactComponent as OnlineStatus } from '../../assets/OnlineStatus.svg'
import { ReactComponent as OfflineStatus } from '../../assets/OfflineStatus.svg'
import ModalHelper from '../../components/Modal/ModalHelper'
import './AdminDashboard.css'

const DeviceList = () => {
  const [devices, setDevices] = useState([])
  const [sortBy, setSortBy] = useState(null)
  const [sortOrder, setSortOrder] = useState('asc')
  const [showModal, setShowModal] = useState(false)
  const [deviceData, setDeviceData] = useState({})
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const fetchData = async () => {
    try {
      const devicesData = await DataService.getAllDevices()
      const automationsData = await DataService.getAllAutomations()
      const data = devicesData.map((device) => {
        const automationFound = automationsData.find((a) =>
          a.devices.find((d) => d.device_id === device.device_id),
        )
        return {
          ...device,
          automation: automationFound || null,
        }
      })
      setDevices(data)
    } catch (error) {
      console.error('Error fetching devices:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleSort = (property) => {
    const isAscending = sortBy === property && sortOrder === 'asc'
    setSortBy(property)
    setSortOrder(isAscending ? 'desc' : 'asc')
  }

  const sortedDevices = [...devices].sort((a, b) => {
    if (sortBy === null) return 0

    let aValue = a[sortBy]
    let bValue = b[sortBy]
    if (sortBy === 'automation.automation.name') {
      aValue = a?.automation?.automation?.name
      bValue = b?.automation?.automation?.name
    }

    if (aValue == null && bValue == null) return 0
    if (aValue == null) return sortOrder === 'asc' ? -1 : 1
    if (bValue == null) return sortOrder === 'asc' ? 1 : -1

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      const comparison = aValue.localeCompare(bValue)
      return sortOrder === 'asc' ? comparison : -comparison
    } else if (typeof aValue === 'number' && typeof bValue === 'number') {
      const comparison = aValue - bValue
      return sortOrder === 'asc' ? comparison : -comparison
    }

    const comparison = aValue.toString().localeCompare(bValue.toString())
    return sortOrder === 'asc' ? comparison : -comparison
  })

  const handleRowClick = (device) => {
    setDeviceData(device)
    setShowModal(true)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <div className="admin-dashboard">
      <h1>Device List</h1>
      <TableContainer component={Paper}>
        <Table aria-label="device table">
          <TableHead>
            <TableRow>
              <TableCell width={88}>
                <TableSortLabel
                  active={sortBy === 'device_id'}
                  direction={sortBy === 'device_id' ? sortOrder : 'asc'}
                  onClick={() => handleSort('device_id')}
                >
                  Device ID
                </TableSortLabel>
              </TableCell>
              <TableCell width={80}>
                <TableSortLabel
                  active={sortBy === 'user_id'}
                  direction={sortBy === 'user_id' ? sortOrder : 'asc'}
                  onClick={() => handleSort('user_id')}
                >
                  User ID
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'name'}
                  direction={sortBy === 'name' ? sortOrder : 'asc'}
                  onClick={() => handleSort('name')}
                >
                  Device name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'device_type_name'}
                  direction={sortBy === 'device_type_name' ? sortOrder : 'asc'}
                  onClick={() => handleSort('device_type_name')}
                >
                  Device type
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'automation.automation.name'}
                  direction={sortBy === 'automation.automation.name' ? sortOrder : 'asc'}
                  onClick={() => handleSort('automation.automation.name')}
                >
                  Automation
                </TableSortLabel>
              </TableCell>
              <TableCell width={50}>
                <TableSortLabel
                  active={sortBy === 'device_status_id'}
                  direction={sortBy === 'device_status_id' ? sortOrder : 'asc'}
                  onClick={() => handleSort('device_status_id')}
                >
                  Status
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedDevices
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((device) => (
                <TableRow
                  key={device.device_id}
                  className="table_row_style"
                  onClick={() => handleRowClick(device)}
                >
                  <TableCell>{device?.device_id}</TableCell>
                  <TableCell>{device?.user_id}</TableCell>
                  <TableCell>{device?.name}</TableCell>
                  <TableCell>{device?.device_type_name}</TableCell>
                  <TableCell>
                    {device?.automation?.automation?.name ? (
                      <div>
                        <span className="table-automation-status">
                          {!device.automation_active ? <OfflineStatus /> : <OnlineStatus />}
                        </span>
                        {device?.automation?.automation?.name}
                      </div>
                    ) : (
                      ''
                    )}
                  </TableCell>
                  <TableCell>
                    {device.device_status_id === 1 ? <OfflineStatus /> : <OnlineStatus />}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={sortedDevices.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {showModal && (
        <ModalHelper type="admin" data={deviceData} onClose={() => setShowModal(false)} />
      )}
    </div>
  )
}

export default DeviceList
