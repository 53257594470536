/* eslint-disable no-console */
/* eslint-disable no-useless-catch */
import axios from 'axios'
import { authBaseUrl, befeBaseUrl, mailerBaseUrl } from '../util/constants'
// const authBaseUrl = 'http://localhost:3001'
// const befeBaseUrl = 'http://localhost:3001'
const DataService = {
  loginUser: async (email, password) => {
    try {
      const response = await axios.post(
        `${authBaseUrl}/auth/login`,
        {
          email,
          password,
        },
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      return response.data
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  logoutUser: async () => {
    try {
      const response = await axios.get(`${authBaseUrl}/auth/logout`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      if (response.status === 200) {
      }
      return response.status
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  registerUser: async (email, password, recaptcha) => {
    try {
      const response = await axios.post(
        `${authBaseUrl}/auth/register`,
        {
          email,
          password,
        },
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            Authorization: recaptcha,
          },
        },
      )
      return response.data
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  checkDeviceConnection: async (email) => {
    try {
      const response = await axios.post(
        `${befeBaseUrl}/devices/check-connection`,
        {
          email,
        },
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      return response.data
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  signInSSO: async (token) => {
    try {
      const response = await axios.post(
        `${authBaseUrl}/auth/sign-in-sso`,
        {
          token,
        },
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      return response.data
    } catch (error) {
      console.log('ERROR', error)
    }
  },

  allDevicesUser: async (email) => {
    try {
      const response = await axios.get(`${befeBaseUrl}/devices/${email}`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.data
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  userRole: async (userId) => {
    try {
      const response = await axios.get(`${befeBaseUrl}/user/role/${userId}`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.data
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  connectedModulesForDevice: async (id) => {
    try {
      const response = await axios.get(`${befeBaseUrl}/devices/check-modules-connected/${id}`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.data
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  getDailyAverageModuleDataForDevice: async (id) => {
    try {
      const response = await axios.get(
        `${befeBaseUrl}/devices/daily-average-module-active-data/${id}`,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      return response.data
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  getModuleDataForDevice: async (id) => {
    try {
      const response = await axios.get(`${befeBaseUrl}/devices/module-active-data/${id}`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.data
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  getAutomations: async (payload) => {
    try {
      const response = await axios.post(`${befeBaseUrl}/automation/list`, payload, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })

      return response.data
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  getAllAutomations: async () => {
    try {
      const response = await axios.post(
        `${befeBaseUrl}/automation/all`,
        {},
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )

      return response.data
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  getAllDiariesForDevice: async (payload) => {
    try {
      const response = await axios.post(`${befeBaseUrl}/diary-for-device`, payload, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.data
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  removeDiary: async (diaryId) => {
    try {
      const response = await axios.delete(`${befeBaseUrl}/diary/${diaryId}`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.status
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  saveDiary: async (payload) => {
    try {
      const response = await axios.post(`${befeBaseUrl}/diary`, payload, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.status
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  updateDiary: async (diaryId, payload) => {
    try {
      const response = await axios.put(`${befeBaseUrl}/diary/${diaryId}`, payload, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.status
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  handleTicketSubmit: async (subject, message, userObject, setLoadingData, setSentStatus) => {
    setLoadingData(true)
    setSentStatus('')

    try {
      const response = await axios.post(
        `${mailerBaseUrl}/support/createTicket`,
        {
          subject: subject,
          content: message,
          email: userObject.email,
          full_name: '',
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        },
      )

      if (!response.status === 200) {
        setLoadingData(false)
        setSentStatus('fail')
        throw new Error(`HTTP error! Status: ${response.status}`)
      }

      setSentStatus('success')
      setLoadingData(false)
    } catch (error) {
      console.error(error)
    }
  },

  getAutomation: async (automationId) => {
    try {
      const response = await axios.get(`${befeBaseUrl}/automation/${automationId}`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.data
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  getDeviceAutomationStatus: async (deviceId) => {
    try {
      const response = await axios.get(`${befeBaseUrl}/devices/automation-status/${deviceId}`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.data
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  addAutomation: async (payload) => {
    try {
      const response = await axios.post(`${befeBaseUrl}/automation`, payload, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.data
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  updateAutomation: async (payload, automationId) => {
    try {
      const response = await axios.put(`${befeBaseUrl}/automation/${automationId}`, payload, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.status
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  removeAutomation: async (automationId) => {
    try {
      const response = await axios.delete(`${befeBaseUrl}/automation/${automationId}`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.status
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  updateDeviceAutomation: async (payload) => {
    try {
      const response = await axios.post(
        `${befeBaseUrl}/automation/update-device-automation`,
        payload,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      return response.data
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  getStage: async (stageId) => {
    try {
      const response = await axios.get(`${befeBaseUrl}/stage/${stageId}`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.data
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  addStage: async (payload) => {
    try {
      const response = await axios.post(`${befeBaseUrl}/stage`, payload, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.status
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  getStagesByAutomation: async (payload) => {
    try {
      const response = await axios.post(`${befeBaseUrl}/stage/list`, payload, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.data
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  deleteStage: async (stageId) => {
    try {
      const response = await axios.delete(`${befeBaseUrl}/stage/${stageId}`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.status
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  updateStage: async (payload, stageId) => {
    try {
      const response = await axios.put(`${befeBaseUrl}/stage/${stageId}`, payload, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.status
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  updateStageWithLightIntervals: async (payload, stageId) => {
    try {
      const response = await axios.post(`${befeBaseUrl}/stage/${stageId}`, payload, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.status
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  getStageTemplate: async (stageId) => {
    try {
      const response = await axios.get(`${befeBaseUrl}/stage-template/${stageId}`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.data
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  addStageTemplate: async (payload) => {
    try {
      const response = await axios.post(`${befeBaseUrl}/stage-template`, payload, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.status
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  getStageTemplatesByAutomation: async (payload) => {
    try {
      const response = await axios.post(`${befeBaseUrl}/stage-template/list`, payload, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.data
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  getStageTemplates: async () => {
    try {
      const response = await axios.post(`${befeBaseUrl}/stage-template/list`, undefined, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.data
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  deleteStageTemplate: async (stageId) => {
    try {
      const response = await axios.delete(`${befeBaseUrl}/stage-template/${stageId}`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.status
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  updateStageTemplate: async (payload, stageTemplateId) => {
    try {
      const response = await axios.put(
        `${befeBaseUrl}/stage-template/${stageTemplateId}`,
        payload,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      return response.status
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  checkCalibrationStatus: async (payload) => {
    try {
      const response = await axios.post(`${befeBaseUrl}/devices/checkCalibrationStatus`, payload, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.data
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  checkDeviceStatus: async (id) => {
    try {
      const response = await axios.get(`${befeBaseUrl}/devices/device-status/${id}`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.data
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  getLightIntervalsByStage: async (payload) => {
    try {
      const response = await axios.get(
        `${befeBaseUrl}/light-interval/list/${payload.automation_id}&${payload.stage_id}`,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      return response.data
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  addLightInterval: async (payload) => {
    try {
      const response = await axios.post(`${befeBaseUrl}/light-interval`, payload, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.status
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  deleteLightInterval: async (lightIntervalId) => {
    try {
      const response = await axios.delete(`${befeBaseUrl}/light-interval/${lightIntervalId}`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.status
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  updateLightInterval: async (payload, lightIntervalId) => {
    try {
      const response = await axios.put(
        `${befeBaseUrl}/light-interval/${lightIntervalId}`,
        payload,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      return response.status
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  getLightIntervalTemplateByStageTemplate: async (payload) => {
    try {
      const response = await axios.post(`${befeBaseUrl}/light-interval-template/list`, payload, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.data
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  addLightIntervalTemplate: async (payload) => {
    try {
      const response = await axios.post(`${befeBaseUrl}/light-interval-template`, payload, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.status
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  deleteLightIntervalTemplate: async (lightIntervalId) => {
    try {
      const response = await axios.delete(
        `${befeBaseUrl}/light-interval-template/${lightIntervalId}`,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      return response.status
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  updateLightIntervalTemplate: async (payload, lightIntervalId) => {
    try {
      const response = await axios.put(
        `${befeBaseUrl}/light-interval-template/${lightIntervalId}`,
        payload,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      return response.status
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  sendActionRequest: async (payload) => {
    try {
      const response = await axios.post(`${befeBaseUrl}/devices/send-action-request`, payload, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.data
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  getDeviceSettings: async (id) => {
    try {
      const response = await axios.get(`${befeBaseUrl}/devices/settings/${id}`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.data
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  getDeviceCurrentSettings: async (deviceId) => {
    try {
      const response = await axios.get(`${befeBaseUrl}/devices/current-data/${deviceId}`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.data
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  removeDevice: async (deviceId) => {
    try {
      const response = await axios.delete(`${befeBaseUrl}/device/${deviceId}`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.status
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  updateDeviceSettings: async (payload, deviceId) => {
    try {
      const response = await axios.put(`${befeBaseUrl}/device/${deviceId}`, payload, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.status
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  updateDeviceValue: async (payload, deviceId) => {
    try {
      const response = await axios.put(`${befeBaseUrl}/devices/${deviceId}`, payload, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.status
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  updateUserColumn: async (payload, userId) => {
    try {
      const response = await axios.put(`${befeBaseUrl}/user/${userId}`, payload, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.status
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  uploadProfileImage: async (payload, userId) => {
    try {
      const response = await axios.post(`${befeBaseUrl}/user/upload-picture/${userId}`, payload, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      return response.status
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  getProfilePicture: async (userId) => {
    try {
      const response = await axios.get(`${befeBaseUrl}/user/${userId}`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.data
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  getModuleAverageData: async (payload) => {
    try {
      const response = await axios.post(`${befeBaseUrl}/devices/module-average-data`, payload, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.data
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  getModuleMedianData: async (payload) => {
    try {
      const response = await axios.post(`${befeBaseUrl}/devices/module-median-data`, payload, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.data
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  getDeviceModules: async (id) => {
    try {
      const response = await axios.get(`${befeBaseUrl}/devices/modules/${id}`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.data
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  updateAutomationStatus: async (automationId, status, deviceType) => {
    try {
      const response = await axios.put(
        `${befeBaseUrl}/automation/${automationId}/change-status`,
        {
          status,
          device_type: deviceType,
        },
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      return response.status
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  searchPlants: async (searchString) => {
    try {
      const response = await axios.get(`${befeBaseUrl}/plants/`, {
        params: {
          search: searchString,
        },
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.data
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  getAllDevices: async (searchString) => {
    try {
      const response = await axios.get(`${befeBaseUrl}/admin/allDevices`, {
        params: {
          search: searchString,
        },
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.data
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  getDeviceTypes: async () => {
    try {
      const response = await axios.get(`${befeBaseUrl}/device/types`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.data
    } catch (error) {
      console.log('ERROR', error)
      return error
    }
  },

  getDeviceHasCamera: async (deviceId) => {
    try {
      const response = await axios.get(`${befeBaseUrl}/device/${deviceId}/hasCamera`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.data
    } catch (error) {
      console.error('error', error)
      return false
    }
  },

  getGalleryPictures: async ({ deviceId, months = 1, offset = 0, desc = false }) => {
    try {
      const response = await axios.get(
        `${befeBaseUrl}/device/${deviceId}/images?months=${months}&&offset=${offset}&&desc=${desc}`,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      return response.data
    } catch (error) {
      console.log('ERROR', error)
      return []
    }
  },
}

export default DataService
