import React, { useEffect, useState } from 'react'
import './AdminModal.css'
import { DefaultButton, DefaultInput } from '../../DefaultComponents'
import DataService from '../../../data/DataService'
import useWarning from '../../Modal/WarningModal/WarningModal'
import { ReactComponent as SaveIcon } from '../../../assets/SaveIcon.svg'
import { useAuth } from '../../../hooks/useAuth'

const AdminModal = ({ onClose, deviceData }) => {
  const [data, setData] = useState(deviceData)
  const [deviceName, setDeviceName] = useState(deviceData?.name)
  const [frequency, setFrenquency] = useState(0)
  const [automationName, setAutomationName] = useState(
    deviceData?.automation?.automation?.name || '',
  )
  const [warningText, setWarningText] = useState('')
  const [getConfirmation, Warning] = useWarning(warningText)

  const { user } = useAuth()

  useEffect(() => {
    setData(deviceData)
  }, [deviceData])

  const changeAutomationStatus = async (status) => {
    setWarningText('Are you sure you want to change automation status?')
    const confirm = await getConfirmation()
    if (confirm) {
      const resp = await DataService.updateAutomationStatus(
        data.automation_id,
        status,
        data.device_type_name,
      )
      if (resp === 200) {
        setData((prevState) => ({
          ...prevState,
          automation_active: status,
        }))
      }
    }
  }

  const handleResetDevice = async () => {
    const payload = 'R'
    setWarningText('Are you sure you want to Reset Device?')
    const confirm = await getConfirmation()
    if (confirm) {
      const resp = await DataService.sendActionRequest({
        payload: payload,
        device_type: data.device_type_id.toString(),
        device_mac_address: data.mac_address,
        action_name: 'Reset',
      })
    }
  }

  const handleRestartDevice = async () => {
    const payload = 'RM'
    setWarningText('Are you sure you want to Restart Device?')
    const confirm = await getConfirmation()
    if (confirm) {
      const resp = await DataService.sendActionRequest({
        payload: payload,
        device_type: data.device_type_id.toString(),
        device_mac_address: data.mac_address,
        action_name: 'Reset',
      })
    }
  }

  const handleOTA = async () => {
    const payload = ''
    setWarningText('Are you sure you want to trigger OTA')
    const confirm = await getConfirmation()
    if (confirm) {
      const resp = await DataService.sendActionRequest({
        payload: payload,
        device_type: data.device_type_id.toString(),
        device_mac_address: data.mac_address,
        action_name: 'OTA',
      })
    }
  }

  const handleSaveDevice = async (key, value) => {
    const res = await DataService.updateDeviceValue({ column: key, value: value }, data.device_id)
    if (res === 200) {
      setData((prevState) => ({
        ...prevState,
        name: value,
      }))
    }
  }

  const handleSaveAutomation = async (value) => {
    const res = await DataService.updateAutomation(
      { name: value, user_created: true, user_id: user.user_id },
      data.automation_id,
    )
    if (res === 200) {
      setData((prevState) => ({
        ...prevState,
        automation: {
          automation: {
            name: value,
          },
        },
      }))
    }
  }

  const labelWithSave = (label, key, stateName) => {
    let dataValue
    if (key === 'automationName') {
      dataValue = data?.automation?.automation?.name
    } else {
      dataValue = data[key]
    }
    return (
      <div
        className="input-label"
        onClick={() => {
          if (key === 'name') {
            handleSaveDevice(key, stateName)
          }
          if (key === 'automationName') {
            handleSaveAutomation(stateName)
          }
        }}
      >
        {label}
        {stateName !== dataValue && (
          <div className="save-icon-text">
            <SaveIcon width={12} height={12} className="icon-save-color" /> Save
          </div>
        )}
      </div>
    )
  }

  return (
    <div className="modal-body">
      <div className="modal-content">
        <div className="modal-content-section">
          Device<div className="modal-content-section-divider"></div>
        </div>
        <div className="section-content">
          <div>
            <DefaultInput
              value={deviceName}
              label={labelWithSave('Device name', 'name', deviceName)}
              onChange={(e) => {
                setDeviceName(e.target.value)
              }}
            />{' '}
          </div>
          <DefaultInput
            value={frequency}
            type={'number'}
            label={labelWithSave('Set frequency', 'frequency', frequency)}
            onChange={(e) => {
              setFrenquency(e.target.value)
            }}
          />
          <div className="inline-btns">
            <DefaultButton onClick={handleResetDevice}>Reset device</DefaultButton>
            <DefaultButton onClick={handleRestartDevice}>Restart device</DefaultButton>
            <DefaultButton onClick={handleOTA}>OTA</DefaultButton>
          </div>
        </div>
        <div className="modal-content-section">
          Automation<div className="modal-content-section-divider"></div>
        </div>
        {data.automation ? (
          <div className="section-content">
            <DefaultInput
              value={automationName}
              label={labelWithSave('Name', 'automationName', automationName)}
              onChange={(e) => {
                setAutomationName(e.target.value)
              }}
            />
            <div className="automation-status">
              Automation {data.automation_active ? 'active' : 'inactive'}
              <DefaultButton
                onClick={() => changeAutomationStatus(data.automation_active ? false : true)}
              >
                {data.automation_active ? 'Deactivate' : 'Activate'}
              </DefaultButton>
            </div>
          </div>
        ) : (
          <div className="section-content no-automation-text">
            This device has no automation set!
          </div>
        )}
      </div>
      <div className="inline-btns">
        <DefaultButton onClick={onClose}>Close</DefaultButton>
      </div>
      <Warning showText={true} />
    </div>
  )
}

export default AdminModal
