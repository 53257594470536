import React from 'react'
import './UpgradeCard.css'
import { ReactComponent as CheckmarkIconCircle } from '../../assets/CheckmarkIconCircle.svg'
import DefaultButton from '../DefaultComponents/DefaultButton/DefaultButton'
import { useTranslation } from 'react-i18next'
import { Card } from '@mui/material'

const UpgradeCard = ({ plan, planType }) => {
  const { t } = useTranslation('translations')
  let calculateAnnual = plan.price.annual / 12
  if (plan.price.annual % 12 !== 0) {
    calculateAnnual = calculateAnnual.toFixed(2)
  }
  return (
    <Card variant="outlined">
      <div className="card-content">
        <div className="card-title">{plan.name}</div>
        {!planType ? (
          <div className="card-price">
            {plan.price.monthly}€ {plan.type > 1 && t('upgrade.per_month')}
          </div>
        ) : (
          <div className="card-price-m">
            {plan.type === 0
              ? `0€ / ${t('upgrade.switched_to_free_plan')}`
              : `${plan.price.annual}€ ${t('upgrade.per_year')} / ${calculateAnnual}€ ${t(
                  'upgrade.per_month',
                )}`}
          </div>
        )}
        <div style={{ minHeight: 50 }}>
          {plan.controls.map((c, index) => (
            <div className="card-control-types card-text-normal" key={index}>
              <CheckmarkIconCircle className={c.included ? 'icon-active' : 'icon-inactive'} />
              {c.name}
            </div>
          ))}
        </div>
        <span className="card-divider"></span>
        <div>
          <div className="card-text-bold">{t('upgrade.sampling_soil_conditions')}</div>
          <div className="card-text-normal">
            {plan.soilConditions} {t('upgrade.min_range')}
          </div>
        </div>
        <div>
          <div className="card-text-bold">{t('upgrade.sampling_air_conditions')}</div>
          <div className="card-text-normal">
            {plan.airConditions} {t('upgrade.min_range')}
          </div>
        </div>
        <div>
          <div className={`${plan.phConditions ? 'card-text-bold' : 'card-bold-disabled'}`}>
            {t('upgrade.sampling_ph_conditions')}{' '}
          </div>
          <div className={`${plan.phConditions ? 'card-text-normal' : 'card-disabled'}`}>
            {plan.phConditions ? plan.phConditions : 'Unavailable'}
          </div>
        </div>
        <div>
          <div className="card-text-bold">{t('upgrade.data_retention')}</div>
          <div className="card-text-normal">{plan.dataRetention}</div>
        </div>
        <div>
          <div className="card-text-bold">{t('upgrade.number_of_supported_devices')}</div>
          <div className="card-text-normal">
            {t('upgrade.up_to')} {plan.devices}
          </div>
        </div>
        <div></div>
        <div>
          <div className={`${plan.growingPlans ? 'card-text-bold' : 'card-bold-disabled'}`}>
            {t('upgrade.growing_plans')}
          </div>
          <div className={`${plan.growingPlans ? 'card-text-normal' : 'card-disabled'}`}>
            {plan.growingPlans ? t('upgrade.yes') : t('upgrade.no')}
          </div>
        </div>
        <div>
          <div className={`${plan.reports ? 'card-text-bold' : 'card-bold-disabled'}`}>
            {t('upgrade.reports')}
          </div>
          <div className={`${plan.reports ? 'card-text-normal' : 'card-disabled'}`}>
            {plan.reports ? t('upgrade.yes') : t('upgrade.no')}
          </div>
        </div>
        <DefaultButton customStyle={'card-btn'}>{t('upgrade.start_now')}</DefaultButton>
      </div>
    </Card>
  )
}

export default UpgradeCard
