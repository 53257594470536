import React from 'react'
import CalibrationModal from './CalibrationModal/CalibrationModal'
import './Modal.css'
import VersionModal from './VersionModal/VersionModal'
import StageModal from '../AutomationsComponents/StageModal'
import DiaryModal from '../../screens/DiaryScreen/DiaryModal'
import AddNewPlantModal from './AddNewPlantModal/AddNewPlantModal'
import SettingsModal from './SettingsModal/SettingsModal'
import AdminModal from './AdminModal/AdminModal'

const ModalHelper = ({ deviceType, modalState, type, onClose, data, dataUpdated }) => {
  return (
    <div className="modal-helper-body">
      {type === 'calibration' && <CalibrationModal deviceType={deviceType} onClose={onClose} />}
      {type === 'version' && <VersionModal versionData={data} onClose={onClose} />}
      {type === 'stage' && (
        <StageModal
          modalState={modalState}
          stageData={data}
          onClose={onClose}
          dataUpdated={(e) => dataUpdated(e)}
        />
      )}
      {type === 'diary' && (
        <DiaryModal diaryData={data} onClose={onClose} dataUpdated={(e) => dataUpdated(e)} />
      )}
      {type === 'newPlant' && <AddNewPlantModal onClose={onClose} />}
      {type === 'settings' && (
        <SettingsModal dataUpdated={dataUpdated} deviceData={data} onClose={onClose} />
      )}
      {type === 'admin' && <AdminModal deviceData={data} onClose={onClose} />}
    </div>
  )
}

export default ModalHelper
