import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react'
import { ReactComponent as CalendarIcon } from '../../assets/CalendarIcon.svg'
import { ReactComponent as RemoveIcon } from '../../assets/RemoveIcon.svg'
import { ReactComponent as AddIcon } from '../../assets/AddIcon.svg'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import { MobileDateRangePicker } from '@mui/x-date-pickers-pro/MobileDateRangePicker'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { DefaultButton, DefaultInput, DefaultDialog } from '../DefaultComponents'
import { getISOTime, calculateTimezone, getTimestampFromTime } from '../../util/formatDate'
import { formatISODate } from '../../util/util'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import DataService from '../../data/DataService'
import './CustomAutomation.css'
import { useNotifications } from '../../hooks/useNotification'

const StagesForm = forwardRef((props, ref) => {
  const currentDate = new Date().toISOString().split('T')[0]
  const { t } = useTranslation('translations')
  const [stageName, setStageName] = useState('')
  const [start, setStart] = useState(currentDate)
  const [end, setEnd] = useState(currentDate)
  const [minTemperature, setMinTemperature] = useState('')
  const [maxTemperature, setMaxTemperature] = useState('')
  const [lightIntervals, setLightIntervals] = useState([
    { start_time: getISOTime(new Date()), end_time: getISOTime(new Date()) },
  ])
  const [intervalsToDelete, setIntervalsToDelete] = useState([])
  const [templateIntervalsToDelete, setTemplateIntervalsToDelete] = useState([])
  const [moistureLevel, setMoistureLevel] = useState('')
  const [automationId, setAutomationId] = useState(null)
  const [validation, setValidation] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)

  const { addErrorNotification } = useNotifications()

  useImperativeHandle(ref, () => ({
    async saveStage(automation_id, is_template) {
      const payload = {
        name: stageName,
        automation_id: automation_id,
        start: start,
        end: end,
        temperature_min: minTemperature,
        temperature_max: maxTemperature,
        moisture_value: moistureLevel,
      }
      const validate = validatePayload(payload)
      if (validate) {
        if (is_template) {
          let res = await DataService.addStageTemplate(payload)
          if (res?.stage_id) {
            setValidation(false)
            for (const interval of lightIntervals) {
              await DataService.addLightIntervalTemplate({
                stage_id: res.stage_id,
                automation_id: automation_id,
                start_time: interval.start_time,
                end_time: interval.end_time,
              })
            }
            return true
          } else {
            setOpenDialog(true)
          }
        } else {
          let res = await DataService.addStage(payload)
          if (res?.stage_id) {
            setValidation(false)
            for (const interval of lightIntervals) {
              await DataService.addLightInterval({
                stage_id: res.stage_id,
                automation_id: automation_id,
                start_time: interval.start_time,
                end_time: interval.end_time,
              })
            }
            return true
          } else if (res?.response?.data === 'E_STAGE_OVERLAPS') {
            addErrorNotification({
              header: 'Failed to save stage',
              text: 'This stage is overlapping with some other stages',
            })
          } else {
            addErrorNotification({
              header: 'Failed to save stage',
            })
          }
        }
      }
      return false
    },

    async deleteStage(stageId, is_template) {
      if (is_template) {
        let res = await DataService.deleteStageTemplate(stageId)
        if (res === 200) {
          return true
        } else {
          setOpenDialog(true)
          return false
        }
      } else {
        let res = await DataService.deleteStage(stageId)
        if (res === 200) {
          return true
        } else {
          setOpenDialog(true)
          return false
        }
      }
    },

    async updateStage(stage_id, is_template) {
      const payload = {
        name: stageName,
        automation_id: automationId,
        start: start,
        end: end,
        temperature_min: minTemperature,
        temperature_max: maxTemperature,
        moisture_value: moistureLevel,
      }
      const validate = validatePayload(payload)
      if (validate) {
        if (is_template) {
          let res = await DataService.updateStageTemplate(payload, stage_id)
          if (res === 200) {
            setValidation(false)
            for (const interval of templateIntervalsToDelete) {
              await DataService.deleteLightIntervalTemplate(interval)
            }
            for (const interval of lightIntervals) {
              await DataService.addLightIntervalTemplate({
                stage_id: stage_id,
                automation_id: automationId,
                start_time: interval.start_time,
                end_time: interval.end_time,
              })
            }
            return stage_id
          } else {
            setOpenDialog(true)
          }
        } else {
          let res = await DataService.updateStage(payload, stage_id)
          if (res === 200) {
            setValidation(false)
            for (const interval of intervalsToDelete) {
              await DataService.deleteLightInterval(interval)
            }
            for (const interval of lightIntervals) {
              await DataService.addLightInterval({
                stage_id: stage_id,
                automation_id: automationId,
                start_time: interval.start_time,
                end_time: interval.end_time,
              })
            }
            return stage_id
          } else if (res?.response?.data === 'E_STAGE_OVERLAPS') {
            addErrorNotification({
              header: 'Failed to save stage',
              text: 'This stage is overlapping with some other stages',
            })
          } else {
            addErrorNotification({
              header: 'Failed to save stage',
            })
          }
        }
      }
      return 0
    },
  }))

  useEffect(() => {
    if (props.stageData) {
      const data = props.stageData
      setStageName(data.title)
      setStart(data.start)
      setEnd(data.end)
      setMinTemperature(data.temperature_min)
      setMaxTemperature(data.temperature_max)
      setMoistureLevel(data.moisture_value)
      setAutomationId(data.automation_id)

      getLightIntervals(data.stage_id, data.automation_id)
    }
    if (props.templateData) {
      const data = props.templateData
      setStageName(data.title)
      setStart(data.start)
      setEnd(data.end)
      setMinTemperature(data.temperature_min)
      setMaxTemperature(data.temperature_max)
      setMoistureLevel(data.moisture_value)
      setAutomationId(data.automation_id)

      getLightIntervalTemplates(data.stage_id, data.automation_id)
    }
  }, [props.stageData, props.templateData])

  const getLightIntervals = async (stage_id, automation_id) => {
    const getLightIntervals = await DataService.getLightIntervalsByStage({
      stage_id: stage_id,
      automation_id: automation_id,
    })
    if (getLightIntervals.length) {
      let formmatedLightIntervals = []
      let intervalsToDelete = []
      for (const interval of getLightIntervals) {
        formmatedLightIntervals.push({
          light_interval_id: interval.light_interval_id,
          stage_id: interval.stage_id,
          automation_id: interval.automation_id,
          start_time: interval.start_time,
          end_time: interval.end_time,
        })
        intervalsToDelete.push(interval.light_interval_id)
      }
      setLightIntervals(formmatedLightIntervals)
      setIntervalsToDelete(intervalsToDelete)
    }
  }

  const getLightIntervalTemplates = async (stage_id, automation_id) => {
    const getLightIntervals = await DataService.getLightIntervalTemplateByStageTemplate({
      stage_id: stage_id,
      automation_id: automation_id,
    })
    if (getLightIntervals.length) {
      let formmatedLightIntervals = []
      let intervalsToDelete = []
      for (const interval of getLightIntervals) {
        formmatedLightIntervals.push({
          light_interval_id: interval.light_interval_id,
          stage_id: interval.stage_id,
          automation_id: interval.automation_id,
          start_time: interval.start_time,
          end_time: interval.end_time,
        })
        intervalsToDelete.push(interval.light_interval_id)
      }
      setLightIntervals(formmatedLightIntervals)
      setTemplateIntervalsToDelete(intervalsToDelete)
    }
  }

  const formatDate = (range) => {
    if (range[0]) {
      setStart(formatISODate(range[0]))
    } else {
      setStart('')
    }
    if (range[1]) {
      setEnd(formatISODate(range[1]))
    } else {
      setEnd('')
    }
  }

  const formatStartTime = (time, index) => {
    if (time) {
      if (time.$d.toString() !== 'Invalid Date') {
        let temp = [...lightIntervals]
        temp[index].start_time = getISOTime(time.$d)
        setLightIntervals(temp)
      }
    } else {
      let temp = [...lightIntervals]
      temp[index].start_time = getISOTime(new Date())
      setLightIntervals(temp)
    }
  }

  const formatEndTime = (time, index) => {
    if (time) {
      if (time.$d.toString() !== 'Invalid Date') {
        let temp = [...lightIntervals]
        temp[index].end_time = getISOTime(time.$d)
        setLightIntervals(temp)
      }
    } else {
      let temp = [...lightIntervals]
      temp[index].end_time = getISOTime(new Date())
      setLightIntervals(temp)
    }
  }

  const addInterval = () => {
    let temp = [...lightIntervals]
    const last_item = lightIntervals[lightIntervals.length - 1]
    temp.push({ start_time: last_item.start_time, end_time: last_item.end_time })
    setLightIntervals(temp)
  }

  const validatePayload = (payload) => {
    setValidation(true)
    for (const value of Object.values(payload)) {
      if ((typeof value === 'string' && !value.trim()) || !value) {
        return false
      }
    }
    for (const index of lightIntervals.keys()) {
      if (validateTimeRange(index, lightIntervals)) {
        return false
      }
    }
    return true
  }

  const removeInterval = (index) => {
    let temp = [...lightIntervals]
    temp.splice(index, 1)
    setLightIntervals(temp)
  }

  const validateTimeRange = (index, intervals) => {
    const start = getTimestampFromTime(intervals[index].start_time)
    const end = getTimestampFromTime(intervals[index].end_time)
    let intervalseForCompare = [...intervals]
    intervalseForCompare.splice(index, 1)
    if (start > end) {
      return 'Invalid time range'
    }
    for (const interval of intervalseForCompare) {
      const e1start = start
      const e1end = end
      const e2start = getTimestampFromTime(interval.start_time)
      const e2end = getTimestampFromTime(interval.end_time)
      if ((e1start >= e2start && e1start <= e2end) || (e2start >= e1start && e2start <= e1end)) {
        return 'Time range overlaps'
      }
    }
    return ''
  }

  const limitValue = (value) => {
    if (value.length > 5) {
      return value.substring(0, 5)
    } else {
      return value
    }
  }

  return (
    <div className="stage-setup-form">
      <DefaultDialog
        disableDefaultAnimation
        classModifier={`error-container ${openDialog ? 'open' : 'closed'}`}
        header={
          <div>
            <div className="system-checks-dialog-title">{t('system_checks.error_occured')}</div>
            <div className="system-checks-dialog-subtitle">{'Failed to save data.'}</div>
          </div>
        }
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      />
      <DefaultInput
        value={stageName}
        label={t('automations.stage_setup.set_stage_name')}
        placeholder={t('automations.stage_setup.enter_stage_name')}
        onChange={(e) => setStageName(e.target.value)}
        error={validation && !stageName}
        message={validation && !stageName ? 'This field is required.' : ''}
      />
      <div className="default-input-label">{t('automations.stage_setup.set_date_range')}</div>
      <div className="automations-stage-date-picker-container">
        <MobileDateRangePicker
          value={[dayjs(start), dayjs(end)]}
          className="date-range-picker mobile"
          onChange={(e) => formatDate(e)}
          slotProps={{
            textField: {
              focused: true,
              InputProps: { endAdornment: <CalendarIcon className="calendar-icon" /> },
            },
          }}
          localeText={{
            start: t('automations.stage_setup.start'),
            end: t('automations.stage_setup.end'),
          }}
        />
        <DateRangePicker
          value={[dayjs(start), dayjs(end)]}
          className="date-range-picker"
          onChange={(e) => formatDate(e)}
          slotProps={{
            textField: {
              focused: true,
              InputProps: { endAdornment: <CalendarIcon className="calendar-icon" /> },
            },
          }}
          localeText={{
            start: t('automations.stage_setup.start'),
            end: t('automations.stage_setup.end'),
          }}
        />
      </div>
      <div className="default-input-label">{t('automations.stage_setup.ideal_temperature')}</div>
      <div className="automation-stage-temperature-container">
        <DefaultInput
          value={minTemperature}
          type="number"
          label={'Min'}
          placeholder={t('automations.stage_setup.enter_temperature')}
          onChange={(e) => setMinTemperature(limitValue(e.target.value))}
          error={validation && !minTemperature}
          message={validation && !minTemperature ? 'This field is required.' : ''}
        />
        <DefaultInput
          value={maxTemperature}
          type="number"
          label={'Max'}
          placeholder={t('automations.stage_setup.enter_temperature')}
          onChange={(e) => setMaxTemperature(limitValue(e.target.value))}
          error={validation && !maxTemperature}
          message={validation && !maxTemperature ? 'This field is required.' : ''}
        />
      </div>
      <div className="default-input-label">{t('automations.stage_setup.light_intervals')}</div>
      {lightIntervals.length &&
        lightIntervals.map((item, index) => (
          <div key={index} className="automations-light-interval-container">
            <div className="automations-time-picker-wrapper">
              <TimePicker
                className="stage-time-picker"
                ampm={false}
                value={item?.start_time ? dayjs(calculateTimezone(item.start_time)) : null}
                timeSteps={{ minutes: 1 }}
                slotProps={{
                  textField: {
                    error: !!validateTimeRange(index, lightIntervals),
                    helperText: validateTimeRange(index, lightIntervals),
                  },
                }}
                onChange={(e) => formatStartTime(e, index)}
                label={t('automations.stage_setup.start')}
              />
              <TimePicker
                className="stage-time-picker"
                ampm={false}
                value={item?.end_time ? dayjs(calculateTimezone(item.end_time)) : null}
                timeSteps={{ minutes: 1 }}
                slotProps={{
                  textField: {
                    error: !!validateTimeRange(index, lightIntervals),
                  },
                }}
                onChange={(e) => formatEndTime(e, index)}
                label={t('automations.stage_setup.end')}
              />
            </div>
            {lightIntervals.length > 0 && (
              <div
                onClick={() => removeInterval(index)}
                className="automations-remove-light-interval-icon"
              >
                <RemoveIcon />
              </div>
            )}
          </div>
        ))}
      {lightIntervals.length <= 10 ? (
        <DefaultButton
          customStyle={'add-interval-btn'}
          leftIcon
          onClick={() => addInterval()}
          customLeftIcon={
            <div className="add-interval-icon-box">
              <AddIcon className="add-interval-icon" />
            </div>
          }
        >
          {t('automations.stage_setup.add_new_interval')}
        </DefaultButton>
      ) : null}
      {/* <DefaultInput
        value={moistureLevel}
        type="number"
        min={0}
        max={100}
        label={t('automations.stage_setup.ideal_moisture_level')}
        placeholder={t('automations.stage_setup.enter_ph_value')}
        onChange={(e) => setMoistureLevel(limitValue(e.target.value))}
        error={validation && !moistureLevel}
        message={validation && !moistureLevel ? 'This field is required.' : ''}
      /> */}
    </div>
  )
})

export default StagesForm
