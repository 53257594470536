import { useAuth } from '../../hooks/useAuth'
import Layout from '../Layout/Layout'

const { useLocation, Navigate } = require('react-router-dom')

const ProtectedRoute = ({ children }) => {
  const { authenticated, user } = useAuth()
  const loginRoutes = ['/login', '/register']
  const allowedRoutes = ['/connect', '/welcome']
  const location = useLocation()

  if (!authenticated && location.pathname !== '/login' && location.pathname !== '/register') {
    return <Navigate to={'/login'} />
  }

  if (authenticated && !user.hasRegisteredDevice && !allowedRoutes.includes(location.pathname)) {
    return <Navigate to={'/connect'} />
  }

  if (
    authenticated &&
    user.hasRegisteredDevice &&
    (allowedRoutes.includes(location.pathname) || loginRoutes.includes(location.pathname))
  ) {
    return <Navigate to={'/'} />
  }

  if (
    !loginRoutes.includes(location.pathname) &&
    !allowedRoutes.includes(location.pathname) &&
    location.pathname !== '/system-check'
  ) {
    return <Layout>{children}</Layout>
  } else return children
}

export default ProtectedRoute
