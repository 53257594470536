import React from 'react'
import './AddNewPlantModal.css'
import SectionHeader from '../../DefaultComponents/SectionHeader'
import { ReactComponent as AutomationsIcon } from '../../../assets/AutomationsIcon.svg'
import Table from '../../Table/Table'
import StagesForm from '../../AutomationsComponents/StagesForm'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import { useTranslation } from 'react-i18next'

const CustomAutomation = () => {
  const { t } = useTranslation('translations')

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="form-box">
        <label className="add-plant-normal-text">{t('automations.plan_name')}</label>
        <input className="add-plant-input" type="text" placeholder="Enter plan name" />
      </div>
      <div className="form-box">
        <label className="add-plant-normal-text">{t('automations.set_box_name')}</label>
        <input className="add-plant-input" type="text" placeholder="Enter box name" />
      </div>
      <SectionHeader
        icon={<AutomationsIcon className="icon-color" />}
        title={t('automations.list_ofstages')}
      />
      <Table type={'stages'} tableData={[]} />
      <SectionHeader
        icon={<AutomationsIcon className="icon-color" />}
        title={t('automations.stage_setup_label')}
      />
      <StagesForm />
    </LocalizationProvider>
  )
}

export default CustomAutomation
