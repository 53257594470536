import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { ErrorBoundary } from 'react-error-boundary'
import '../src/util/i18n'
import { AuthProvider } from './hooks/useAuth'
import { ErrorScreen } from './screens/ErrorScreen/ErrorScreen'
import { NotificationProvider } from './hooks/useNotification'
import { DeviceProvider } from './hooks/useDevice'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <React.StrictMode>
      <ErrorBoundary FallbackComponent={ErrorScreen}>
        <NotificationProvider>
          <AuthProvider>
            <DeviceProvider>
              <App />
            </DeviceProvider>
          </AuthProvider>
        </NotificationProvider>
      </ErrorBoundary>
    </React.StrictMode>
  </GoogleOAuthProvider>,
)
