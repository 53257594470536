import React, { useState } from 'react'
import './Upgrade.css'
import SectionHeader from '../../components/DefaultComponents/SectionHeader'
import { ReactComponent as UpgradeIcon } from '../../assets/UpgradeIcon.svg'
import UpgradeCard from '../../components/Upgrade/UpgradeCard'
import { plans } from '../../util/constants'
import { useTranslation } from 'react-i18next'
import SwitchToggle from '../../components/DefaultComponents/SwitchToggle/SwitchToggle'

const Upgrade = () => {
  const [togglePlan, setTogglePlan] = useState(false)
  const { t } = useTranslation('translations')

  return (
    <div className="upgrade-body">
      <SectionHeader
        title={'Upgrade'}
        icon={<UpgradeIcon className="upgrade-icon" />}
        classModifier={'pt0'}
      />
      <div className="plan-switch">
        <div>
          <span className={`${togglePlan ? '' : 'plan-text-active'}`}>{t('upgrade.monthly')}</span>/
          <span className={`${togglePlan ? 'plan-text-active' : ''}`}>{t('upgrade.annual')}</span>
        </div>
        <div className="plan-toggle-switch" onClick={() => setTogglePlan(!togglePlan)}>
          <SwitchToggle activeState={togglePlan} />
        </div>
      </div>
      <div className="plans">
        {plans.map((p, i) => (
          <UpgradeCard key={i} plan={p} planType={togglePlan} />
        ))}
      </div>
    </div>
  )
}

export default Upgrade
