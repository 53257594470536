import './LatestPhoto.css'
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import defaultPhoto from '../../assets/defaultPhoto.jpeg'
import { ReactComponent as GalleryIcon } from '../../assets/GalleryIcon.svg'
import SmallPhoto from './SmallPhoto'
import GalleryCarousel from '../Gallery/GalleryCarousel'
import { useTranslation } from 'react-i18next'
import { calcElapsedTime } from '../../util/util'
import { getFormatedDateGallery } from '../../util/formatDate'

const LatestPhoto = ({ photo1HourAgo, photo2HourAgo, photo3HourAgo, photo4HourAgo }) => {
  const [show, setShow] = useState(false)
  const [index, setIndex] = useState(0)
  const [previewImageData, setPreviewImageData] = useState([{ url: '', imageTitle: '' }])
  const { t } = useTranslation('translations')

  const setImageData = (image, text) => {
    setPreviewImageData([{ url: image, imageTitle: text }])
  }

  const setPreviewShow = (show) => {
    setShow(show)
  }
  // const downloadImage = () => {
  //   axios.get(PICTURE, { responseType: 'blob' }).then((myBlob) => {
  //     const href = URL.createObjectURL(myBlob.data)

  //     const link = document.createElement('a')
  //     link.href = href
  //     link.setAttribute('download', 'picture1.jpeg')
  //     document.body.appendChild(link)
  //     link.click()

  //     document.body.removeChild(link)
  //     URL.revokeObjectURL(href)
  //   })
  // }

  return (
    <div
      onClick={() => {
        if (show) setShow(false)
      }}
      className="latest-photo-container"
    >
      {show && (
        <GalleryCarousel
          onClose={setPreviewShow}
          imagesData={previewImageData}
          intialImageIndex={index}
          showThumbnails={false}
          showActionArrows={false}
        />
      )}
      <span className="latest-photo-text">
        <GalleryIcon className="gallery-icon" />
        {t('dashboard.latest_photo.latest_photo')}
      </span>
      <span className="last-update-bold">
        {t('dashboard.latest_photo.last_update')}{' '}
        <span className="last-update">{getFormatedDateGallery(photo1HourAgo.date_captured)}</span>
      </span>
      <div className="latest-photo-box">
        <div className="latest-photo-left-wrapper">
          <div className="latest-photo-big-container">
            <img
              onClick={() => {
                setPreviewImageData([
                  {
                    url: photo1HourAgo.url,
                    imageTitle: calcElapsedTime(photo1HourAgo.date_captured),
                  },
                ])
                setShow(true)
              }}
              className="latest-photo-big"
              src={photo1HourAgo.url}
              alt="1hourAgo"
            ></img>
            <div className="buttons">
              <div className="info">{calcElapsedTime(photo1HourAgo.date_captured)}</div>
              <div
                onClick={() => {
                  /* downloadImage */
                }}
                className="btn-download"
              >
                {t('dashboard.latest_photo.download')}
              </div>
            </div>
          </div>
        </div>
        <div className="latest-photo-right-wrapper">
          <SmallPhoto
            setImageData={setImageData}
            setShow={setPreviewShow}
            image={photo2HourAgo.url}
            infoTitle={calcElapsedTime(photo2HourAgo.date_captured)}
            photoAlt={'2hourAgo'}
          />
          <SmallPhoto
            setImageData={setImageData}
            setShow={setPreviewShow}
            image={photo3HourAgo.url}
            infoTitle={calcElapsedTime(photo3HourAgo.date_captured)}
            photoAlt={'3hourAgo'}
          />
          <SmallPhoto
            setImageData={setImageData}
            setShow={setPreviewShow}
            image={photo4HourAgo.url}
            infoTitle={calcElapsedTime(photo4HourAgo.date_captured)}
            photoAlt={'4hourAgo'}
          />
        </div>
      </div>
      <NavLink to={'/gallery'} className="full-gallery-btn">
        <GalleryIcon /> {t('dashboard.latest_photo.see_full_gallery')}
      </NavLink>
    </div>
  )
}

const photoType = PropTypes.shape({
  url: PropTypes.string.isRequired,
  date_captured: PropTypes.string.isRequired,
})

const defaultValue = { url: defaultPhoto, date_captured: '' }

LatestPhoto.propTypes = {
  photo1HourAgo: photoType,
  photo2HourAgo: photoType,
  photo3HourAgo: photoType,
  photo4HourAgo: photoType,
}

LatestPhoto.defaultProps = {
  photo1HourAgo: defaultValue,
  photo2HourAgo: defaultValue,
  photo3HourAgo: defaultValue,
  photo4HourAgo: defaultValue,
}

export default LatestPhoto
