import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { DefaultButton, DefaultInput } from '../../DefaultComponents'
import { useEffect, useState } from 'react'
import { DateRangePicker, MobileDateRangePicker, TimePicker } from '@mui/x-date-pickers-pro'
import { ReactComponent as CalendarIcon } from '../../../assets/CalendarIcon.svg'
import dayjs from 'dayjs'
import { ReactComponent as RemoveIcon } from '../../../assets/RemoveIcon.svg'
import { ReactComponent as AddIcon } from '../../../assets/AddIcon.svg'
import { formatISODate } from '../../../util/util'
import {
  getISOTime,
  calculateTimezone,
  getTimestampFromTime,
  dateToTimezoneTimeString,
  dateToUTCTimeString,
  getDateFromUTCTimeString,
} from '../../../util/formatDate'
import { ReactComponent as EditIcon } from '../../../assets/EditIcon.svg'
import { ReactComponent as CheckmarkIconCircle } from '../../../assets/CheckmarkIconCircle.svg'
import SSXCheckbox from '../../UI/SSXCheckbox/SSXCheckbox'

const addToDateAndReturn = (date, { hours, minutes, seconds }) => {
  const copy = new Date(date)
  if (hours) {
    copy.setHours(copy.getHours() + hours)
  }
  if (minutes) {
    copy.setMinutes(copy.getMinutes() + minutes)
  }
  if (seconds) {
    copy.setSeconds(copy.getSeconds() + seconds)
  }

  return copy
}

export const ValidateStageForm = (stageFormData) => {
  const errors = {}

  if (!stageFormData.name) {
    errors.name = 'Name cannot be empty'
  }

  if (
    (!stageFormData.temperature_min || !stageFormData.temperature_max) &&
    !stageFormData.light_intervals.length
  ) {
    errors.temperature_interval = 'Light intervals or min and max temperature has to be set'
  }

  if (parseInt(stageFormData.temperature_min, 10) > parseInt(stageFormData.temperature_max, 10)) {
    errors.temperature_min = 'Minimum temp. cannot be greater then maximum temp.'
    errors.temperature_max = 'Minimum temp. cannot be greater then maximum temp.'
  }

  for (let i = 0; i < stageFormData.light_intervals.length; i++) {
    const currentLightInterval = stageFormData.light_intervals[i]

    const startTimestamp = getTimestampFromTime(currentLightInterval.start_time)
    const endTimestamp = getTimestampFromTime(currentLightInterval.end_time)

    const setupLightIntervalsError = () => {
      if (!errors.light_intervals) {
        errors.light_intervals = {}
      }
    }

    if (startTimestamp === endTimestamp) {
      setupLightIntervalsError()
      errors.light_intervals[i.toString()] = 'Time cannot be equal'
      continue
    }

    if (startTimestamp > endTimestamp) {
      setupLightIntervalsError()
      errors.light_intervals[i.toString()] = 'Start time cannot be later than end time'
      continue
    }

    for (let j = 0; j < stageFormData.light_intervals.length; j++) {
      if (i === j) {
        continue
      }
      const compareTimeStart = getTimestampFromTime(stageFormData.light_intervals[j].start_time)
      const compareTimeEnd = getTimestampFromTime(stageFormData.light_intervals[j].end_time)

      if (
        (compareTimeStart >= startTimestamp && compareTimeStart <= endTimestamp) ||
        (compareTimeEnd >= startTimestamp && compareTimeEnd <= endTimestamp)
      ) {
        setupLightIntervalsError()
        errors.light_intervals[i.toString()] = 'Inverval overlaps'
      }
    }
  }

  return errors
}

const MainStageForm = ({
  initialData,
  setStageLoading,
  onSaved,
  automationId,
  onStageTemplateLoad,
  onStageTemplateEdit,
  onLoadTemplatePressed,
  onDataChange,
  errors,
}) => {
  const { t } = useTranslation('translations')

  const [stageTemplates, setStageTemplates] = useState([])
  const [showTemplates, setShowTemplates] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState()

  const [dataCopy, setDataCopy] = useState({
    start: new Date().toISOString(),
    end: new Date().toISOString(),
    ...initialData,
    light_intervals: initialData?.light_intervals?.length ?? [],
    // light_intervals: initialData?.light_intervals ?? [
    //   {
    //     start_time: dateToTimezoneTimeString(new Date()),
    //     end_time: dateToTimezoneTimeString(addToDateAndReturn(new Date(), { hours: 1 })),
    //   },
    // ],
  })

  useEffect(() => {
    onDataChange(dataCopy)
  }, [dataCopy, onDataChange])

  useEffect(() => {
    setDataCopy({
      start: new Date().toISOString(),
      end: new Date().toISOString(),
      ...initialData,
      light_intervals: initialData?.light_intervals ?? [],
      // light_intervals: initialData?.light_intervals ?? [
      //   {
      //     start_time: dateToTimezoneTimeString(new Date()),
      //     end_time: dateToTimezoneTimeString(addToDateAndReturn(new Date(), { hours: 1 })),
      //   },
      // ],
    })
  }, [initialData])

  const setKey = (key, data) => {
    setDataCopy((copy) => ({ ...copy, [key]: data }))
  }

  const formatDate = (range) => {
    if (range[0]) {
      setKey('start', formatISODate(range[0]))
    } else {
      setKey('start', '')
    }
    if (range[1]) {
      setKey('end', formatISODate(range[1]))
    } else {
      setKey('end', '')
    }
  }

  // Made by Josip
  const limitValue = (value) => {
    if (value.length > 5) {
      return value.substring(0, 5)
    } else {
      return value
    }
  }

  const formatStartTime = (time, index) => {
    if (time) {
      if (time.$d.toString() !== 'Invalid Date') {
        let temp = [...dataCopy.light_intervals]
        temp[index].start_time = getISOTime(time.$d)
        setKey('light_intervals', temp)
      }
    } else {
      let temp = [...dataCopy.light_intervals]
      temp[index].start_time = getISOTime(new Date())
      setKey('light_intervals', temp)
    }
  }

  const formatEndTime = (time, index) => {
    if (time) {
      if (time.$d.toString() !== 'Invalid Date') {
        let temp = [...dataCopy.light_intervals]
        temp[index].end_time = getISOTime(time.$d)
        setKey('light_intervals', temp)
      }
    } else {
      let temp = [...dataCopy.light_intervals]
      temp[index].end_time = getISOTime(new Date())
      setKey('light_intervals', temp)
    }
  }

  if (showTemplates) {
    return (
      <div className="stage-setup-form">
        <h3>Select a stage template</h3>
        {stageTemplates.map((st) => (
          <div
            key={st.stage_template_id}
            style={{
              display: 'inline-flex',
            }}
          >
            <SSXCheckbox
              onChange={(e) => {
                setSelectedTemplate(st)
              }}
              checked={selectedTemplate?.stage_template_id === st.stage_template_id}
              type={'radio'}
              value={st.stage_template_id}
            >
              {st.name}
            </SSXCheckbox>
          </div>
        ))}
        <div className="inline-btns">
          <DefaultButton
            leftIcon
            disabled={!selectedTemplate}
            customLeftIcon={
              <div className="stage-template-modal-check-icon">
                <CheckmarkIconCircle />
              </div>
            }
            onClick={() => {
              onStageTemplateLoad?.(selectedTemplate)
              setShowTemplates(false)
            }}
          >
            {'Load Template'}
          </DefaultButton>
          <DefaultButton
            leftIcon
            disabled={!selectedTemplate}
            customLeftIcon={
              <div className="stage-template-modal-edit-icon">
                <EditIcon />
              </div>
            }
            onClick={() => {
              onStageTemplateEdit?.(selectedTemplate)
            }}
          >
            {'Edit'}
          </DefaultButton>
        </div>
      </div>
    )
  }

  return (
    <div className="stage-setup-form">
      <DefaultInput
        value={dataCopy?.name}
        label={t('automations.stage_setup.set_stage_name')}
        placeholder={t('automations.stage_setup.enter_stage_name')}
        onChange={(e) => {
          setKey('name', e.target.value)
        }}
        error={errors?.name}
        message={errors?.name}
      />
      <div className="default-input-label">{t('automations.stage_setup.set_date_range')}</div>
      <div className="automations-stage-date-picker-container">
        <MobileDateRangePicker
          value={[dayjs(dataCopy?.start), dayjs(dataCopy?.end)]}
          className="date-range-picker mobile"
          onChange={(e) => formatDate(e)}
          slotProps={{
            textField: {
              focused: true,
              InputProps: { endAdornment: <CalendarIcon className="calendar-icon" /> },
            },
          }}
          localeText={{
            start: t('automations.stage_setup.start'),
            end: t('automations.stage_setup.end'),
          }}
        />
        <DateRangePicker
          value={[dayjs(dataCopy?.start), dayjs(dataCopy?.end)]}
          className="date-range-picker"
          onChange={(e) => formatDate(e)}
          slotProps={{
            textField: {
              focused: true,
              InputProps: { endAdornment: <CalendarIcon className="calendar-icon" /> },
            },
          }}
          localeText={{
            start: t('automations.stage_setup.start'),
            end: t('automations.stage_setup.end'),
          }}
        />
      </div>
      <div className="default-input-label">{t('automations.stage_setup.ideal_temperature')}</div>
      <div className="automation-stage-temperature-container">
        <DefaultInput
          value={dataCopy?.temperature_min}
          type="number"
          label={'Min'}
          placeholder={t('automations.stage_setup.enter_temperature')}
          onChange={(e) => setKey('temperature_min', limitValue(e.target.value))}
          error={errors?.temperature_min}
          message={errors?.temperature_min}
        />
        <DefaultInput
          value={dataCopy?.temperature_max}
          type="number"
          label={'Max'}
          placeholder={t('automations.stage_setup.enter_temperature')}
          onChange={(e) => setKey('temperature_max', limitValue(e.target.value))}
          error={errors?.temperature_max}
          message={errors?.temperature_max}
        />
      </div>
      <div className="default-input-label">{t('automations.stage_setup.light_intervals')}</div>
      {dataCopy?.light_intervals &&
        dataCopy.light_intervals.length > 0 &&
        dataCopy.light_intervals.map((item, index) => (
          <div key={index} className="automations-light-interval-container">
            <div className="automations-time-picker-wrapper">
              <TimePicker
                className="stage-time-picker"
                ampm={false}
                value={item?.start_time ? dayjs(calculateTimezone(item.start_time)) : null}
                timeSteps={{ minutes: 1 }}
                slotProps={{
                  textField: {
                    error: errors?.light_intervals?.[index.toString()],
                    helperText: errors?.light_intervals?.[index.toString()],
                  },
                }}
                onChange={(e) => formatStartTime(e, index)}
                label={t('automations.stage_setup.start')}
              />
              <TimePicker
                className="stage-time-picker"
                ampm={false}
                value={item?.end_time ? dayjs(calculateTimezone(item.end_time)) : null}
                timeSteps={{ minutes: 1 }}
                slotProps={{
                  textField: {
                    error: errors?.light_intervals?.[index.toString()],
                  },
                }}
                onChange={(e) => formatEndTime(e, index)}
                label={t('automations.stage_setup.end')}
              />
            </div>
            {dataCopy && dataCopy?.light_intervals.length > 0 && (
              <div
                onClick={() => {
                  const copy = [...dataCopy.light_intervals]
                  copy.splice(index, 1)
                  setDataCopy((data) => ({ ...data, light_intervals: copy }))
                  dataCopy.light_intervals.splice(index, 1)
                }}
                className="automations-remove-light-interval-icon"
              >
                <RemoveIcon />
              </div>
            )}
          </div>
        ))}
      {dataCopy && dataCopy.light_intervals.length <= 10 ? (
        <DefaultButton
          customStyle={'add-interval-btn'}
          leftIcon
          onClick={() => {
            const newStart = dataCopy.light_intervals[dataCopy.light_intervals.length - 1]?.end_time
            const date = getDateFromUTCTimeString(newStart || dateToTimezoneTimeString(new Date()))

            setDataCopy((data) => ({
              ...data,
              light_intervals: [
                ...data.light_intervals,
                {
                  start_time: dateToUTCTimeString(date),
                  end_time: dateToUTCTimeString(addToDateAndReturn(date, { hours: 1 })),
                },
              ],
            }))
          }}
          customLeftIcon={
            <div className="add-interval-icon-box">
              <AddIcon className="add-interval-icon" />
            </div>
          }
        >
          {t('automations.stage_setup.add_new_interval')}
        </DefaultButton>
      ) : null}
      {errors?.temperature_interval && (
        <div className="stage-setup-form-error">{errors.temperature_interval}</div>
      )}
      {/* <DefaultInput
        value={dataCopy?.moisture_value}
        type="number"
        min={0}
        max={100}
        label={t('automations.stage_setup.ideal_moisture_level')}
        placeholder={t('automations.stage_setup.enter_ph_value')}
        onChange={(e) => setKey('moisture_value', limitValue(e.target.value))}
      /> */}
    </div>
  )
}

MainStageForm.propTypes = {
  initialData: PropTypes.shape({
    name: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string,
    temperature_min: PropTypes.string,
    temperature_max: PropTypes.string,
    light_intervals: PropTypes.arrayOf(
      PropTypes.shape({
        start_time: PropTypes.string,
        end_time: PropTypes.string,
        light_interval_id: PropTypes.number,
      }).isRequired,
    ),
  }),
  setStageLoading: PropTypes.func,
  onSaved: PropTypes.func,
  onLoadTemplatePressed: PropTypes.func,
  onDataChange: PropTypes.func,
  errors: PropTypes.object,
}

MainStageForm.defaultProps = {
  initialData: PropTypes.shape({
    name: '',
    start: new Date(),
    end: new Date(),
    temperature_min: '',
    temperature_max: '',
    light_intervals: [
      {
        start_time: dateToTimezoneTimeString(new Date()),
        end_time: dateToTimezoneTimeString(addToDateAndReturn(new Date(), { hours: 1 })),
      },
    ],
  }),
  setStageLoading: () => {},
  onSaved: () => {},
  onStageTemplateLoad: () => {},
  onStageTemplateEdit: () => {},
  onLoadTemplatePressed: () => {},
  onDataChange: () => {},
  errors: {},
  // automationId: -1,
}

export default MainStageForm
